import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchCollateral,
  fetchPaymentOptions,
  fetchLaunchConfig,
  saveLaunchConfig,
  clearLaunchConfig,
  launchMasternode,
  fetchLaunchProgress,
  fetchInvoice,
  submitStripePayment
} from "app.actions/launch";
import { fetchUserCredits } from "app.actions/user";
import { withRouter } from "react-router-dom";

import Content from "app.components/Common/Content/Content";
import Stepper, { StepperItem } from "app.components/Common/Stepper/Stepper";
import LaunchStepMasternodeOptions from "app.components/Launch/LaunchStepMasternodeOptions";
import LaunchStepCollateralLookup from "app.components/Launch/LaunchStepCollateralLookup";
import LaunchStepPayment from "app.components/Launch/LaunchStepPayment";
import LaunchStepMasternodeRegistration from "app.components/Launch/LaunchStepMasternodeRegistration";
import LaunchStepConfirmation from "app.components/Launch/LaunchStepConfirmation";
import LaunchContext from "app.components/Launch/LaunchContext";

class LaunchContainer extends Component {
  componentDidMount() {
    const { match = {} } = this.props;
    const id = match.params && match.params.id;

    if (id === "new") {
      this.props.clearLaunchConfig();
      this.props.history.push("/launch");
    } else {
      this.props.fetchLaunchConfig(id);
    }
  }
  saveLaunchConfig(data) {
    this.props.saveLaunchConfig(data);
  }
  launchMasternode(data) {
    this.props.launchMasternode(data);
  }
  render() {
    const { launch, saveLaunchConfig, launchMasternode } = this.props;
    const { lastStep, isReady } = launch.config;

    return (
      <LaunchContext.Provider value={this.props}>
        <Content loading={!isReady}>
          <Stepper onItemSubmit={saveLaunchConfig} startingStep={lastStep}>
            <StepperItem title="Masternode Options">
              <LaunchStepMasternodeOptions />
            </StepperItem>
            <StepperItem title="Collateral Lookup" onSubmit={launchMasternode}>
              <LaunchStepCollateralLookup />
            </StepperItem>
            <StepperItem title="Payment">
              <LaunchStepPayment />
            </StepperItem>
            <StepperItem title="Masternode Registration">
              <LaunchStepMasternodeRegistration />
            </StepperItem>
            <StepperItem title="Confirmation">
              <LaunchStepConfirmation />
            </StepperItem>
          </Stepper>
        </Content>
      </LaunchContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    launch: state.launch
  };
};

const mapDispatchToProps = {
  searchCollateral,
  fetchPaymentOptions,
  fetchLaunchConfig,
  saveLaunchConfig,
  launchMasternode,
  clearLaunchConfig,
  fetchInvoice,
  fetchLaunchProgress,
  submitStripePayment,
  fetchUserCredits
};

LaunchContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LaunchContainer)
);

export default LaunchContainer;
