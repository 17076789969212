import React from "react";
import Message from "app.components/Common/Message/Message";
import CopyToClipboard from "app.components/Common/CopyToClipboard/CopyToClipboard";
import { useWindowSize } from "app.utils";

const GovernanceDelegationCommands = ({ dismiss, masternodes }) => {
  const { isLarge } = useWindowSize();
  return (
    <div>
      <div className="modal__header">
        <button data-tabbable={true} onClick={dismiss} className="modal__close">
          <span className="sr-only">Close</span>
        </button>
        <div className="text--left">
          <h3>
            {isLarge
              ? `DELEGATE VOTE PERMISSIONS FOR ${
                  masternodes.length
                } MASTERNODE(S).`
              : `DELEGATE VOTE FOR ${masternodes.length} NODE(S).`}
          </h3>
        </div>
      </div>
      <div className="modal__body">
        <p className="text--left">
          To delegate voting permission to NODE40 from your local wallet, use
          the following commands:
        </p>

        <Message>
          Note that this is a paid transaction, your wallet will use the
          payoutAddress of each node to cover the transaction fee. If you don't
          have any funds on that address or you just want to use a different
          address for paying the fee, you can add an address to the end of each
          command.
        </Message>

        <div className="flex flex--col flex--grow">
          {masternodes.map((item, index) => (
            <div key={index} className="governance-delegation">
              <div className="governance-delegation__title word-break">
                {index + 1}: Masternode ProTx {item.proTxHash}
              </div>
              <div className="governance-delegation__item">
                <CopyToClipboard
                  label="Copy"
                  rows={4}
                  value={`protx update_registrar ${item.proTxHash} ${
                    item.operatorPublicKeyNode40
                  } ${item.votingAddressNode40} ${item.payoutAddress}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal__controls text--right">
        <button
          onClick={dismiss}
          data-tabbable={true}
          className="button button--text"
        >
          Close
        </button>
      </div>
    </div>
  );
};
export default GovernanceDelegationCommands;
