import React, { useContext, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DigitalMoney from "app.components/Util/DigitalMoney";
import CashMoney from "app.components/Util/CashMoney";
import StripeButton from "app.components/Common/Stripe/StripeButton";
import {
  COMMON_DATE_ONLY_FORMAT,
  INVOICE_STATUS,
  INVOICE_METHODS,
  STRIPE_KEY,
  NODE40_STRIPE_LOGO
} from "app.constants";
import AppContext from "app.components/AppContext";
import CopyToClipboard from "app.components/Common/CopyToClipboard/CopyToClipboard";
import Loader from "app.components/Common/Loader/Loader";

const InvoiceDetail = ({ invoice, updateInvoice }) => {
  const [processing, setProcessing] = useState(false);
  const { user } = useContext(AppContext);

  const stripeConfig = {
    key: STRIPE_KEY,
    image: NODE40_STRIPE_LOGO,
    token: token => {
      updateInvoice({
        id: invoice.id,
        token: token.id,
        amount: invoice.fiatValue,
        renewal: true
      });
      setProcessing(true);
    }
  };

  const stripeData = {
    name: invoice.sequence,
    description: invoice.amount + " Dash",
    amount: invoice.fiatValue ? invoice.fiatValue * 100 : 0,
    email: user.sub
  };

  return (
    <div className="flex flex--col flex--grow">
      <div className="masternode-detail masternode-detail--60 u-pb-default">
        <div className="masternode-detail__title u-mb-default">
          Invoice {invoice.sequence}
        </div>
        <div className="masternode-detail__item">
          <div className="masternode-detail__item__description">Number</div>
          <div className="masternode-detail__item__value">
            {invoice.sequence}
          </div>
        </div>
        <div className="masternode-detail__item">
          <div className="masternode-detail__item__description">Status</div>
          <div className="masternode-detail__item__value">
            <span
              className={
                invoice.status === INVOICE_STATUS.PAID
                  ? "node-status node-status--enabled"
                  : "node-status node-status--not-enabled"
              }
            >
              {invoice.status}
            </span>
          </div>
        </div>
        <div className="masternode-detail__item">
          <div className="masternode-detail__item__description">Due Date</div>
          <div className="masternode-detail__item__value">
            {moment(invoice.dueDate).format(COMMON_DATE_ONLY_FORMAT)}
          </div>
        </div>
        {invoice.status === INVOICE_STATUS.PAID ? (
          <React.Fragment>
            <div className="masternode-detail__item">
              <div className="masternode-detail__item__description">
                Payment Date
              </div>
              <div className="masternode-detail__item__value">
                {moment(invoice.paymentDate).format(COMMON_DATE_ONLY_FORMAT)}
              </div>
            </div>
            <div className="masternode-detail__item">
              <div className="masternode-detail__item__description">
                Payment Method
              </div>
              <div className="masternode-detail__item__value">
                {invoice.method === INVOICE_METHODS.CREDIT_CARD
                  ? "Credit Card"
                  : "Dash"}
              </div>
            </div>
            <div className="masternode-detail__item">
              <div className="masternode-detail__item__description">
                Amount Paid
              </div>
              <div className="masternode-detail__item__value">
                {invoice.method === INVOICE_METHODS.CREDIT_CARD ? (
                  <CashMoney dollars={invoice.fiatValue} />
                ) : (
                  <span>
                    <DigitalMoney coins={invoice.amount.toFixed("8")} /> Dash
                  </span>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="masternode-detail__item masternode-detail__item--full u-p-large">
            <div className="flex flex--col">
              <div className="payment-type__item">
                <div className="payment-type__item__title">Pay in Dash</div>
                <div className="payment-type__item__description">
                  {!invoice.amount
                    ? "Loading deposit address"
                    : "Please send exactly " +
                      invoice.amount +
                      " Dash to this deposit address."}
                </div>
                <div>
                  <CopyToClipboard
                    textWidth="96px"
                    placeholder="Loading deposit address..."
                    value={invoice.paymentAddress}
                    disabled={processing}
                  />
                </div>
              </div>

              <div className="payment-type__item payment-type__item--grow">
                <div className="payment-type__item__title">
                  Or, Pay by credit card
                </div>
                <div className="payment-type__item__description">
                  {!invoice.fiatValue
                    ? "Loading fiat value"
                    : `You will be charged with $${invoice.fiatValue}.`}
                </div>
                <div className="payment-type__item__button">
                  <StripeButton
                    className={
                      processing
                        ? "button button--primary button--loading"
                        : "button button--primary"
                    }
                    disabled={processing}
                    config={stripeConfig}
                    data={stripeData}
                  >
                    Pay with stripe
                  </StripeButton>
                </div>
              </div>
            </div>
            {processing ? (
              <div className="payment__progress">
                <div className="payment__progress__item">
                  <Loader type="horizontal" />
                  Processing payment
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div className="u-p-large">
        <Link to="/invoices">View All Invoices</Link>
      </div>
    </div>
  );
};

export default InvoiceDetail;
