import * as networkActions from "app.actions/network";

let initialState = {
  isReady: false,
  isLoading: false,
  numActiveNodes: 230,
  btcPriceUsd: 13000,
  dashPriceUsd: 180,
  masternodeReward: 1.44,
  blockTime: 150, //in seconds
  voting: {
    totalBudget: 5735.52,
    votingDeadline: 1559079360,
    budgetDistributionDate: 1559424960
  }
};

const network = (state = initialState, action) => {
  switch (action.type) {
    case networkActions.FETCH_NETWORK_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case networkActions.FETCH_NETWORK_RECEIVE: {
      return {
        ...state,
        isLoading: false,
        isReady: true,
        ...action.data
      };
    }
    case networkActions.FETCH_NETWORK_ERROR: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    default:
      return state;
  }
};

export default network;
