import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { fetchGovernance, vote } from "app.actions/governance";
import { fetchMasternodes } from "app.actions/masternodes";
import { dismissModal, openModal } from "app.actions/ui";
import { NETWORK_STATUS } from "app.constants";
import GovernanceList from "app.components/Governance/GovernanceList";
import GovernanceSummary from "app.components/Governance/GovernanceSummary";
import GovernanceTabs from "app.components/Governance/GovernanceTabs";
import GovernanceDelegationCommands from "app.components/Governance/GovernanceDelegationCommands";
import GovernanceBroadcastVote from "app.components/Governance/GovernanceBroadcastVote";

import Modal from "app.components/Modal/Modal";
import Message, {
  MessageCommands,
  MessageContent
} from "app.components/Common/Message/Message";
import Content from "app.components/Common/Content/Content";

class GovernanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProposal: null
    };
  }
  componentDidMount() {
    this.props.fetchGovernance();
    this.props.fetchMasternodes();
  }
  openProposal(proposal, signal) {
    proposal.signal = signal;
    this.setState({ selectedProposal: proposal });
    this.props.openModal({ modalGovernanceBroadcastVote: true });
  }
  vote(proposal, masternodes) {
    const data = {
      id: proposal.id,
      votes: masternodes.map(item => ({
        vote: proposal.signal,
        node: item.commissionId
      }))
    };
    this.props.vote(data);
  }
  processMasternodeList(masternodes) {
    return masternodes.reduce(
      (list, item) => {
        if (item.networkStatus === NETWORK_STATUS.ENABLED) {
          item.votingAddress === item.votingAddressNode40
            ? list.valid.push(item)
            : list.invalid.push(item);
        }
        return list;
      },
      { valid: [], invalid: [] }
    );
  }
  processProposalList(proposals) {
    const { numActiveNodes, dashPriceUsd, voting } = this.props.network;
    const currentSeconds = new Date().getTime() / 1000;

    const summary = {
      totalBudgetDash: voting.totalBudget,
      totalBudgetUsd: voting.totalBudget * dashPriceUsd,
      allocatedBudgetDash: 0,
      allocatedBudgetUsd: 0,
      votingDeadline: voting.votingDeadline,
      budgetDistributionDate: voting.budgetDistributionDate,
      passingProposals: 0,
      totalProposals: proposals.length
    };

    proposals = proposals
      .map(proposal => {
        proposal.statusCount =
          proposal.yeas - proposal.nays - Math.floor(numActiveNodes * 0.1);

        if (proposal.statusCount < 0) {
          proposal.status = `Needs ${Math.abs(proposal.statusCount)}`;
          proposal.statusClass = "proposal-status proposal-status--missing";
        } else {
          summary.passingProposals++;
          summary.allocatedBudget += proposal.monthlyPayment;
          proposal.status = `Passing +${proposal.statusCount}`;
          proposal.statusClass = "proposal-status proposal-status--passing";
        }

        summary.allocatedBudgetDash += proposal.monthlyPayment;

        if (proposal.url.includes("dashnexus")) {
          proposal.urlSource = "Dash Nexus";
        } else if (proposal.url.includes("dashcentral")) {
          proposal.urlSource = "Dash Central";
        } else {
          proposal.urlSource = "Description";
        }

        proposal.requestedDash = proposal.monthlyPayment.toFixed(2);
        proposal.requestedUsd = dashPriceUsd * proposal.requestedDash;

        if (proposal.voted) {
          proposal.tab = "voted";
        } else if (proposal.endEpoch < currentSeconds) {
          proposal.tab = "closed";
        } else {
          proposal.tab = "open";
        }

        return proposal;
      })
      .sort((a, b) => b.statusCount - a.statusCount)
      .reduce(
        (tabs, proposal) => {
          return { ...tabs, [proposal.tab]: [...tabs[proposal.tab], proposal] };
        },
        {
          open: [],
          voted: [],
          closed: []
        }
      );

    summary.allocatedBudgetDash = 1430;
    summary.allocatedBudgetUsd = summary.allocatedBudgetDash * dashPriceUsd;

    return { summary, proposals };
  }

  render() {
    const { governance, match, ui, openModal, dismissModal } = this.props;

    const { isLoading } = governance;
    const {
      modalGovernanceDelegationCommands,
      modalGovernanceBroadcastVote
    } = ui;

    const masternodes = this.processMasternodeList(
      this.props.masternodes.hosted
    );
    const { summary, proposals } = this.processProposalList(
      governance.proposals
    );

    return (
      <Content loading={isLoading}>
        {masternodes.invalid.length ? (
          <Message>
            <MessageContent>
              You have {masternodes.invalid.length} masternode(s) that are not
              using the NODE40 Voting Keys. Update your voting keys to vote
              through this platform.
            </MessageContent>
            <MessageCommands>
              <button
                className="button button--sm button--primary u-m-default"
                onClick={e =>
                  openModal({ modalGovernanceDelegationCommands: true })
                }
              >
                Show Commands
              </button>
            </MessageCommands>
          </Message>
        ) : null}

        {false ? <GovernanceSummary data={summary} /> : null}

        <div className="governance-tabs u-mt-large">
          <GovernanceTabs />
          <div className="governance">
            <Switch>
              <Redirect exact from={`${match.url}`} to={`${match.url}/open`} />
              <Route
                exact
                path={`${match.url}/open`}
                render={() => (
                  <GovernanceList
                    openProposal={(proposal, signal) =>
                      this.openProposal(proposal, signal)
                    }
                    list={proposals.open}
                    type="open"
                  />
                )}
              />
              <Route
                exact
                path={`${match.url}/voted`}
                render={() => (
                  <GovernanceList
                    list={proposals.voted}
                    type="voted"
                    openProposal={(proposal, signal) =>
                      this.openProposal(proposal, signal)
                    }
                  />
                )}
              />
              <Route
                path={`${match.url}/closed`}
                render={() => (
                  <GovernanceList list={proposals.closed} type="closed" />
                )}
              />
            </Switch>
          </div>
        </div>
        <Modal
          modalClass="delegation"
          isOpen={modalGovernanceDelegationCommands}
          dismiss={() => {
            dismissModal({ modalGovernanceDelegationCommands: false });
          }}
        >
          <GovernanceDelegationCommands masternodes={masternodes.invalid} />
        </Modal>
        <Modal
          modalClass="broadcast-vote"
          isOpen={modalGovernanceBroadcastVote}
          dismiss={() => {
            dismissModal({ modalGovernanceBroadcastVote: false });
          }}
        >
          <GovernanceBroadcastVote
            masternodes={masternodes.valid}
            proposal={this.state.selectedProposal}
            vote={(proposal, masternodes) => this.vote(proposal, masternodes)}
          />
        </Modal>
      </Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    governance: state.governance,
    masternodes: state.masternodes,
    network: state.network,
    ui: state.ui
  };
};

const mapDispatchToProps = {
  fetchGovernance,
  fetchMasternodes,
  vote,
  dismissModal,
  openModal
};

GovernanceContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GovernanceContainer)
);

export default GovernanceContainer;
