import React from "react";

const AccountIcon = ({ className, color = "#FFF" }) => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Account</title>
    <path
      d="M27 0H3C1.35 0 0 1.35 0 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3zm-1 28H4V4h22v24zM8 18h14v2H8v-2zm0 4h14v2H8v-2zm2-13a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm5 3h-4c-1.65 0-3 .9-3 2v2h10v-2c0-1.1-1.35-2-3-2z"
      fillRule="nonzero"
      fill="#08293F"
    />
  </svg>
);

export default AccountIcon;
