import { store } from "app.config/configureStore";
import { receiveAuthorizeUser } from "app.actions/user";
import { logout } from "app.actions/authentication";
import { push } from "connected-react-router";
import { receiveGlobalError, receiveNetworkError } from "app.actions/ui";
import { isLoaded } from "app.utils";

export const handleAfterResponseError = response => {
  if (response.error === 401) {
    store.dispatch(logout());
  } else if (response.error === 403) {
    const authLoaded = isLoaded(
      store.getState().authentication.authenticationStatus
    );
    if (authLoaded) {
      // These things happen; we only care if this happens
      // after the auth is bootstrapped
      store.dispatch(push("/"));
    }
  } else if (response.body.serverError) {
    store.dispatch(
      receiveGlobalError(
        "An unexpected error has occurred. Our team has been notified."
      )
    );
  } else if (response.body.networkError) {
    store.dispatch(receiveNetworkError(response._meta));
  } else if (typeof response.error === "string") {
    // If we receive an error that is a string based, show that string
    console.log("handleAfterResponseError", response);
    store.dispatch(receiveGlobalError(response.error));
  } else if (
    typeof response.error === "object" &&
    typeof response.error.message === "string"
  ) {
    store.dispatch(receiveGlobalError(response.error.message));
  }
};

export const handleReauthenticate = async (newTicket, getTokenFromTicket) => {
  const response = await getTokenFromTicket(newTicket);

  if (!response.error) {
    const { body } = response;
    store.dispatch(receiveAuthorizeUser(body.access_token, body));
  }
};

export const getBearerToken = () => {
  const { user } = store.getState();
  if (user) {
    const { token } = user;
    return token;
  }

  return undefined;
};

export const handleAfterResponse = response => {
  // this should clear any network errors
  if (typeof store.getState().ui.networkError !== "undefined") {
    store.dispatch(receiveNetworkError());
  }
};
