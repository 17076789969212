import React, { useState, useEffect } from "react";
import DeleteIcon from "../Common/Icons/DeleteIcon";
import NodeStatus from "../Common/NodeStatus";
import TimeAgo from "../Common/TimeAgo";
import { middleDots } from "app.utils";
import {
  DASH_COLLATERAL_LENGTH,
  DASH_COLLATERAL_SMALL_SCREEN_SIZE,
  DASH_COLLATERAL_SMALL_SCREEN_LENGTH
} from "app.constants";
import { useWindowSize } from "app.utils";

const MasternodeMonitoredList = ({ list, removeMonitoredMasternode }) => {
  const [collateralLength, setCollateralLength] = useState(24);
  const { windowSize } = useWindowSize();
  useEffect(() => {
    if (windowSize <= DASH_COLLATERAL_SMALL_SCREEN_SIZE) {
      setCollateralLength(DASH_COLLATERAL_SMALL_SCREEN_LENGTH);
    } else {
      setCollateralLength(DASH_COLLATERAL_LENGTH);
    }
  }, [windowSize]);

  return (
    <div className="masternode-card-container">
      {list.map((item, itemIndex) => (
        <div key={itemIndex} className="masternode-card">
          <div className="masternode-card__descriptor-container">
            <div className="masternode-card__descriptor masternode-card__descriptor--100">
              <div className="masternode-card__descriptor__title">
                Collateral
              </div>
              <div className="mono">
                {middleDots(
                  item.collateral.replace("_", "-"),
                  collateralLength
                )}
              </div>
            </div>
          </div>
          <div className="masternode-card__item-container">
            <div className="masternode-card__item" key={itemIndex}>
              <div className="masternode-card__item__info masternode-card__item__info--grow">
                <span className="masternode-card__item__info__title">
                  Payout Address
                </span>
                <span className="masternode-card__item__info__value">
                  <span className="mono">{item.payoutAddress}</span>
                </span>
              </div>
              <div className="masternode-card__item__info masternode-card__item__info--network-status">
                <div className="flex flex--row">
                  <span className="masternode-card__item__info__value">
                    <NodeStatus status={item.networkStatus} />
                  </span>
                </div>
              </div>

              <div className="break-column visible-sm" />
              <div className="masternode-card__item__info masternode-card__item__info--last-paid">
                <span className="masternode-card__item__info__title">
                  Last Paid
                </span>
                <span className="masternode-card__item__info__value">
                  {(item.lastPaid && <TimeAgo value={item.lastPaid} />) || "-"}
                </span>
              </div>
              <div className="masternode-card__item__info masternode-card__item__info--delete">
                <div
                  role="button"
                  className="remove-monitored"
                  tabIndex={itemIndex}
                  onClick={e => {
                    removeMonitoredMasternode(item.monitorId);
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                      removeMonitoredMasternode(item.monitorId);
                    }
                  }}
                >
                  <DeleteIcon className="icon-button--delete" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MasternodeMonitoredList;
