import { makeDefaultActionCreator as action } from "app.actions/helpers";

export const FETCH_MASTERNODES_BEGIN = "FETCH_MASTERNODES_BEGIN";
export const FETCH_MASTERNODES_REQUEST = "FETCH_MASTERNODES_REQUEST";
export const FETCH_MASTERNODES_RECEIVE = "FETCH_MASTERNODES_RECEIVE";
export const FETCH_MASTERNODES_ERROR = "FETCH_MASTERNODES_ERROR";

export const UPDATE_MASTERNODE_BEGIN = "UPDATE_MASTERNODE_BEGIN";
export const UPDATE_MASTERNODE_REQUEST = "UPDATE_MASTERNODE_REQUEST";
export const UPDATE_MASTERNODE_RECEIVE = "UPDATE_MASTERNODE_RECEIVE";
export const UPDATE_MASTERNODE_ERROR = "UPDATE_MASTERNODE_ERROR";

export const SEARCH_MONITORED_MASTERNODE_BEGIN =
  "SEARCH_MONITORED_MASTERNODE_BEGIN";
export const SEARCH_MONITORED_MASTERNODE_REQUEST =
  "SEARCH_MONITORED_MASTERNODE_REQUEST";
export const SEARCH_MONITORED_MASTERNODE_RECEIVE =
  "SEARCH_MONITORED_MASTERNODE_RECEIVE";
export const SEARCH_MONITORED_MASTERNODE_ERROR =
  "SEARCH_MONITORED_MASTERNODE_ERROR";

export const ADD_MONITORED_MASTERNODE_BEGIN = "ADD_MONITORED_MASTERNODE_BEGIN";
export const ADD_MONITORED_MASTERNODE_REQUEST =
  "ADD_MONITORED_MASTERNODE_REQUEST";
export const ADD_MONITORED_MASTERNODE_RECEIVE =
  "ADD_MONITORED_MASTERNODE_RECEIVE";
export const ADD_MONITORED_MASTERNODE_ERROR = "ADD_MONITORED_MASTERNODE_ERROR";

export const ADD_MONITORED_MASTERNODE_RESET = "ADD_MONITORED_MASTERNODE_RESET";

export const REMOVE_MONITORED_MASTERNODE_BEGIN =
  "REMOVE_MONITORED_MASTERNODE_BEGIN";
export const REMOVE_MONITORED_MASTERNODE_REQUEST =
  "REMOVE_MONITORED_MASTERNODE_REQUEST";
export const REMOVE_MONITORED_MASTERNODE_RECEIVE =
  "REMOVE_MONITORED_MASTERNODE_RECEIVE";
export const REMOVE_MONITORED_MASTERNODE_ERROR =
  "REMOVE_MONITORED_MASTERNODE_ERROR";

export const FETCH_MASTERNODE_TRANSACTIONS_BEGIN =
  "FETCH_MASTERNODE_TRANSACTIONS_BEGIN";
export const FETCH_MASTERNODE_TRANSACTIONS_REQUEST =
  "FETCH_MASTERNODE_TRANSACTIONS_REQUEST";
export const FETCH_MASTERNODE_TRANSACTIONS_RECEIVE =
  "FETCH_MASTERNODE_TRANSACTIONS_RECEIVE";
export const FETCH_MASTERNODE_TRANSACTIONS_ERROR =
  "FETCH_MASTERNODE_TRANSACTIONS_ERROR";

export const FETCH_MASTERNODE_INVOICES_BEGIN =
  "FETCH_MASTERNODE_INVOICES_BEGIN";
export const FETCH_MASTERNODE_INVOICES_REQUEST =
  "FETCH_MASTERNODE_INVOICES_REQUEST";
export const FETCH_MASTERNODE_INVOICES_RECEIVE =
  "FETCH_MASTERNODE_INVOICES_RECEIVE";
export const FETCH_MASTERNODE_INVOICES_ERROR =
  "FETCH_MASTERNODE_INVOICES_ERROR";

export const FETCH_TAGS_BEGIN = "FETCH_TAGS_BEGIN";
export const FETCH_TAGS_REQUEST = "FETCH_TAGS_REQUEST";
export const FETCH_TAGS_RECEIVE = "FETCH_TAGS_RECEIVE";
export const FETCH_TAGS_ERROR = "FETCH_TAGS_ERROR";

export const ADD_TAG_BEGIN = "ADD_TAG_BEGIN";
export const ADD_TAG_REQUEST = "ADD_TAG_REQUEST";
export const ADD_TAG_RECEIVE = "ADD_TAG_RECEIVE";
export const ADD_TAG_ERROR = "ADD_TAG_ERROR";

export const REMOVE_TAG_BEGIN = "REMOVE_TAG_BEGIN";
export const REMOVE_TAG_REQUEST = "REMOVE_TAG_REQUEST";
export const REMOVE_TAG_RECEIVE = "REMOVE_TAG_RECEIVE";
export const REMOVE_TAG_ERROR = "REMOVE_TAG_ERROR";

export const fetchMasternodes = action(FETCH_MASTERNODES_BEGIN);
export const fetchMasternodesRequest = action(FETCH_MASTERNODES_REQUEST);
export const fetchMasternodesReceive = action(FETCH_MASTERNODES_RECEIVE);
export const fetchMasternodesError = action(FETCH_MASTERNODES_ERROR);

export const updateMasternode = action(UPDATE_MASTERNODE_BEGIN);
export const updateMasternodeRequest = action(UPDATE_MASTERNODE_REQUEST);
export const updateMasternodeReceive = action(UPDATE_MASTERNODE_RECEIVE);
export const updateMasternodeError = action(UPDATE_MASTERNODE_ERROR);

export const searchMonitoredMasternode = action(
  SEARCH_MONITORED_MASTERNODE_BEGIN
);
export const searchMonitoredMasternodeRequest = action(
  SEARCH_MONITORED_MASTERNODE_REQUEST
);
export const searchMonitoredMasternodeReceive = action(
  SEARCH_MONITORED_MASTERNODE_RECEIVE
);
export const searchMonitoredMasternodeError = action(
  SEARCH_MONITORED_MASTERNODE_ERROR
);

export const addMonitoredMasternode = action(ADD_MONITORED_MASTERNODE_BEGIN);
export const addMonitoredMasternodeRequest = action(
  ADD_MONITORED_MASTERNODE_REQUEST
);
export const addMonitoredMasternodeReceive = action(
  ADD_MONITORED_MASTERNODE_RECEIVE
);
export const addMonitoredMasternodeError = action(
  ADD_MONITORED_MASTERNODE_ERROR
);
export const resetMasternodeMonitoredAdd = action(
  ADD_MONITORED_MASTERNODE_RESET
);

export const removeMonitoredMasternode = action(
  REMOVE_MONITORED_MASTERNODE_BEGIN
);
export const removeMonitoredMasternodeRequest = action(
  REMOVE_MONITORED_MASTERNODE_REQUEST
);
export const removeMonitoredMasternodeReceive = action(
  REMOVE_MONITORED_MASTERNODE_RECEIVE
);
export const removeMonitoredMasternodeError = action(
  REMOVE_MONITORED_MASTERNODE_ERROR
);

export const fetchMasternodeTransactions = action(
  FETCH_MASTERNODE_TRANSACTIONS_BEGIN
);
export const fetchMasternodeTransactionsRequest = action(
  FETCH_MASTERNODE_TRANSACTIONS_REQUEST
);
export const fetchMasternodeTransactionsReceive = action(
  FETCH_MASTERNODE_TRANSACTIONS_RECEIVE
);
export const fetchMasternodeTransactionsError = action(
  FETCH_MASTERNODE_TRANSACTIONS_ERROR
);

export const fetchMasternodeInvoices = action(FETCH_MASTERNODE_INVOICES_BEGIN);
export const fetchMasternodeInvoicesRequest = action(
  FETCH_MASTERNODE_INVOICES_REQUEST
);
export const fetchMasternodeInvoicesReceive = action(
  FETCH_MASTERNODE_INVOICES_RECEIVE
);
export const fetchMasternodeInvoicesError = action(
  FETCH_MASTERNODE_INVOICES_ERROR
);

export const fetchTags = action(FETCH_TAGS_BEGIN);
export const fetchTagsRequest = action(FETCH_TAGS_REQUEST);
export const fetchTagsReceive = action(FETCH_TAGS_RECEIVE);
export const fetchTagsError = action(FETCH_TAGS_ERROR);

export const addTag = action(ADD_TAG_BEGIN);
export const addTagRequest = action(ADD_TAG_REQUEST);
export const addTagReceive = action(ADD_TAG_RECEIVE);
export const addTagError = action(ADD_TAG_ERROR);

export const removeTag = action(REMOVE_TAG_BEGIN);
export const removeTagRequest = action(REMOVE_TAG_REQUEST);
export const removeTagReceive = action(REMOVE_TAG_RECEIVE);
export const removeTagError = action(REMOVE_TAG_ERROR);
