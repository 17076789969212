import React, { useContext } from "react";
import {
  StepperContext,
  StepperNavIndicator
} from "app.components/Common/Stepper/Stepper";

const StepperNav = () => {
  const { steps, currentStepIndex, maxStepIndex } = useContext(StepperContext);

  return (
    <nav className="stepper__nav">
      {steps.map((step, i) => (
        <div
          className={
            i <= maxStepIndex
              ? "stepper__nav__item stepper__nav__item--active"
              : "stepper__nav__item"
          }
          key={i}
        >
          <div className="stepper__nav__item__wrapper">
            <StepperNavIndicator
              step={i + 1}
              currentStepIndex={currentStepIndex}
            />
            <div className="stepper__nav__item__label">
              <span>{step.title}</span>
              <div className="stepper__nav__item__sublabel">
                {step.subLabel}
              </div>
            </div>
          </div>
        </div>
      ))}
    </nav>
  );
};

export default StepperNav;
