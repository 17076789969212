import { makeDefaultActionCreator as action } from "app.actions/helpers";

export const FETCH_NETWORK_BEGIN = "FETCH_NETWORK_BEGIN";
export const FETCH_NETWORK_REQUEST = "FETCH_NETWORK_REQUEST";
export const FETCH_NETWORK_RECEIVE = "FETCH_NETWORK_RECEIVE";
export const FETCH_NETWORK_ERROR = "FETCH_NETWORK_ERROR";

export const fetchNetwork = action(FETCH_NETWORK_BEGIN);
export const fetchNetworkRequest = action(FETCH_NETWORK_REQUEST);
export const fetchNetworkReceive = action(FETCH_NETWORK_RECEIVE);
export const fetchNetworkError = action(FETCH_NETWORK_ERROR);
