import React from "react";

const activeCircleFill = "#85c54d";
const activeFill = "#FFFFFF";
const inActiveCircleFill = "#dedede";
const inActiveFill = "#7e7e7e";

const StepperNavIndicatior = ({
  width = 25,
  alt,
  step = 1,
  currentStepIndex = 0,
  className
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 225 225"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="All">
          <title>{alt}</title>
          <circle
            id="Oval"
            fill={
              currentStepIndex === step - 1
                ? activeCircleFill
                : inActiveCircleFill
            }
            cx="112.5"
            cy="112.5"
            r="112.5"
          />

          <text
            className="stepper-indicator-font"
            id="X"
            fontSize="140"
            fill={currentStepIndex === step - 1 ? activeFill : inActiveFill}
            textAnchor="middle"
            x="112"
            y="160"
          >
            {step}
          </text>
        </g>
      </g>
    </svg>
  );
};

export default StepperNavIndicatior;
