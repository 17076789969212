import { createStore, applyMiddleware, compose } from "redux";
import browserHistory from "app.config/configureBrowserHistory";
import { createLogger } from "redux-logger";
import MakeHosting from "app.reducers";
import { routerMiddleware } from "connected-react-router";
import networkCancelMiddleware from "app.config/networkCancelMiddleware";
import logPageChangeMiddleware from "app.config/logPageChangeMiddleware";
import createSagaMiddleware from "redux-saga";
import rootSaga from "app.sagas";

// Redux stores are just a collection of Reducers that form the state tree
// Here we configure the store to use a few middlewares
// 1) thunk: Thunks are a middleware which allow us to dispatch actions which run asynchonously.
//       Further reading: https://github.com/gaearon/redux-thunk
//                http://stackoverflow.com/questions/35411423/how-to-dispatch-a-redux-action-with-a-timeout/35415559#35415559
// 2) Logging

let configureStore;

const sagaMiddleware = createSagaMiddleware();
const Hosting = MakeHosting(browserHistory);

if (process.env.NODE40_ENV === "production") {
  configureStore = () => {
    const store = createStore(
      Hosting,
      compose(
        applyMiddleware(
          logPageChangeMiddleware(),
          networkCancelMiddleware(browserHistory),
          routerMiddleware(browserHistory),
          sagaMiddleware
        )
      )
    );

    sagaMiddleware.run(rootSaga);

    return store;
  };
} else {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const logger = createLogger();
  configureStore = () => {
    const store = createStore(
      Hosting,
      composeEnhancers(
        applyMiddleware(
          networkCancelMiddleware(browserHistory),
          routerMiddleware(browserHistory),
          // logger,
          sagaMiddleware
        )
      )
    );

    sagaMiddleware.run(rootSaga);

    return store;
  };
}

// Call to configure the Redux store which will be passed into our Root component.
export const store = configureStore();
