import React, { useState, useContext } from "react";

import TabsItem from "app.components/Common/Tabs/TabsItem";

const TabsContext = React.createContext({});

const Tabs = ({ children = [], onChange, selectedTab = 0 }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab);
  const currentTab = children[currentTabIndex];
  const onChangeTab = (e, index) => {
    setCurrentTabIndex(index);
    if (onChange) {
      onChange(e, currentTab, index);
    }
  };
  const tabs = children.map(item => ({ ...item.props }));
  const context = {
    tabs,
    currentTabIndex,
    onChangeTab
  };

  return (
    <TabsContext.Provider value={context}>
      <div className="tabs">
        <TabsNav />
        <div className="tabs__item">{currentTab}</div>
      </div>
    </TabsContext.Provider>
  );
};

const TabsNav = () => {
  const { tabs, onChangeTab, currentTabIndex } = useContext(TabsContext);

  return (
    <div className="tabs__nav">
      <ul>
        {tabs.map((tab, index) => (
          <li key={index}>
            <button
              className={currentTabIndex === index ? "active" : ""}
              onClick={e => onChangeTab(e, index)}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;

export { TabsItem, TabsContext, TabsNav };
