import HttpClient from "./HttpClient";
import axios from "axios";
import LocalStorageService from "../../../../service/LocalStorageService"

/**
 * An HTTP Client Manager to interact with Node40 SSO APIs in a standardized manner
 * @memberof HTTPClients.Base
 * @abstract
 * @extends HTTPClients.Base.HTTPClient
 */
class SSOClient extends HttpClient {
  getNewTicket = async () => {
    const { ssoRefreshURL } = this.config;
    const accessToken = LocalStorageService.getAccessToken();
    if(accessToken == null) return "";
    const refreshedTicket = await axios.post(ssoRefreshURL, {access_token: accessToken}, {
      withCredentials: true
    });

    let ticket = refreshedTicket.data.ticket

    return ticket;
  };

  /**
   * Constructs a new instance of the HTTPClient
   * @param {HTTPClients.HTTPClientConfig} config - The configuration for this HTTP Client.
   */
  constructor(config) {
    super(config);

    // Allow temporarily disabling SSO functionality
    if (config.disableSSO) return;

    const { ssoRefreshURL, onReauthenticate } = config;

    if (typeof ssoRefreshURL !== "string")
      throw new Error(
        "Did not provide the SSO Refresh URL for this API instance"
      );

    if (typeof onReauthenticate !== "function")
      throw new Error("Did not provide an onReauthenticate callback to config");

    // First, we store our original request interceptors so that we can mimic this request
    const originalRequestInterceptors = this.instance.interceptors.request;
    const refireInstance = axios.create(this.getClientConfig(config));
    refireInstance.interceptors.request = originalRequestInterceptors;

    // Then, we overwrite our response interceptors so that we can place our SSO interceptor
    // Before the other ones, to allow to re-fire without the other client utilities
    // having to be aware of that logic
    const originalResponseInterceptors = [
      ...this.instance.interceptors.response.handlers
    ];

    this.instance.interceptors.response.handlers = [];

    this.instance.interceptors.response.use(null, async apiError => {
      if (apiError.response.status !== 401) return Promise.reject(apiError);

      // Step 1
      // -Store request config of original request
      const oldRequestConfig = apiError.config;

      try {
        // Step 2
        // - Do a raw request to get a refreshed ticket, if an SSO Session
        // - is still valid
        const refreshedTicket = await this.getNewTicket();

        // Step 3
        // - Inform the client that their SSO Session has successfully been
        // - renewed and that a new ticket has been granted
        await onReauthenticate(refreshedTicket);

        // Step 4
        // - Refire the original request
        return await refireInstance(oldRequestConfig);
      } catch (e) {
        console.log("Error during SSO handshake", e);

        return Promise.reject(e);
      }
    });

    // Place our new interceptor for SSO first
    this.instance.interceptors.response.handlers = [
      ...this.instance.interceptors.response.handlers,
      ...originalResponseInterceptors
    ];
  }
}

export default SSOClient;
