export const APP_NAME = "NODE40 Hosting";

export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const PUT = "PUT";
export const AUTHENTICATED = true;

export const BASE_PATH = "/";
export const LOCAL_STORAGE_KEY = "NODE40_HOSTING_APP";
export const LOCAL_STORAGE_TOKEN_KEY = "hosting-auth-token";
export const LOCAL_STORAGE_DECODED_TOKEN_KEY = "user";

export const SCREEN_SIZE_MD_MIN = 640;
export const SCREEN_SIZE_MD_MAX = 1200;

export const TRANSACTIONS_PER_PAGE = 10;
export const PAGINATION_SIZE = 10;
export const PAGINATION_SIZE_SMALL_SCREEN = 4;

export const COLOR_BRAND_BLUE = "#29395A";
export const COLOR_WHITE = "#FFFFFF";

export const COMMON_DATE_FORMAT = "MMM\xa0D,\xa0YYYY\xa0h:mm\xa0A";
export const COMMON_DATE_ONLY_FORMAT = "MMM\xa0D,\xa0YYYY";
export const COMMON_MONTH_YEAR_ONLY_FORMAT = "MMM\xa0YYYY";
export const COMMON_TIME_ONLY_FORMAT = "h:mm\xa0A";

export const DASH_DECIMALS = 100000000;
export const DASH_COLLATERAL = 1000;
export const DASH_COLLATERAL_LENGTH = 66;
export const DASH_COLLATERAL_SMALL_SCREEN_SIZE = 440;
export const DASH_COLLATERAL_SMALL_SCREEN_LENGTH = 40;

export const NEXT_REWARD_MIN_SECONDS = 18000; // below 5 hours will show "within the next few hours"

export const FIVE_SECONDS = 5 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const HALF_MINUTE = 30 * 1000;

export const NODE40_STRIPE_LOGO =
  "https://cdn.zaimirai.com/images/logo/logo-zaimirai-favicon.png";

export const STATUS = {
  UNINITIALIZED: "uninitialized",
  INITIAL: "initial",
  LOADING: "loading",
  RELOADING: "reloading",
  LOADED: "loaded",
  WORKING: "working",
  SUCCESS: "success",
  ERROR: "error"
};

export const NETWORK_STATUS = {
  ENABLED: "ENABLED",
  PENDING: "PENDING",
  POSE_BANNED: "POSE_BANNED",
  WRONG_OPERATOR: "WRONG_OPERATOR",
  DISAPPEARED: "DISAPPEARED",
  MIGRATING: "MIGRATING"
};

export const COMMISSION_STATUS = {
  COMPLETE: "COMPLETE",
  RENEWAL: "RENEWAL",
  LAUNCHING: "LAUNCHING",
  AWAITING_BLOCKS: "AWAITING_BLOCKS"
};

export const INVOICE_STATUS = {
  PAID: "PAID",
  PENDING: "PENDING"
};

export const INVOICE_METHODS = {
  CREDIT_CARD: "CREDIT_CARD",
  DASH: "DASH"
};

let hostingServiceId;
if (
  process.env.NODE_ENV === "production" &&
  process.env.NODE40_ENV === "production"
) {
  // for production
  hostingServiceId = "bd3e3e91-367f-4c2b-b1b9-12d1b11344d1";
} else if (
  process.env.NODE_ENV === "production" &&
  process.env.NODE40_ENV === "development"
) {
  // for test
  hostingServiceId = "e4bfe8dd-04a2-45a0-8ec8-2cacd7b97b7b";
} else {
  // for development
  hostingServiceId = "e4bfe8dd-04a2-45a0-8ec8-2cacd7b97b7b";
}

export const HOSTING_SERVICE_ID = hostingServiceId;

let authBase,
  assetsBase,
  ssoBase,
  hostingSSOBase,
  ssoRefreshUrl,
  hostingBase,
  stripeKey,
  masternodePort,
  balanceUrl,
  accountUrl,
  planUrl;

if (process.env.NODE40_ENV === "production") {
  ssoBase = "https://account.zaimirai.com";
  authBase = "https://api.zaimirai.com/monitor/api";
  hostingBase = "https://api.zaimirai.com/monitor/api";
  hostingSSOBase = "https://api.zaimirai.com/monitor/sso/api";
  assetsBase = "https://assets.zaimirai.com/";
  ssoRefreshUrl = "https://api.zaimirai.com/monitor/api/740e961b-fe39-4251-8083-39cace53166e";
  stripeKey = "pk_live_51JS29ACL57c1oW2ohiK3IEyIxbPzMKyNNmq1GCja1SnI07iocCrvNVyhEJ32pkRkSCv9Bgrs9EPwDcm2VUBqr5SH00p1jat2HI";
  masternodePort = "9999";
  balanceUrl = "https://balance.node40.com";
  accountUrl = "https://account.node40.com";
  planUrl = "https://zaimirai.com/pricing/";
} else if(process.env.NODE40_ENV === "staging") {
  ssoBase = "https://account-staging.zaimirai.com";
  authBase = "https://api-staging.zaimirai.com/monitor/api";
  hostingBase = "https://api-staging.zaimirai.com/monitor/api";
  hostingSSOBase = "https://api-staging.zaimirai.com/monitor/sso/api";
  assetsBase = "https://assets.zaimirai.com/";
  ssoRefreshUrl = "https://api-staging.zaimirai.com/monitor/api/740e961b-fe39-4251-8083-39cace53166e";
  stripeKey = "pk_test_51JS29ACL57c1oW2oMKMan64D7deWiE5J2Z5IByRl4Ei4fpZjsxVTx6HiMp9Tmv1vCJ94VN21r5RaK4XgRKlGrh2600jkZVGFWg";
  masternodePort = "19999";
  balanceUrl = "https://balance.darknode40.com";
  accountUrl = "https://account-test.zaimirai.com";
  planUrl = "https://www-staging.zaimirai.com/pricing/";

} else if(process.env.NODE40_ENV === "development") {
  ssoBase = "http://account.zaimirai.local";
  authBase = "http://api.staking.zaimirai.local:8080/monitor/api";
  hostingBase = "http://api.staking.zaimirai.local:8080/monitor/api";
  hostingSSOBase = "http://api.staking.zaimirai.local:8080/monitor/sso/api";
  assetsBase = "https://assets.zaimirai.com/";
  ssoRefreshUrl = "http://api.staking.zaimirai.local:8080/monitor/api/740e961b-fe39-4251-8083-39cace53166e";
  stripeKey = "pk_test_51JS29ACL57c1oW2oMKMan64D7deWiE5J2Z5IByRl4Ei4fpZjsxVTx6HiMp9Tmv1vCJ94VN21r5RaK4XgRKlGrh2600jkZVGFWg";
  masternodePort = "19999";
  balanceUrl = "https://balance.darknode40.com";
  accountUrl = "http://account.darknode40.com";
  planUrl = "https://www-test.zaimirai.com/pricing/";
} else if(process.env.NODE40_ENV === "test"){
  ssoBase = "https://account-test.zaimirai.com";
  authBase = "https://api-test.zaimirai.com/monitor/api";
  hostingBase = "https://api-test.zaimirai.com/monitor/api";
  hostingSSOBase = "https://api-test.zaimirai.com/monitor/sso/api";
  assetsBase = "https://assets.node40.com/";
  ssoRefreshUrl = "https://api-test.zaimirai.com/monitor/api/740e961b-fe39-4251-8083-39cace53166e";
  stripeKey = "pk_test_51JS29ACL57c1oW2oMKMan64D7deWiE5J2Z5IByRl4Ei4fpZjsxVTx6HiMp9Tmv1vCJ94VN21r5RaK4XgRKlGrh2600jkZVGFWg";
  masternodePort = "19999";
  balanceUrl = "https://balance.darknode40.com";
  accountUrl = "https://account-test.zaimirai.com";
  planUrl = "https://www-test.zaimirai.com/pricing/";
}

export const AUTH_BASE = authBase;
export const HOSTING_SSO_BASE = hostingSSOBase;
export const HOSTING_BASE = hostingBase;
export const ASSETS_BASE = assetsBase;
export const SSO_BASE = ssoBase;
// let ssoRefresh = `${ssoRefreshUrl}/${hostingServiceId}`;
let ssoRefresh = `${ssoRefreshUrl}`;


export const SSO_REFRESH_URL = ssoRefresh ;
export const STRIPE_KEY = stripeKey;
export const MASTERNODE_PORT = masternodePort;
export const BALANCE_URL = balanceUrl;
export const ACCOUNT_URL = accountUrl;
export const PLAN_URL = planUrl;
