import { combineReducers } from "redux";
import ui from "app.reducers/ui";
import user from "app.reducers/user";
import authentication from "app.reducers/authentication";
import masternodes from "app.reducers/masternodes";
import governance from "app.reducers/governance";
import network from "app.reducers/network";
import launch from "app.reducers/launch";
import invoice from "app.reducers/invoice";
import { connectRouter } from "connected-react-router";

// Reducers are responsible for a slice of the application state. When an action happens, if
// A reducer handles that action, it can operate on its slice of the state and return a new copy
// of its slice
//
// A reducer is a pure function which takes in as an argument, State and Action. The action
// tells the reducer how to manipulate state, or, which portion of state to return
//
// combineReducers is how Redux manages splitting application state logically.
// Combine reducers will return a single redux state from the result of combining multiple redux reducers.

const MakeHosting = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    ui,
    authentication,
    masternodes,
    governance,
    network,
    launch,
    invoice
  });

export default MakeHosting;
