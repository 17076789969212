import React from "react";
import { NETWORK_STATUS } from "app.constants";

const NodeStatus = ({ status, type }) => {
  const statusClass =
    status === NETWORK_STATUS.ENABLED
      ? "node-status--enabled"
      : "node-status--not-enabled";

  return type === "simple" ? (
    <NodeStatusSimple className={statusClass} />
  ) : (
    <div className={"node-status " + statusClass}>
      {status === NETWORK_STATUS.MIGRATING ? (
        <span className="node-status__value">{NETWORK_STATUS.ENABLED}</span>
      ) : (
        <span className="node-status__value">{status}</span>
      )}
    </div>
  );
};

const NodeStatusSimple = ({ className }) => {
  return (
    <svg width="16" height="16">
      <circle cx="8" cy="8" r="4" className={className} />
    </svg>
  );
};

export default NodeStatus;
