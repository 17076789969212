import React, { useEffect, useState } from "react";
const StripeButton = ({
  className = "",
  disabled,
  children,
  config,
  data,
  onClick
}) => {
  const [checkout] = useCheckout(config, data);

  return (
    <button className={className} disabled={disabled} onClick={checkout}>
      {children}
    </button>
  );
};

const useCheckout = (config, data, onClick) => {
  const [stripe, setStripe] = useState();

  const configureStripe = () => {

    // let publicKeyResult = await HostingAPI.getPublicKey();
    // config.key = publicKeyResult.data;
    // eslint-disable-next-line no-undef
    const response = StripeCheckout.configure({
      locale: "auto",
      allowRememberMe: false,
      token: function (token) {
      },
      ...config
    });
    setStripe(response);
  };
  useEffect(configureStripe, []);

  return [
    () => {
      stripe.open(data);
    }
  ];
};

export default StripeButton;
