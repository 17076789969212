import React, { Component } from "react";
import PropTypes from "prop-types";
import tabbable from "tabbable";

const BYPASS_ACTIVE_FIELD_CHECK = true;
const MODAL_ID = "node40-ui-modal";

class ModalBody extends Component {
  static propTypes = {
    body: PropTypes.element,
    modalError: PropTypes.string,
    modalClass: PropTypes.string,
    params: PropTypes.object,
    dismiss: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.tabbableCache = undefined;
    this.tabIndex = undefined;
    this.body = document.getElementsByTagName("body")[0];
    this.tabbableContainerRef = React.createRef();
    this.state = {
      canDismiss: true
    };
  }

  componentDidMount() {
    this.tabIndex = 0;

    // this class prevents scrolling on the background while the modal is open
    this.body.classList.add("modal-open");

    // for detecting key press, so we can dismiss when `esc` key is pressed
    // and control tabbing behavior
    window.addEventListener("keydown", this._detectKey, false);

    // cache this because we need to accomodate changes between disabled/not disabled elements as they occur
    this.tabbableCache = tabbable(this.tabbableContainerRef.current);

    // Move focus to first focusable element
    for (let i = 0; i < this.tabbableCache.length; i++) {
      if (
        this.tabbableCache[i].disabled !== true &&
        this.tabbableCache[i].className !== "modal__close" &&
        this.tabbableCache[i].className !== "modal__header__close"
      ) {
        this.tabbableCache[i].focus();
        break;
      }
    }
  }

  newFieldsCallback = () => {
    // get any new fields which may have been added and remove those which
    // have been removed. if an existing element is active, adjust the index.
    this.tabbableCache = tabbable(this.tabbableContainerRef.current);
    this.tabIndex = 0;
    for (let i = 0; i < this.tabbableCache.length; i++) {
      if (this.tabbableCache[i] === document.activeElement) {
        this.tabIndex = i;
        break;
      }
    }
  };

  componentWillUnmount() {
    this.body.classList.remove("modal-open");
    window.removeEventListener("keydown", this._detectKey, false);
  }

  // this function watches for key presses to make sure some behaviors happen
  // if ESC (27) is pressed, we dismiss the modal window
  // if tab (9) or shift tab is pressed, we control which elements are tabbed to,
  // so that we can make sure a user can't interact with the elements behind the modal
  _detectKey = event => {
    const key = event.which || event.keyCode;

    // tab pressed
    if (key === 9) {
      event.preventDefault();
      this._handleTabPressed(event.shiftKey);
    }

    // esc press, dismiss modal
    if (key === 27) {
      //e.preventDefault();
      if (this.state.canDismiss) {
        this.props.dismiss();
      }
    }
  };

  canDismissCallback = canDismiss => {
    // if something is happening in a modal that
    // and we want to prevent the user from leaving
    // the modal while it is happening
    this.setState({ canDismiss: canDismiss });
  };

  _handleTabPressed = (shiftKey, bypassActiveFieldCheck) => {
    // if we found no tabbable fields, cancel.
    if (this.tabbableCache.length === 0) {
      return;
    }

    // set index to current field (like, say if you click to a different field instead of tabbing to it)
    if (!bypassActiveFieldCheck) {
      for (let i = 0; i < this.tabbableCache.length; i++) {
        if (this.tabbableCache[i] === document.activeElement) {
          this.tabIndex = i;
          break;
        }
      }
    }

    if (typeof this.tabIndex === "undefined") {
      this.tabIndex = 0;
    }

    if (shiftKey) {
      if (this.tabIndex === 0) {
        this.tabIndex = this.tabbableCache.length - 1;
      } else {
        --this.tabIndex;
      }
    } else {
      if (this.tabIndex === this.tabbableCache.length - 1) {
        this.tabIndex = 0;
      } else {
        ++this.tabIndex;
      }
    }

    if (this.tabbableCache[this.tabIndex].disabled === true) {
      // if there is at least ONE non-disabled tabbable field
      for (let i = 0; i < this.tabbableCache.length; i++) {
        if (this.tabbableCache[i].disabled !== true) {
          this._handleTabPressed(shiftKey, BYPASS_ACTIVE_FIELD_CHECK);
          break;
        }
      }
    } else {
      this.tabbableCache[this.tabIndex].focus();
    }
  };

  render() {
    const className =
      typeof this.props.modalClass !== "string"
        ? "modal"
        : `modal modal__${this.props.modalClass}`;
    return (
      <div
        id={MODAL_ID}
        ref={this.tabbableContainerRef}
        className="modal__shadowbox"
      >
        <div className={className}>
          {// some modals might need a complex header, they will provide their own
          typeof this.props.title === "string" ? (
            <div className="modal__header">
              <h3>{this.props.title}</h3>
              <button
                data-tabbable={true}
                onClick={event => {
                  event.preventDefault();
                  this.props.dismiss();
                }}
                className="modal__header__close"
              >
                <span className="sr-only">Close</span>
              </button>
            </div>
          ) : null}
          {this.props.body &&
            React.cloneElement(this.props.body, {
              // the following properties are passed into the component you provide as
              // the body of your modal.
              // an Error message that your modal can display.
              modalError: this.props.modalError,
              // if your modal does now allow itself to be closed while work is happening
              // you can call this function to enable/disable it
              canDismissCallback: this.canDismissCallback,
              // If the contents of your modal change so that new tabbable fields appear
              // this will reindex them
              newFieldsCallback: this.newFieldsCallback,
              // the function that will be called to dismiss your modal
              dismiss: this.props.dismiss,
              // any other params you have on the query string
              ...this.props.params
            })}
        </div>
      </div>
    );
  }
}

export default ModalBody;
