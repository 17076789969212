import { makeDefaultActionCreator } from "app.actions/helpers";
const action = makeDefaultActionCreator;

export const SEARCH_COLLATERAL_BEGIN = "SEARCH_COLLATERAL_BEGIN";
export const SEARCH_COLLATERAL_REQUEST = "SEARCH_COLLATERAL_REQUEST";
export const SEARCH_COLLATERAL_RECEIVE = "SEARCH_COLLATERAL_RECEIVE";
export const SEARCH_COLLATERAL_ERROR = "SEARCH_COLLATERAL_ERROR";
export const FETCH_PAYMENT_OPTIONS_BEGIN = "FETCH_PAYMENT_OPTIONS_BEGIN";
export const FETCH_PAYMENT_OPTIONS_REQUEST = "FETCH_PAYMENT_OPTIONS_REQUEST";
export const FETCH_PAYMENT_OPTIONS_RECEIVE = "FETCH_PAYMENT_OPTIONS_RECEIVE";
export const FETCH_PAYMENT_OPTIONS_ERROR = "FETCH_PAYMENT_OPTIONS_ERROR";
export const SAVE_LAUNCH_CONFIG_BEGIN = "SAVE_LAUNCH_OPTIONS_BEGIN";
export const SAVE_LAUNCH_CONFIG_REQUEST = "SAVE_LAUNCH_OPTIONS_REQUEST";
export const SAVE_LAUNCH_CONFIG_RECEIVE = "SAVE_LAUNCH_OPTIONS_RECEIVE";
export const SAVE_LAUNCH_CONFIG_ERROR = "SAVE_LAUNCH_CONFIG_ERROR";
export const FETCH_LAUNCH_CONFIG_BEGIN = "FETCH_LAUNCH_CONFIG_BEGIN";
export const FETCH_LAUNCH_CONFIG_REQUEST = "FETCH_LAUNCH_CONFIG_REQUEST";
export const FETCH_LAUNCH_CONFIG_RECEIVE = "FETCH_LAUNCH_CONFIG_RECEIVE";
export const FETCH_LAUNCH_CONFIG_ERROR = "FETCH_LAUNCH_CONFIG_ERROR";
export const CLEAR_LAUNCH_CONFIG_BEGIN = "CLEAR_LAUNCH_CONFIG_BEGIN";
export const CLEAR_LAUNCH_CONFIG_REQUEST = "CLEAR_LAUNCH_CONFIG_REQUEST";
export const CLEAR_LAUNCH_CONFIG_RECEIVE = "CLEAR_LAUNCH_CONFIG_RECEIVE";
export const CLEAR_LAUNCH_CONFIG_ERROR = "CLEAR_LAUNCH_CONFIG_ERROR";
export const LAUNCH_MASTERNODE_BEGIN = "LAUNCH_MASTERNODE_BEGIN";
export const LAUNCH_MASTERNODE_REQUEST = "LAUNCH_MASTERNODE_REQUEST";
export const LAUNCH_MASTERNODE_RECEIVE = "LAUNCH_MASTERNODE_RECEIVE";
export const LAUNCH_MASTERNODE_ERROR = "LAUNCH_MASTERNODE_ERROR";
export const FETCH_LAUNCH_PROGRESS_BEGIN = "FETCH_LAUNCH_PROGRESS_BEGIN";
export const FETCH_LAUNCH_PROGRESS_REQUEST = "FETCH_LAUNCH_PROGRESS_REQUEST";
export const FETCH_LAUNCH_PROGRESS_RECEIVE = "FETCH_LAUNCH_PROGRESS_RECEIVE";
export const FETCH_LAUNCH_PROGRESS_ERROR = "FETCH_LAUNCH_PROGRESS_ERROR";
export const FETCH_INVOICE_BEGIN = "FETCH_INVOICE_BEGIN";
export const FETCH_INVOICE_REQUEST = "FETCH_INVOICE_REQUEST";
export const FETCH_INVOICE_RECEIVE = "FETCH_INVOICE_RECEIVE";
export const FETCH_INVOICE_ERROR = "FETCH_INVOICE_ERROR";
export const SUBMIT_STRIPE_PAYMENT_BEGIN = "SUBMIT_STRIPE_PAYMENT_BEGIN";
export const SUBMIT_STRIPE_PAYMENT_REQUEST = "SUBMIT_STRIPE_PAYMENT_REQUEST";
export const SUBMIT_STRIPE_PAYMENT_RECEIVE = "SUBMIT_STRIPE_PAYMENT_RECEIVE";
export const SUBMIT_STRIPE_PAYMENT_ERROR = "SUBMIT_STRIPE_PAYMENT_ERROR";

export const searchCollateral = action(SEARCH_COLLATERAL_BEGIN);
export const searchCollateralRequest = action(SEARCH_COLLATERAL_REQUEST);
export const searchCollateralReceive = action(SEARCH_COLLATERAL_RECEIVE);
export const searchCollateralError = action(SEARCH_COLLATERAL_ERROR);

export const fetchPaymentOptions = action(FETCH_PAYMENT_OPTIONS_BEGIN);
export const fetchPaymentOptionsRequest = action(FETCH_PAYMENT_OPTIONS_REQUEST);
export const fetchPaymentOptionsReceive = action(FETCH_PAYMENT_OPTIONS_RECEIVE);
export const fetchPaymentOptionsError = action(FETCH_PAYMENT_OPTIONS_ERROR);

export const fetchLaunchConfig = action(FETCH_LAUNCH_CONFIG_BEGIN);
export const fetchLaunchConfigRequest = action(FETCH_LAUNCH_CONFIG_REQUEST);
export const fetchLaunchConfigReceive = action(FETCH_LAUNCH_CONFIG_RECEIVE);
export const fetchLaunchConfigError = action(FETCH_LAUNCH_CONFIG_ERROR);

export const saveLaunchConfig = action(SAVE_LAUNCH_CONFIG_BEGIN);
export const saveLaunchConfigRequest = action(SAVE_LAUNCH_CONFIG_REQUEST);
export const saveLaunchConfigReceive = action(SAVE_LAUNCH_CONFIG_RECEIVE);
export const saveLaunchConfigError = action(SAVE_LAUNCH_CONFIG_ERROR);

export const clearLaunchConfig = action(CLEAR_LAUNCH_CONFIG_BEGIN);
export const clearLaunchConfigRequest = action(CLEAR_LAUNCH_CONFIG_REQUEST);
export const clearLaunchConfigReceive = action(CLEAR_LAUNCH_CONFIG_RECEIVE);
export const clearLaunchConfigError = action(CLEAR_LAUNCH_CONFIG_ERROR);

export const launchMasternode = action(LAUNCH_MASTERNODE_BEGIN);
export const launchMasternodeRequest = action(LAUNCH_MASTERNODE_REQUEST);
export const launchMasternodeReceive = action(LAUNCH_MASTERNODE_RECEIVE);
export const launchMasternodeError = action(LAUNCH_MASTERNODE_ERROR);

export const fetchLaunchProgress = action(FETCH_LAUNCH_PROGRESS_BEGIN);
export const fetchLaunchProgressRequest = action(FETCH_LAUNCH_PROGRESS_REQUEST);
export const fetchLaunchProgressReceive = action(FETCH_LAUNCH_PROGRESS_RECEIVE);
export const fetchLaunchProgressError = action(FETCH_LAUNCH_PROGRESS_ERROR);

export const fetchInvoice = action(FETCH_INVOICE_BEGIN);
export const fetchInvoiceRequest = action(FETCH_INVOICE_REQUEST);
export const fetchInvoiceReceive = action(FETCH_INVOICE_RECEIVE);
export const fetchInvoiceError = action(FETCH_INVOICE_ERROR);

export const submitStripePayment = action(SUBMIT_STRIPE_PAYMENT_BEGIN);
export const submitStripePaymentRequest = action(SUBMIT_STRIPE_PAYMENT_REQUEST);
export const submitStripePaymentReceive = action(SUBMIT_STRIPE_PAYMENT_RECEIVE);
export const submitStripePaymentError = action(SUBMIT_STRIPE_PAYMENT_ERROR);
