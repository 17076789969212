import {
  LOCAL_STORAGE_TOKEN_KEY
} from "../constants";
class LocalStorageService {
  constructor() {
  }

  setAccessToken(accessToken){
      let key = LOCAL_STORAGE_TOKEN_KEY;
      if(accessToken) {
        localStorage.setItem(key, JSON.stringify(accessToken))
      }
  }

  getAccessToken(){
      let key = LOCAL_STORAGE_TOKEN_KEY;
      let token = localStorage.getItem(key);
      if(!token) return null;
      try{
        token = JSON.parse(token)
      }catch (e) {
         console.log(e);
      }
      return token;
  }
}
export default new LocalStorageService()
