import React from "react";
import { NavLink } from "react-router-dom";
import { matchPath } from "react-router";

const Menu = () => (
  <div className="header__nav__menu">
    <NavLink
      className="header__nav__menu__item header__nav__menu__item--link hidden-sm"
      activeClassName="header__nav__menu__item--active"
      isActive={(match, location) => location.pathname === "/"}
      to={`/`}
    >
      Masternodes
    </NavLink>

    <NavLink
      className="header__nav__menu__item hidden-sm"
      activeClassName="header__nav__menu__item--active"
      to={`/governance`}
      isActive={(match, location) => {
        const actualMatch = matchPath(location.pathname, {
          path: `/governance/open`
        });

        return actualMatch ? true : false;
      }}
    >
      Budget Proposals
    </NavLink>
  </div>
);

export default Menu;
