import React from "react";

const Loader = ({ children, type }) => {
  let className = "loader";

  if (type) {
    className += " loader--" + type;
  }

  let loaderSpinnerClass = "loader__spinner";

  if (type === "horizontal") {
    loaderSpinnerClass += " loader__spinner--small";
  }

  let spinnerClass = "spinner";

  if (type === "horizontal") {
    spinnerClass += " spinner--small";
  }

  return (
    <div className={className}>
      <div className={loaderSpinnerClass}>
        <div className={spinnerClass} />
      </div>
      <div className="loader__message">{children}</div>
    </div>
  );
};

export default Loader;
