import Client from "app.api/util/clients/UserAPIClient";
import { AUTH_BASE, SSO_REFRESH_URL } from "app.constants";
import {
  handleAfterResponseError,
  handleReauthenticate,
  getBearerToken,
  handleAfterResponse
} from "app.api/Common";
import AuthAPI from "app.api/SSO/AuthAPI";

export default new Client({
  serviceBase: AUTH_BASE,
  ssoRefreshURL: SSO_REFRESH_URL,
  onReauthenticate: async newTicket =>
    await handleReauthenticate(newTicket, AuthAPI.getTokenFromTicket),
  getBearerToken,
  afterResponseError: handleAfterResponseError,
  afterResponse: handleAfterResponse
});
