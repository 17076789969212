import React, { useState } from "react";
import Message from "app.components/Common/Message/Message";
import Select, {
  SelectIndicator,
  SelectItem
} from "app.components/Common/Select/Select";
const MasternodeMonitoredAdd = ({
  dismiss,
  add,
  search,
  isLoading,
  result,
  searchError,
  addError
}) => {
  const [publicAddress, setPublicAddress] = useState("");
  const [vin, setVin] = useState("");

  return (
    <div>
      <div className="modal__header">
        <button data-tabbable={true} onClick={dismiss} className="modal__close">
          <span className="sr-only">Close</span>
        </button>
        <div className="text--left">
          <h3>Add monitored masternode</h3>
        </div>
      </div>
      <div className="modal__body">
        <p className="text--left">
          Enter the address that contains your 1,000 Dash and Zaimirai will locate
          your collateral id and index.
        </p>
        <input
          type="text"
          className="form-control"
          placeholder="Public Address"
          value={publicAddress}
          disabled={isLoading}
          onChange={e => setPublicAddress(e.target.value)}
        />
        <div className="text--left">
          {(addError !== null && <Message>{addError}</Message>) ||
            (searchError === "notFound" && (
              <Message>No associated masternodes found.</Message>
            )) ||
            (searchError !== null && <Message>{searchError}</Message>)}
        </div>

        {(result.length && (
          <table className="u-mt-large">
            <thead>
              <tr>
                <th style={{ width: "64px" }} />
                <th className="text--left">Transaction Id</th>
                <th className="text--center">Transaction Index</th>
              </tr>
            </thead>
            <tbody>
              <Select
                value={vin}
                onChange={(e, value) => setVin(value)}
                templateType="table"
              >
                {result.map((item, i) => (
                  <tr key={i} className="bordered">
                    <td className="text--center">
                      <SelectItem
                        value={item.vin}
                        customTemplate={
                          <SelectIndicator selected={item.vin === vin} />
                        }
                      />
                    </td>
                    <td className="text--left word-break">
                      {item.vin.split("-")[0]}
                    </td>
                    <td className="text--center">{item.vin.split("-")[1]}</td>
                  </tr>
                ))}
              </Select>
            </tbody>
          </table>
        )) ||
          null}
      </div>
      <div className="modal__controls text--right">
        <button
          onClick={dismiss}
          data-tabbable={true}
          className="button button--text"
          disabled={isLoading}
        >
          Close
        </button>
        <button
          onClick={() => {
            result.length
              ? add({ key: publicAddress, vin })
              : search({ publicAddress });
          }}
          data-tabbable={true}
          disabled={
            result.length ? !vin : isLoading || publicAddress.length < 20
          }
          className={
            isLoading
              ? "button button--primary button--loading"
              : "button button--primary"
          }
        >
          {result.length ? "START WATCHING" : "SEARCH"}
        </button>
      </div>
    </div>
  );
};

export default MasternodeMonitoredAdd;
