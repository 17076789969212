import React from "react";
import { Link } from "react-router-dom";
import { BALANCE_URL, ACCOUNT_URL } from "app.constants";
import Logo from "app.components/Header/Logo";
import Menu from "app.components/Header/Menu";
import HeaderDropdown from "app.components/Header/HeaderDropdown";
import HeaderDropdownItem from "app.components/Header/HeaderDropdownItem";
import AccountIcon from "app.components/Header/AccountIcon";
import AppsIcon from "app.components/Header/AppsIcon";
import LogoutIcon from "app.components/Header/LogoutIcon";
import UserIcon from "app.components/Header/UserIcon";
import InvoicesIcon from "app.components/Header/InvoicesIcon";

const Header = ({ user, logout }) => (
  <header className="header">
    <div className="header__brand">
      <Link to={`/`}>
        <img src={"https://assets.zaimirai.com/images/common/icons/favicon-96x96.png"} alt={"Zaimirai"} width={35} className="header__brand__logo--full" />
        <span className="product-name">Hosting</span>
      </Link>
    </div>
    <div className="header__nav">
      <Menu />

      <HeaderDropdown descriptor={<AppsIcon />}>
        <HeaderDropdownItem
          className="hidden-md"
          type="link"
          description="Masternodes"
          path={"/"}
        />
        <HeaderDropdownItem
          className="hidden-md"
          type="link"
          description="Budget Proposals"
          path={"/governance"}
        />
        <HeaderDropdownItem
          className="header-dropdown-item--divider"
          type="href"
          description="Support"
          path="https://intercom.help/zaimirai/en/"
        />
        <HeaderDropdownItem
          type="href"
          description="Zaimirai.com"
          path="https://www.zaimirai.com"
        />
      </HeaderDropdown>

      <HeaderDropdown descriptor={<HeaderDropdownDescriptorUser user={user} />}>
        <HeaderDropdownItem
          type="href"
          description="My Account"
          // path={ACCOUNT_URL}
          path={"/account/profile"}
          icon={<AccountIcon />}
        />
        <HeaderDropdownItem
          type="link"
          description="Invoices"
          path={"/invoices"}
          icon={<InvoicesIcon />}
        />
        <HeaderDropdownItem
          type="button"
          description="Sign Out"
          onClick={logout}
          icon={<LogoutIcon />}
        />
      </HeaderDropdown>
    </div>
  </header>
);

const HeaderDropdownDescriptorUser = ({ user }) =>
  (user && (
    <React.Fragment>
      <UserIcon />
      <span className="header-dropdown__descriptor__username visible-lg">
        {user.sub}
      </span>
      <span className="caret visible-lg" />
    </React.Fragment>
  )) ||
  null;
export default Header;
