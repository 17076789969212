import React from "react";
import CopyToClipboard from "app.components/Common/CopyToClipboard/CopyToClipboard";

const GovernanceBroadcastVote = ({ dismiss, proposal, masternodes, vote }) => (
  <div>
    <div className="modal__header">
      <button data-tabbable={true} onClick={dismiss} className="modal__close">
        <span className="sr-only">Close</span>
      </button>
      <div className="text--left">
        <h3>VOTE {proposal.signal}</h3>
      </div>
    </div>
    <div className="modal__body">
      {masternodes.length ? (
        <div>
          <p className="text--left u-mb-large">
            Click the Vote button below to cast a{" "}
            <strong>{proposal.signal}</strong> vote for{" "}
            <strong>{proposal.name}</strong> from your NODE40 hosted masternodes
            with delegated voting key.
          </p>
          <p className="text--left u-mb-large">
            To cast a vote from your local wallet, use the following command:
          </p>
        </div>
      ) : (
        <p className="text--left u-mb-large">
          To cast a <strong>{proposal.signal}</strong> vote from your local
          wallet for <strong>{proposal.name}</strong>, use the following
          command:
        </p>
      )}

      <CopyToClipboard
        rows={2}
        label="Copy"
        value={`gobject vote-many ${proposal.hash} funding ${proposal.signal}`}
      />
    </div>
    <div className="modal__controls text--right">
      <button
        onClick={dismiss}
        data-tabbable={true}
        className="button button--text u-m-default"
      >
        Close
      </button>
      {masternodes.length ? (
        <button
          onClick={() => {
            dismiss();
            vote(proposal, masternodes);
          }}
          className={
            (proposal.signal === "YES" && "button button--positive") ||
            (proposal.signal === "NO" && "button button--danger") ||
            (proposal.signal === "ABSTAIN" && "button button--outline-primary")
          }
        >
          Vote {proposal.signal}
        </button>
      ) : null}
    </div>
  </div>
);

export default GovernanceBroadcastVote;
