import * as governanceActions from "app.actions/governance";

let initialState = {
  proposals: [],
  isLoading: false,
  isVoteLoading: false
};

const governance = (state = initialState, action) => {
  switch (action.type) {
    case governanceActions.FETCH_GOVERNANCE_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case governanceActions.FETCH_GOVERNANCE_RECEIVE: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    case governanceActions.FETCH_GOVERNANCE_ERROR: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    case governanceActions.VOTE_REQUEST: {
      return {
        ...state,
        isVoteLoading: true,
        ...action.data
      };
    }
    case governanceActions.VOTE_RECEIVE: {
      return {
        ...state,
        isVoteLoading: false,
        ...action.data
      };
    }
    case governanceActions.VOTE_ERROR: {
      return {
        ...state,
        isVoteLoading: false,
        ...action.data
      };
    }
    default:
      return state;
  }
};

export default governance;
