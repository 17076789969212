import React from "react";

const MasternodeMonitoredRemove = ({ dismiss, remove, isLoading }) => {
  return (
    <div>
      <div className="modal__header">
        <button data-tabbable={true} onClick={dismiss} className="modal__close">
          <span className="sr-only">Close</span>
        </button>
        <div className="text--left">
          <h3>STOP WATCHING THIS NODE?</h3>
        </div>
      </div>
      <div className="modal__body">
        <p className="text--left">
          Are you sure you want to remove this node from your monitor?
        </p>
      </div>
      <div className="modal__controls text--right">
        <button
          onClick={dismiss}
          className="button button--text"
          disabled={isLoading}
        >
          Close
        </button>
        <button
          onClick={() => remove()}
          data-tabbable={true}
          disabled={isLoading}
          className={
            isLoading
              ? "button button--primary button--loading"
              : "button button--primary"
          }
        >
          YES, REMOVE THE NODE.
        </button>
      </div>
    </div>
  );
};

export default MasternodeMonitoredRemove;
