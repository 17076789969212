import SSOClient from "./base/SSOClient";

/**
 * All requests pertaining to asset requests
 * @memberof HTTPClients
 * @extends HTTPClients.Base.SSOClient
 */
class CratchitAPIClient extends SSOClient {
  /**
   * Constructs a new instance of the AssetAPIClient
   * @param {HTTPClients.HTTPClientConfig} config - The configuration for this HTTP Client.
   */
  constructor(config) {
    super(config);

    const { getBearerToken } = config;

    if (typeof getBearerToken !== "function")
      throw new Error(
        "Must provide a getBearerToken method that returns a token"
      );

    // Add the bearer token request interceptor
    this.instance.interceptors.request.use(requestConfig => {
      if (requestConfig.authenticated) {
        const token = getBearerToken();

        if (typeof token !== "undefined") {
          requestConfig.headers.Authorization = `Bearer ${token}`;
        }
      }

      return requestConfig;
    });
  }
}

export default CratchitAPIClient;
