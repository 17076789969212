import { call, put, takeEvery, select } from "redux-saga/effects";
import { goToLogin } from "app.actions/authentication";
import * as userActions from "app.actions/user";
import UserAPI from "app.api/User/UserAccountAPI";
import AuthAPI from "app.api/SSO/AuthAPI";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { handleError } from "app.sagas/error";
import LocalStorageService from "../service/LocalStorageService";
import { BASE_PATH } from "app.constants";
import jwtDecode from "jwt-decode";
const userSagas = [
  takeEvery(userActions.USER_TOKEN_FETCH, fetchNewToken),
  takeEvery(userActions.USER_FETCH, fetchUser),
  takeEvery(userActions.AUTHORIZE_USER_BEGIN, getTokenFromTicket),
  takeEvery(userActions.FETCH_USER_CREDITS_BEGIN, fetchUserCredits)
];

export default userSagas;

function* getTokenFromTicket(action) {
  //console.log(action);
  //return;
  try {
    const { ticket, redirectTo, fromLogin = false } = action;

    yield put(userActions.requestAuthorizeUser());

    if (fromLogin) {
      yield put(userActions.resetUser());
    }

    let response = yield call(AuthAPI.getTokenFromTicket, ticket);
    console.log("getTokenFromTicket", getTokenFromTicket)
    if (response.error) {
     // yield put(goToLogin());
      console.log(response.error);
    } else {
      let { body } = response;
      const { access_token } = body;
      // Do not put these in a yield all call; we have to consume the receive auth call
      // before we can navigate or we crash the app. so there's that
      LocalStorageService.setAccessToken(access_token);
      yield put(userActions.receiveAuthorizeUser(access_token, body));

      window.location.replace(redirectTo || BASE_PATH);
    }
  } catch (err) {
    console.log("getTokenFromTicket", err);
   // yield put(goToLogin());
  }
}

// ToDo: Add a 'go-back-to-org' saga here;
// This saga will clear the session, and go back to login so that you can go back to the org page? Or maybe something else
// Because that's effectively just logging out if we do the org page properly

function* fetchNewToken(action) {
  try {
    const { token } = action;

    const userIsAuthenticating = yield select(
      state => state.user.isAuthenticating
    );
    if (userIsAuthenticating) return;

    yield put(userActions.requestToken());

    const response = yield call(UserAPI.refreshUserToken, token);
    const { error, body } = response;

    if (error) {
      if (error === 401) {
        yield put(userActions.invalidateToken());
      }
    } else {
      yield put(userActions.receiveToken(body));
    }
  } catch (err) {
    yield put(userActions.fetchNewTokenError());
    yield put(handleError({ err }));
  }
}

function* fetchUser(action) {
  try {
    const userLoading = yield select(state => state.user.loading);
    if (userLoading) return;

    yield put(userActions.requestUser());

    const response = yield call(UserAPI.getUser);

    if (!response.error) {
      yield put(userActions.fetchUserError());
      yield put(handleError({ err: response.error }));
    } else {
      yield put(userActions.receiveUser(response.body));
    }
  } catch (err) {
    yield put(userActions.fetchUserError());
    yield put(handleError({ err }));
  }
}

function* fetchUserCredits(action) {
  try {
    const selector = state => state.user.isFetchUserCreditsLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(userActions.fetchUserCreditsRequest(nextStatus));

    const credits = yield call(
      HostingAPI.requestUserCredits,
      action.data.userId
    );

    yield put(userActions.fetchUserCreditsReceive({ credits }));
  } catch (err) {
    yield put(userActions.fetchUserCreditsError());
    yield put(handleError({ err }));
  }
}
