import { Component } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import { authorizeUser } from "app.actions/user";

class AuthenticateUser extends Component {
  componentDidMount() {
    const queryParams = querystring.parse(this.props.location.search.substr(1));

    const { ticket, redirect } = queryParams;

    // no ticket
    if (typeof ticket === "undefined") {
      this.props.replace("/");
      return;
    }

    // if a user is some how redirected to this route send them to home
    const redirectTo =
      redirect && redirect.indexOf("authenticate") >= 0 ? null : redirect;

    this.props.authorizeUser(ticket, redirectTo, true);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {};
};

AuthenticateUser = connect(
  mapStateToProps,
  { authorizeUser }
)(AuthenticateUser);

export default AuthenticateUser;
