import * as invoiceActions from "app.actions/invoice";

let initialState = {
  invoices: [],
  isUpdateInvoiceLoading: false,
  isLoading: false
};

const invoice = (state = initialState, action) => {
  switch (action.type) {
    case invoiceActions.FETCH_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case invoiceActions.FETCH_INVOICES_RECEIVE: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    case invoiceActions.FETCH_INVOICES_ERROR: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    case invoiceActions.UPDATE_INVOICE_REQUEST: {
      return {
        ...state,
        isUpdateInvoiceLoading: true
      };
    }
    case invoiceActions.UPDATE_INVOICE_RECEIVE: {
      return {
        ...state,
        isUpdateInvoiceLoading: false,
        ...action.data
      };
    }
    case invoiceActions.UPDATE_INVOICE_ERROR: {
      return {
        ...state,
        isUpdateInvoiceLoading: false,
        ...action.data
      };
    }
    default:
      return state;
  }
};

export default invoice;
