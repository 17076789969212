import React from "react";
import Loader from "app.components/Common/Loader/Loader";
import Message from "app.components/Common/Message/Message";

const Content = ({
  loading,
  loaderType,
  children,
  customLoading,
  empty = false,
  ready = true
}) => {
  children = children.constructor === Array ? children : [children];

  if (loading || !ready) {
    return customLoading || <Loader type={loaderType} />;
  }

  if (empty) {
    const message = children.find(
      child => child.type.displayName === "ContentEmpty"
    );

    return message ? message : <Message>Empty</Message>;
  }

  children = children.filter(
    child => child && child.type && child.type.displayName !== "ContentEmpty"
  );

  return children;
};

export default Content;

const ContentEmpty = ({ children }) => {
  return children;
};
ContentEmpty.displayName = "ContentEmpty";

export { ContentEmpty };
