import React from "react";

const wipeFragments = children => {
  return children.reduce((list, item) => {
    if (!item) {
      return list;
    }
    return list.concat(
      item.type === React.Fragment ? item.props.children : item
    );
  }, []);
};

const Instructions = ({ children = [] }) => (
  <div className="instructions">
    <ol>
      {wipeFragments(children).map((child, index) => (
        <li key={index}>{React.cloneElement(child, { index })}</li>
      ))}
    </ol>
  </div>
);

const InstructionsItem = ({ children, index }) => (
  <React.Fragment>
    <div className="instructions__index">{index + 1}</div>
    <div className="instructions__item">{children}</div>
  </React.Fragment>
);

const InstructionsItemInput = ({
  children,
  onChange,
  value = "",
  placeholder,
  name
}) => {
  return (
    <React.Fragment>
      {children}
      <input
        type="text"
        className="form-control instructions__item__input"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

export default Instructions;
export { InstructionsItem, InstructionsItemInput };
