import React, { useState, useContext } from "react";
import {
  StepperHeading,
  StepperControls
} from "app.components/Common/Stepper/Stepper";
import Instructions, {
  InstructionsItem,
  InstructionsItemInput
} from "app.components/Common/Instructions/Instructions";
import Tabs, { TabsItem } from "app.components/Common/Tabs/Tabs";
import CopyToClipboard from "app.components/Common/CopyToClipboard/CopyToClipboard";
import LaunchContext from "app.components/Launch/LaunchContext";

import { MASTERNODE_PORT } from "app.constants";

const LaunchStepMasternodeRegistration = () => {
  const { launch } = useContext(LaunchContext);
  const { config } = launch;
  const { isThirdParty } = config;
  const selectedTab = isThirdParty || config.walletType === "dashCore" ? 0 : 1;
  const [tab, setTab] = useState(selectedTab);
  const [form, setForm] = useState({});

  const setFormValue = event => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const {
    ownerAddress,
    payoutAddress,
    thirdPartyAddress,
    fundAddress,
    feeAddress,
    tx,
    collateralAddress,
    signMessage,
    signature
  } = form;
  const { submit, sign, prepare, fund } = buildCommands(form, config);

  const isDefaultValid = ownerAddress && payoutAddress;
  const isStandardValid = signature && tx && collateralAddress && signMessage;
  const isThirdPartyValid = isThirdParty && thirdPartyAddress;
  const isFormValid = isDefaultValid && (isStandardValid || isThirdPartyValid);

  return (
    <React.Fragment>
      <StepperHeading
        heading="Masternode Registration"
        subHeading="Register your masternode by issuing a PROTX"
      />
      <Tabs onChange={(e, v, index) => setTab(index)} selectedTab={tab}>
        <TabsItem title="Dash Core Wallet">
          <p className="dashcore-wallet">
            Check out our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://intercom.help/zaimirai/en/articles/5604901-launch-a-masternode-masternode-registration-with-the-dash-core-wallet"
            >
              support document
            </a>{" "}
            for the Dash Core Wallet.
          </p>
          <Instructions>
            <InstructionsItem>
              Unlock your Dash Core wallet. From the <strong>settings</strong>{" "}
              menu select <strong>Unlock Wallet</strong>.
            </InstructionsItem>
            <InstructionsItem>
              Select <strong>Debug console</strong> From the{" "}
              <strong>Tools</strong> menu.
            </InstructionsItem>
            <InstructionsItem>
              Type <strong>getnewaddress</strong> into the debug console and hit
              enter. This is the masternode's <strong>Owner Address</strong>.
            </InstructionsItem>
            <InstructionsItem>
              <InstructionsItemInput
                placeholder="Owner Address"
                name="ownerAddress"
                value={ownerAddress}
                onChange={setFormValue}
              >
                Enter your <strong>Owner Address</strong> below:
              </InstructionsItemInput>
            </InstructionsItem>
            <InstructionsItem>
              <InstructionsItemInput
                placeholder="Payout Address"
                name="payoutAddress"
                value={payoutAddress}
                onChange={setFormValue}
              >
                Enter your <strong>Payout Address</strong> below. The Payout
                Address is the address to which your masternode rewards will be
                deposited.
              </InstructionsItemInput>
            </InstructionsItem>
            {(isThirdParty && (
              <React.Fragment>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="Third Party Address"
                    name="thirdPartyAddress"
                    value={thirdPartyAddress}
                    onChange={setFormValue}
                  >
                    Enter the <strong>Third Party Address</strong> below (the
                    address to which your 1,000 Dash will be sent during
                    registration e.g., SALT Lending).
                  </InstructionsItemInput>
                </InstructionsItem>

                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="Fund Address"
                    name="fundAddress"
                    value={fundAddress}
                    onChange={setFormValue}
                  >
                    (Optional) Enter a <strong>Fund Address</strong>. If
                    provided, it must contain at least 1,000 Dash to fund your
                    masternode plus an extra small amount (about 0.00001 Dash)
                    to cover the network fee for registration. If not specified,{" "}
                    <strong>Payout Address</strong> will be used.
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <CopyToClipboard
                    disabled={
                      !ownerAddress || !payoutAddress || !thirdPartyAddress
                    }
                    value={fund}
                  >
                    Paste the following command into the Debug console and hit
                    enter.
                  </CopyToClipboard>
                </InstructionsItem>
              </React.Fragment>
            )) || (
              <React.Fragment>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="Fee Address"
                    name="feeAddress"
                    value={feeAddress}
                    onChange={setFormValue}
                  >
                    (Optional) Enter a <strong>Fee Address.</strong>
                    If provided, it must contain a small amount (about 0.00001
                    Dash) to cover the network fee for registration. If not
                    specified, <strong>Payout Address</strong> will be used.
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <CopyToClipboard
                    disabled={!ownerAddress || !payoutAddress}
                    value={prepare}
                  >
                    Paste the following command into the Debug console and hit
                    enter.
                  </CopyToClipboard>
                </InstructionsItem>
              </React.Fragment>
            )}
            {!isThirdParty && (
              <React.Fragment>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="tx"
                    name="tx"
                    value={tx}
                    onChange={setFormValue}
                  >
                    Paste the <strong>tx</strong> from the debug console output
                    of step 7 below. (Make sure to include everything that is
                    between quotes)
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="collateralAddress"
                    name="collateralAddress"
                    value={collateralAddress}
                    onChange={setFormValue}
                  >
                    Paste the <strong>collateralAddress</strong> from the debug
                    console output of step 7 below. (Make sure to include
                    everything that is between quotes)
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="Sign Message"
                    name="signMessage"
                    value={signMessage}
                    onChange={setFormValue}
                  >
                    Paste the <strong>signMessage</strong> from the debug
                    console output of step 7 below. (Make sure to include
                    everything that is between quotes)
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <CopyToClipboard
                    disabled={!tx || !collateralAddress || !signMessage}
                    value={sign}
                  >
                    Paste the following command into the Debug console and hit
                    enter.
                  </CopyToClipboard>
                </InstructionsItem>
                <InstructionsItem>
                  <InstructionsItemInput
                    placeholder="Signature"
                    name="signature"
                    value={signature}
                    onChange={setFormValue}
                  >
                    Paste the output from the previous step below.
                  </InstructionsItemInput>
                </InstructionsItem>
                <InstructionsItem>
                  <CopyToClipboard disabled={!signature} value={submit}>
                    Paste the following command into the Debug console and hit
                    enter.
                  </CopyToClipboard>
                </InstructionsItem>
              </React.Fragment>
            )}
          </Instructions>
        </TabsItem>
        <TabsItem title="Hardware Wallet">
          <div className="hardware-wallet">
            <p>
              Most of what you need to run your maternode from a hardware wallet
              is available below. For most hardware wallet setups, you will need
              to determine the BIP32 path by looking at your hardware wallet's
              web interface.
            </p>
            <p>
              If you are looking for an easy to use third-party tool, check out
              our support article on using the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.node40.com/hc/en-us/articles/360030538694-Launch-a-Masternode-Masternode-Registration-with-the-Dash-Masternode-Tool-DMT-"
              >
                Dash Masternode Tool (DMT)
              </a>
              .
            </p>
            <div className="hardware-wallet__instructions">
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard label="Copy" value={config.ip}>
                  IP Address
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard label="Copy" value={MASTERNODE_PORT}>
                  IP Port
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard
                  label="Copy"
                  value={config.collateral && config.collateral.split("_")[0]}
                >
                  Collateral Hash (Collateral TX ID)
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard
                  label="Copy"
                  value={config.collateral && config.collateral.split("_")[1]}
                >
                  Collateral Index (TX index)
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard label="Copy" value={config.publicKey}>
                  Public Key (Collateral)
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard label="Copy" value={config.votingPublicKey}>
                  Voting Address (Voting Public Key)
                </CopyToClipboard>
              </div>
              <div className="hardware-wallet__instructions__item">
                <CopyToClipboard label="Copy" value={config.operatorPublicKey}>
                  Operator Key (BLS Public Key)
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </TabsItem>
      </Tabs>

      <StepperControls
        backHidden={true}
        nextDisabled={!isFormValid && tab === 0}
        onSubmitParams={{
          lastStep: 4
        }}
      />
    </React.Fragment>
  );
};

const buildCommands = (form, masternode) => {
  const {
    ownerAddress = "<OwnerAddress>",
    payoutAddress = "<PayoutAddress>",
    thirdPartyAddress = "<ThirdPartyAddress>",
    fundAddress = "",
    feeAddress = "",
    tx = "<Tx>",
    collateralAddress = "<CollateralAddress>",
    signMessage = "<SignMessage>",
    signature = "<Signature>"
  } = form;
  const { ip, operatorPublicKey, collateral, votingPublicKey } = masternode;
  const protxDefault = `${ip}:${MASTERNODE_PORT} ${ownerAddress} ${operatorPublicKey} ${votingPublicKey} 0 ${payoutAddress}`;

  return {
    prepare: buildProtx("register_prepare", [
      collateral.replace("_", " "),
      protxDefault,
      feeAddress
    ]),
    fund: buildProtx("register_fund", [
      thirdPartyAddress,
      protxDefault,
      fundAddress
    ]),
    sign: `signmessage ${collateralAddress} ${signMessage}`,
    submit: `protx register_submit ${tx} ${signature}`
  };
};

const buildProtx = (name, props) => ["protx", name, ...props].join(" ").trim();

export default LaunchStepMasternodeRegistration;
