import React, { useState } from "react";

const MasternodeTagAdd = ({ dismiss, add }) => {
  const [tag, setTag] = useState("");
  return (
    <div>
      <div className="modal__header">
        <button data-tabbable={true} onClick={dismiss} className="modal__close">
          <span className="sr-only">Close</span>
        </button>
        <div className="text--left">
          <h3>Add tag</h3>
        </div>
      </div>
      <div className="modal__body">
        <p className="text--left">Add a tag to your masternode</p>
        <input
          type="text"
          className="form-control"
          placeholder="Tag"
          value={tag}
          onChange={e => setTag(e.target.value)}
        />
      </div>
      <div className="modal__controls text--right">
        <button
          onClick={dismiss}
          data-tabbable={true}
          className="button button--text"
        >
          Close
        </button>
        <button
          onClick={() => add(tag)}
          data-tabbable={true}
          disabled={tag.length > 20}
          className="button button--primary"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default MasternodeTagAdd;
