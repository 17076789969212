import React, { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import ModalBody from "app.components/Modal/ModalBody";
/**
 * Modal
 * @example
 * ```js
 * <Modal
 *   isOpen={isMyComponentOpen}
 *   modalClass="modalClassName"
 *   dismiss={dimissCallback}
 * >
 *  <MyComponent />
 * </Modal>
 * ````
 */
export default class Modal extends Component {
  static propTypes = {
    /**
     * The component which should render when isOpen is true. This will be structurally a child component in JSX, not a an attribute on the Modal tag.
     * Must be a valid React Component and not a React DOM element.
     * @type {element}
     */
    children: PropTypes.element,
    /**
     * Variable or statement that will evaluate to true or false that says whether or not to render this modal
     * @type {Boolean}
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * The CSS className that the modal comonent will render with. This name will be prefixed with modal__. Use this to define custom styles just for this modal.
     * importantly, you MUST provide this class with a minimum of a rule defining the height of the modal
     * @type {string}
     */
    modalClass: PropTypes.string.isRequired,
    /**
     * The CSS className that the outermost comonent will render with. Only change this if you want to customize the CSS beyond the supplied defaults.
     * @type {string}
     */
    containerClassName: PropTypes.string,
    /**
     * The function the modal should call when the user clicks the close button or presses the escape key
     * @type {func}
     */
    dismiss: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: null,
    isOpen: false,
    containerClassName: "modal__shadowbox"
  };

  constructor(props) {
    super(props);
    if (
      props.children &&
      typeof props.children.type === "string" // if the type is a string, I think that means it is a DOM element. like 99% sure.
    ) {
      console.error(
        `Modal body must be a valid React Component. Type is "${props.children.type}", which is a React DOM element.`
      );
    }
  }

  render() {
    let { children, containerClassName, isOpen, ...props } = this.props;
    return (
      <CSSTransition
        in={isOpen}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={300}
        classNames={containerClassName}
      >
        <ModalBody body={children} {...props} />
      </CSSTransition>
    );
  }
}
