import React from "react";

const Grid = ({ className, color = "#FFF" }) => (
  <svg
    className={className}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Switch App</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill={color}>
        <rect id="Rectangle" x="0" y="0" width="10" height="10" />
        <rect id="Rectangle" x="15" y="0" width="10" height="10" />
        <rect id="Rectangle" x="30" y="0" width="10" height="10" />
        <rect id="Rectangle" x="0" y="15" width="10" height="10" />
        <rect id="Rectangle" x="15" y="15" width="10" height="10" />
        <rect id="Rectangle" x="30" y="15" width="10" height="10" />
        <rect id="Rectangle" x="0" y="30" width="10" height="10" />
        <rect id="Rectangle" x="15" y="30" width="10" height="10" />
        <rect id="Rectangle" x="30" y="30" width="10" height="10" />
      </g>
    </g>
  </svg>
);

export default Grid;
