import React from "react";
import { Link } from "react-router-dom";

const LaunchStepConfirmation = () => {
  return (
    <React.Fragment>
      <div className="launch-message">
        <div className="launch-message__title">
          <span className="payment__progress__item__done glyphicons glyphicons-ok-2" />
          Masternode Launch complete
        </div>
        <div className="launch-message__description">
          Your masternode is now registered as a Deterministic Dash Masternode
          and will display as <strong>enabled</strong> on the NODE40 Dashboard
          in approximately 3 minutes.
        </div>
      </div>
      <div className="text--right">
        <Link to="/" className="button button--primary">
          GO TO MAIN PAGE
        </Link>
      </div>
    </React.Fragment>
  );
};
export default LaunchStepConfirmation;
