import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import browserHistory from "app.config/configureBrowserHistory";
import AuthenticateUser from "app.components/AuthenticateUser";
import AppContainer from "app.components/AppContainer";
import { requireAuthentication } from "app.components/AuthenticatedComponent";

// the root comonent is a stateless functional react compoent
// (meaning it isn't created as a compnent class, but just a basic
// function which takes in props as arguments. this component doesn't have the
// typical react lifecycle functions. These are pure
// functions which can possibly be further optomized automically in React
// becuase the library will be able to make certain assumptions

// The Root component takes in the Redux store as its lone prop.
const Root = (
  { store } // Provider is a special Redux component that bridges React to Redux.
) => (
  <Provider store={store}>
    {/* These components are React Router components which determine the Route (URL) heirarchy/pattern*/}
    <ConnectedRouter history={browserHistory}>
      <Switch>
        <Route exact={true} path="/authenticate" component={AuthenticateUser} />
        {/* TODO: Maybe conditionally render this; are we an "org-choosing-mode" ? if so, keep URL in place and show
      the org page; if not, run authenticate user or something */}
        <Route path="/" component={requireAuthentication(AppContainer)} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
