const isNullOrEmpty = (val) => {
  if (val === null) return true;
  if (typeof val === 'undefined') return true;
  return val.length === 0;
};

const truncate = (str, maxLength) => (str.length > maxLength ? `${str.substring(0, maxLength)}...` : str);

export default {
  isNullOrEmpty, truncate
};
