import React from "react";

const CashMoney = ({ dollars, places = 2, negativeAsParens = false }) => {
  let dollarsAsFloatFull;
  let isNegative = false;
  let isZero = false;

  if (typeof dollars === "undefined") return null;
  if (dollars === null) return null;

  if (typeof dollars === "number") {
    dollarsAsFloatFull = dollars;
    isNegative = dollarsAsFloatFull < 0 ? true : false;
    isZero = dollarsAsFloatFull === 0 ? true : false;
  }

  if (typeof dollars === "string") {
    const cleanString = `${dollars}`.replace(/[^-.\d.]+/g, "");
    dollarsAsFloatFull = parseFloat(cleanString);
    isZero = dollarsAsFloatFull === 0 ? true : false;
    isNegative =
      cleanString.substr(0, 1) === "-" || dollars.substr(0, 1) === "(";
  }

  if (dollarsAsFloatFull < 0.01 && dollarsAsFloatFull > 0.0) {
    return (
      <span className="money">
        <span className="money__usd">Less Than $</span>
        <span className="money__dollars">0</span>
        <span className="money__change">.01</span>
      </span>
    );
  }

  if (dollarsAsFloatFull > -0.01 && dollarsAsFloatFull < 0.0) {
    if (negativeAsParens) {
      return (
        <span className="money money--negative">
          <span className="money__usd">(Less Than $</span>
          <span className="money__dollars">0</span>
          <span className="money__change">.01)</span>
        </span>
      );
    } else {
      return (
        <span className="money money--negative">
          <span className="money__usd">Less Than -$</span>
          <span className="money__dollars">0</span>
          <span className="money__change">.01</span>
        </span>
      );
    }
  }

  const dollarsAsFloat = dollarsAsFloatFull.toFixed(places);
  const parts = `${dollarsAsFloat}`.split(".");
  let dollarsPart = parts[0]
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let changePart = parts[1];

  return (
    <span
      className={
        isNegative
          ? "money money--negative"
          : isZero
          ? "money money--zero"
          : "money"
      }
    >
      {isNegative && negativeAsParens ? (
        <span className="money__usd">(</span>
      ) : null}
      {isNegative && !negativeAsParens ? (
        <span className="money__usd">-</span>
      ) : null}
      <span className="money__usd">$</span>
      <span className="money__dollars">{dollarsPart}</span>
      <span className="money__change">.{changePart}</span>
      {isNegative && negativeAsParens ? (
        <span className="money__usd">)</span>
      ) : null}
    </span>
  );
};

export default CashMoney;
