import React, { useState } from "react";

import SelectItem from "app.components/Common/Select/SelectItem";
import SelectIndicator from "app.components/Common/Select/SelectIndicator";
import SelectItemCard from "app.components/Common/Select/SelectItemCard";

const SelectContext = React.createContext({});

const Select = ({
  children = [],
  onChange,
  defaultValue,
  className,
  value,
  templateType = "list"
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleItemClick = (e, value) => {
    setSelectedValue(value);
    onChange(e, value);
  };
  const getTabIndex = () => {
    let tabIndexCount = 0;

    return () => {
      tabIndexCount++;
      return tabIndexCount;
    };
  };
  const context = {
    selectedValue: value || selectedValue,
    handleItemClick,
    getTabIndex: getTabIndex()
  };

  const isList = templateType === "list";

  const classNames = ["select"];

  if (className) {
    classNames.push(className);
  }

  return (
    <SelectContext.Provider value={context}>
      {(isList && (
        <ul className={classNames.join(" ")}>
          {children.map((child, index) => (
            <li key={index}>{child}</li>
          ))}
        </ul>
      )) ||
        children}
    </SelectContext.Provider>
  );
};

export default Select;

export { SelectItem, SelectIndicator, SelectContext, SelectItemCard };
