import API from "app.api/util/apis";
import instance from "app.api/User/Client";

class UserAccountAPI extends API {
  constructor() {
    super(instance);
  }

  /**
   * Gets the current user based on attached credentials
   * @async
   * @function
   * @returns {(APIs.HTTPSuccessResponse|APIs.HTTPErrorResponse)} The Response or Error message
   */
  getUser = async () => {
    return this.get("/profile/show", {
      authenticated: true
    });
  };

  /**
   * Submits a request to update the user's token
   * @async
   * @function
   * @param {string} token - The user's token
   * @returns {(APIs.HTTPSuccessResponse|APIs.HTTPErrorResponse)} The Response or Error message
   */
  refreshToken = async token => {
    const body = { token };

    return this.put("/home/refreshToken", body);
  };
}

export default new UserAccountAPI();
