import React from "react";

const ModalError = ({ dismiss, error }) => {
  return (
    <div>
      <div className="modal__header">
        <button data-tabbable={true} onClick={dismiss} className="modal__close">
          <span className="sr-only">Close</span>
        </button>
        <div className="text--left">
          <h3>Unexpected error</h3>
        </div>
      </div>
      <div className="modal__body">
        <p className="text--left">{error.message}</p>
      </div>
      <div className="modal__controls text--right">
        <button onClick={dismiss} className="button button--text">
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalError;
