import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import NodeStatus from "../Common/NodeStatus";
import { middleDots } from "app.utils";
import {
  DASH_COLLATERAL_LENGTH,
  DASH_COLLATERAL_SMALL_SCREEN_SIZE,
  DASH_COLLATERAL_SMALL_SCREEN_LENGTH
} from "app.constants";
import { useWindowSize } from "app.utils";

const MasternodePendingList = ({ list = [] }) => {
  const containsEmptyCollateral = list.some(item => !item.collateral);
  const [collateralLength, setCollateralLength] = useState(24);
  const { isLarge, windowSize } = useWindowSize();
  useEffect(() => {
    if (windowSize <= DASH_COLLATERAL_SMALL_SCREEN_SIZE) {
      setCollateralLength(DASH_COLLATERAL_SMALL_SCREEN_LENGTH);
    } else {
      setCollateralLength(DASH_COLLATERAL_LENGTH);
    }
  }, [windowSize]);

  const containsPendingActionRegistrationDetails = list.some(item => item.pendingAction === "REGISTRATION DETAILS");

  return (
    <React.Fragment>
      {containsPendingActionRegistrationDetails ? (
        <p className="masternode-list-pending__message">
          Click <strong>Registration Details</strong> below for the final step to enable your masternode to start receiving rewards.<br />
          <i>A masternode will be added to the network after a single confirmation (a few minutes after the PROTX is issued).</i>
        </p>
      ) : null}
      {isLarge ? (
        <table className="masternode-list">
          <thead>
            <tr>
              <th className="text--left">
                {containsEmptyCollateral ? "Public Address" : "Collateral"}
              </th>
              <th className="text--center">Status</th>
              <th className="text--right">Creation</th>
              <th className="border-right" />
            </tr>
          </thead>
          <tbody>
            {list.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td>
                  {!containsEmptyCollateral
                    ? item.collateral
                    : item.publicAddress}
                </td>
                <td className="text--center">
                  <NodeStatus status={"PENDING"} />
                </td>
                <td className="text--right">
                  {moment(item.creationDate).fromNow()}
                </td>
                <td className="text--right">
                  <Link to={`/launch/${item.commissionId}`}>
                    {item.pendingAction}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="masternode-card-container">
          {list.map((item, itemIndex) => (
            <div key={itemIndex} className="masternode-card">
              <div className="masternode-card__descriptor-container">
                <div className="masternode-card__descriptor masternode-card__descriptor--100">
                  <div className="masternode-card__descriptor__title">
                    Collateral
                  </div>
                  <div className="mono">
                    {!containsEmptyCollateral
                      ? middleDots(
                          item.collateral.replace("_", "-"),
                          collateralLength
                        )
                      : item.publicAddress}
                  </div>
                </div>
              </div>
              <div className="masternode-card__item-container">
                <div className="masternode-card__item">
                  <div className="masternode-card__item__info masternode-card__item__info--grow">
                    <div className="flex flex--row">
                      <span className="masternode-card__item__info__value">
                        <NodeStatus status={"PENDING"} />
                      </span>
                    </div>
                  </div>
                  <div className="masternode-card__item__info justify--right">
                    <span className="masternode-card__item__info__title">
                      Creation
                    </span>
                    <span className="masternode-card__item__info__value">
                      {moment(item.creationDate).fromNow()}
                    </span>
                  </div>
                  <div className="break-column visible-sm" />

                  <div className="masternode-card__item__info masternode-card__item__info--complete-registration">
                    <Link
                      to={`/launch/${item.commissionId}`}
                      className="button button--primary"
                    >
                      {item.pendingAction}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default MasternodePendingList;
