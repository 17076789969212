import React from "react";
import { Link } from "react-router-dom";
import {
  COMMON_DATE_ONLY_FORMAT,
  INVOICE_STATUS,
  INVOICE_METHODS
} from "app.constants";
import moment from "moment";
import DigitalMoney from "app.components/Util/DigitalMoney";
import CashMoney from "app.components/Util/CashMoney";

const InvoiceList = ({ list }) => (
  <table>
    <thead>
      <tr>
        <th className="text--left">Invoice</th>
        <th className="text--center">Due Date</th>
        <th className="text--left">Payment Address</th>
        <th className="text--center">Payment Method</th>
        <th className="text--center">Amount</th>
        <th className="text--center">Status</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      {list.map((item, itemIndex) => (
        <tr key={itemIndex}>
          <td className="text--left">{item.sequence}</td>
          <td className="text--center">
            {moment(item.dueDate).format(COMMON_DATE_ONLY_FORMAT)}
          </td>
          <td className="text--left">{item.paymentAddress}</td>
          <td className="text--center">
            {item.method === INVOICE_METHODS.CREDIT_CARD
              ? "Credit Card"
              : "Dash"}
          </td>
          <td className="text--center">
            {item.method === INVOICE_METHODS.CREDIT_CARD ? (
              <CashMoney dollars={item.fiatValue} />
            ) : (
              <span>
                <DigitalMoney coins={item.amount.toFixed("8")} /> Dash
              </span>
            )}
          </td>

          <td className="text--center">
            <span
              className={
                item.status === INVOICE_STATUS.PAID
                  ? "node-status node-status--enabled"
                  : "node-status node-status--not-enabled"
              }
            >
              {item.status}
            </span>
          </td>
          <td>
            <Link to={`/invoices/${item.id}`}>View</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
export default InvoiceList;
