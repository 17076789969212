/*
  The entry point for the app. This boostraps the redux store and attaches the React app to the DOM.
 */

import React from "react";
import { render } from "react-dom";
import ReactGA from "react-ga";

import Root from "app.components/Root";
import NoNetwork from "app.components/Util/NoNetwork";
import { store } from "app.config/configureStore";

if (window.navigator.onLine === false) {
  render(<NoNetwork />, document.getElementById("app-root"));
} else {
  if (process.env.NODE40_ENV === "production") {
    ReactGA.initialize("UA-60844224-1");
  }
  render(<Root store={store} />, document.getElementById("app-root"));
}
