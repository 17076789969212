import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "app.components/Modal/Modal";
import { withRouter } from "react-router-dom";
import { dismissModal, openModal } from "app.actions/ui";
import {
  fetchMasternodes,
  addMonitoredMasternode,
  searchMonitoredMasternode,
  removeMonitoredMasternode,
  resetMasternodeMonitoredAdd
} from "app.actions/masternodes";
import { NETWORK_STATUS } from "app.constants";
import MasternodeSummary from "app.components/Masternode/MasternodeSummary";
import MasternodePendingList from "app.components/Masternode/MasternodePendingList";
import MasternodeHostedList from "app.components/Masternode/MasternodeHostedList";
import MasternodeMonitoredList from "app.components/Masternode/MasternodeMonitoredList";
import MasternodeMonitoredAdd from "app.components/Masternode/MasternodeMonitoredAdd";
import MasternodeMonitoredRemove from "app.components/Masternode/MasternodeMonitoredRemove";
import Content, { ContentEmpty } from "app.components/Common/Content/Content";
import NodeStatus from "app.components/Common/NodeStatus";

class MasternodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicAddress: "",
      removeMonitoredMasternodeId: null
    };
  }
  componentDidMount() {
    this.props.fetchMasternodes();
    this.interval = setInterval(this.props.fetchMasternodes, 60000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  removeMonitoredMasternode(id) {
    this.setState({ removeMonitoredMasternodeId: id });
    this.props.openModal({ modalMasternodeMonitoredRemove: true });
  }
  render() {
    const {
      network,
      masternodes,
      ui,
      addMonitoredMasternode,
      searchMonitoredMasternode,
      removeMonitoredMasternode,
      resetMasternodeMonitoredAdd,
      openModal,
      dismissModal
    } = this.props;
    const {
      pending,
      hosted,
      monitored,
      summary,
      addMonitoredMasternodeError,
      searchMonitoredMasternodeError,
      searchMonitoredMasternodeResult,
      isAddMonitoredMasternodeLoading,
      isSearchMonitoredMasternodeLoading,
      isRemoveMonitoredMasternodeLoading
    } = masternodes;
    const { modalMasternodeMonitoredAdd, modalMasternodeMonitoredRemove } = ui;

    return (
      <Content loaderType="full" loading={!masternodes.isReady}>
        <div className="monitor">
          {pending.length ? (
            <div className="monitor__item">
              <div className="monitor__item__header">
                <div>
                  <h1>Pending Masternodes</h1>
                </div>
                <div className="monitor__item__header__commands" />
              </div>

              <MasternodePendingList list={pending} />
            </div>
          ) : null}

          <div className="monitor__item">
            <div className="monitor__item__header">
              <div className="hidden-sm">
                <h1>Zaimirai Hosted Nodes</h1>
              </div>
              <div className="monitor__item__header__summary hidden-lg">
                <div className="monitor__item__header__summary__item">
                  <NodeStatus type="simple" status={NETWORK_STATUS.ENABLED} />
                  <div className="monitor__item__header__summary__item__value">
                    {summary.numActive} Active
                  </div>
                </div>

                <div className="monitor__item__header__summary__item">
                  <NodeStatus type="simple" status={NETWORK_STATUS.PENDING} />
                  <div className="monitor__item__header__summary__item__value">
                    {summary.numPending} Inactive
                  </div>
                </div>
              </div>
              <div className="monitor__item__header__commands">
                <Link to="/launch/new" className="button button--primary">
                  Launch a Masternode
                </Link>
              </div>
            </div>
            <Content empty={hosted.length === 0}>
              <React.Fragment>
                <MasternodeSummary
                  list={hosted}
                  network={network}
                  summary={summary}
                  type="hosted"
                />
                <MasternodeHostedList list={hosted} summary={summary} />
                <div className="monitor__item__footer">
                  * This calculation is updated periodically and is to be used
                  to estimate approximate payment date and time.
                </div>
              </React.Fragment>
              <ContentEmpty>
                <div className="message--empty u-mt-large">
                  <div className="message__content">
                    <p>
                      It looks like you're not hosting any masternodes with
                      Zaimirai. <Link to="/launch/new">Launch one now</Link>
                    </p>
                  </div>
                </div>
              </ContentEmpty>
            </Content>
          </div>

          <div className="monitor__item">
            <div className="monitor__item__header">
              <div>
                <h1 className=" hidden-sm">Monitored Nodes</h1>
              </div>
              <div className="monitor__item__header__commands">
                <button
                  className="button button--primary"
                  onClick={e => {
                    e.preventDefault();
                    openModal({ modalMasternodeMonitoredAdd: true });
                  }}
                >
                  ADD MONITORED MASTERNODE
                </button>
              </div>
            </div>

            <Content empty={monitored.length === 0}>
              <MasternodeMonitoredList
                list={monitored}
                removeMonitoredMasternode={id => {
                  this.removeMonitoredMasternode(id);
                }}
              />
              <ContentEmpty>
                <div className="message--empty u-mt-large">
                  <div className="message__content">
                    <p>
                      If you have masternodes hosted elsewhere, you can still
                      monitor them with Zaimirai.
                    </p>
                  </div>
                </div>
              </ContentEmpty>
            </Content>
          </div>

          <Modal
            modalClass="monitored"
            isOpen={modalMasternodeMonitoredAdd}
            dismiss={() => {
              resetMasternodeMonitoredAdd();
              dismissModal({ modalMasternodeMonitoredAdd: false });
            }}
          >
            <MasternodeMonitoredAdd
              add={addMonitoredMasternode}
              search={searchMonitoredMasternode}
              isLoading={
                isSearchMonitoredMasternodeLoading ||
                isAddMonitoredMasternodeLoading
              }
              addError={addMonitoredMasternodeError}
              searchError={searchMonitoredMasternodeError}
              result={searchMonitoredMasternodeResult}
            />
          </Modal>

          <Modal
            modalClass="monitored"
            isOpen={modalMasternodeMonitoredRemove}
            dismiss={() => {
              dismissModal({ modalMasternodeMonitoredRemove: false });
            }}
          >
            <MasternodeMonitoredRemove
              isLoading={isRemoveMonitoredMasternodeLoading}
              remove={() =>
                removeMonitoredMasternode({
                  id: this.state.removeMonitoredMasternodeId
                })
              }
            />
          </Modal>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    masternodes: state.masternodes,
    network: state.network,
    ui: state.ui
  };
};

const mapDispatchToProps = {
  fetchMasternodes,
  searchMonitoredMasternode,
  addMonitoredMasternode,
  removeMonitoredMasternode,
  resetMasternodeMonitoredAdd,
  dismissModal,
  openModal
};

MasternodeContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    mapDispatchToProps // these actions are automatically wrapped in a dispatch by connect
  )(MasternodeContainer)
);

export default MasternodeContainer;
