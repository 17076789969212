import React, { Component } from "react";
import { connect } from "react-redux";
import { checkAuthenticationStatus } from "app.actions/authentication";
import { isLoaded } from "app.utils";

export function requireAuthentication(OriginalComponent) {
  class AuthenticatedComponent extends Component {
    componentDidMount() {
      this.props.checkAuthenticationStatus();
    }

    render() {
      const { authenticationStatus } = this.props;
      const isAuthenticated = isLoaded(authenticationStatus);

      return isAuthenticated ? <OriginalComponent {...this.props} /> : null;
    }
  }

  const mapStateToProps = state => ({
    authenticationStatus: state.authentication.authenticationStatus
  });

  const mapDispatchToProps = {
    checkAuthenticationStatus
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthenticatedComponent);
}
