import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DigitalMoney from "app.components/Util/DigitalMoney";
import { middleDots } from "app.utils";
import Tags from "app.components/Common/Tags/Tags";
import NodeStatus from "app.components/Common/NodeStatus";
import TimeAgo from "app.components/Common/TimeAgo";
import {
  NETWORK_STATUS,
  SCREEN_SIZE_MD_MAX,
  SCREEN_SIZE_MD_MIN
} from "app.constants";
import { useWindowSize } from "app.utils";

const MasternodeHostedList = ({ list, summary }) => {
  const [proTxLength, setProTxLength] = useState(24);
  const { isLarge, windowSize } = useWindowSize();
  useEffect(() => {
    const proTxLengthProportion = Math.floor(windowSize / 10);
    if (windowSize >= SCREEN_SIZE_MD_MAX) {
      setProTxLength(40);
    } else if (windowSize < SCREEN_SIZE_MD_MIN) {
      setProTxLength(proTxLengthProportion - 4);
    } else if (windowSize < SCREEN_SIZE_MD_MAX) {
      setProTxLength(proTxLengthProportion - 52);
    } else {
      setProTxLength(36);
    }
  }, [windowSize]);

  const mapByPayoutAddress = list.reduce((map, item) => {
    const payoutAddress = item.payoutAddress || "Pending";

    if (!map[payoutAddress]) {
      const itemSummary = summary.addresses[payoutAddress] || {};
      const rewards = itemSummary.rewards || {};
      map[payoutAddress] = {
        balance: rewards.available ? parseFloat(rewards.available) : 0,
        total: rewards.mined ? parseFloat(rewards.mined) : 0,
        payoutAddress: payoutAddress,
        items: []
      };
    }

    map[payoutAddress].items.push(item);

    return map;
  }, {});

  const listGroupedByPayoutAddress = Object.values(mapByPayoutAddress);

  return (
    <div className="masternode-card-container">
      {listGroupedByPayoutAddress.map((group, groupIndex) => (
        <div key={groupIndex} className="masternode-card">
          <div className="masternode-card__descriptor-container">
            <div className="masternode-card__descriptor masternode-card__descriptor--75">
              <div className="masternode-card__descriptor__title">
                Payout Address
              </div>
              <div className="mono">{middleDots(group.payoutAddress, 44)}</div>
            </div>
            <div className="masternode-card__descriptor masternode-card__descriptor--25">
              <span className="masternode-card__descriptor__title">
                Available
              </span>
              <DigitalMoney coins={group.balance.toFixed(2)} unit="Dash" />
            </div>
          </div>
          <div className="masternode-card__item-container">
            {isLarge ? (
              <table className="masternode-list--hosted">
                <thead className="no-border">
                  <tr>
                    <th className="text--left" style={{ width: "400px" }}>
                      Protx Hash
                    </th>

                    <th style={{ width: "184px" }}>Status</th>
                    <th style={{ width: "160px" }} className="text--right">
                      Expiration Date
                    </th>
                    <th style={{ width: "160px" }} className="text--right">
                      Last Reward *
                    </th>
                    <th className="text--right" style={{ width: "240px" }}>
                      Tags
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {group.items.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <TdLink
                        to={`/masternodes/${item.commissionId}`}
                        className="justify--left masternode-list--hosted__link mono"
                      >
                        {middleDots(item.proTxHash, proTxLength)}
                      </TdLink>

                      <TdLink
                        to={`/masternodes/${item.commissionId}`}
                        className="justify--center"
                      >
                        {item.networkStatus === NETWORK_STATUS.ENABLED &&
                        item.operatorPublicKey !==
                          item.operatorPublicKeyNode40 ? (
                          <NodeStatus status={NETWORK_STATUS.WRONG_OPERATOR} />
                        ) : (
                          <NodeStatus status={item.networkStatus} />
                        )}
                      </TdLink>
                      <TdLink
                        to={`/masternodes/${item.commissionId}`}
                        className="justify--right"
                      >
                        {(item.expirationDate &&
                          moment(item.expirationDate).fromNow()) ||
                          "-"}
                      </TdLink>
                      <TdLink
                        to={`/masternodes/${item.commissionId}`}
                        className="justify--right"
                      >
                        {(item.lastPaid && <TimeAgo value={item.lastPaid} />) ||
                          "-"}
                      </TdLink>
                      <TdLink
                        to={`/masternodes/${item.commissionId}`}
                        className="justify--right masternode-list--hosted__tags"
                      >
                        <Tags tags={item.tags} maxLength={2} />
                      </TdLink>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              group.items.map((item, itemIndex) => (
                <Link to={`/masternodes/${item.commissionId}`}>
                  <div className="masternode-card__item" key={itemIndex}>
                    <div className="masternode-card__item__info masternode-card__item__info--grow">
                      <span className="masternode-card__item__info__title">
                        Protx Hash
                      </span>
                      <span className="masternode-card__item__info__value mono">
                        {middleDots(item.proTxHash, proTxLength)}
                      </span>
                    </div>
                    <div className="masternode-card__item__info masternode-card__item__info--status">
                      <span className="masternode-card__item__info__title hidden-sm">
                        Status
                      </span>
                      <div className="flex flex--row">
                        {item.networkStatus === NETWORK_STATUS.ENABLED &&
                        item.operatorPublicKey !==
                          item.operatorPublicKeyNode40 ? (
                          <NodeStatus status={NETWORK_STATUS.WRONG_OPERATOR} />
                        ) : (
                          <NodeStatus status={item.networkStatus} />
                        )}
                      </div>
                    </div>
                    <div className="break-column visible-sm" />
                    <div className="masternode-card__item__info">
                      <span className="masternode-card__item__info__title">
                        Expiration
                      </span>
                      <span className="masternode-card__item__info__value">
                        {(item.expirationDate &&
                          moment(item.expirationDate).fromNow()) ||
                          "-"}
                      </span>
                    </div>
                    <div className="masternode-card__item__info">
                      <span className="masternode-card__item__info__title">
                        Last Reward *
                      </span>
                      <span className="masternode-card__item__info__value">
                        {(item.lastPaid && <TimeAgo value={item.lastPaid} />) ||
                          "-"}
                      </span>
                    </div>
                    <div className="masternode-card__item__info masternode-card__item__info--tags">
                      <div className="flex flex--row">
                        <Tags
                          tabValueMaxLength={9}
                          tags={item.tags}
                          maxLength={2}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const TdLink = ({ to, children, className }) => (
  <td>
    <Link className={className} to={to}>
      {children}
    </Link>
  </td>
);

export default MasternodeHostedList;
