import { all } from "redux-saga/effects";
import userSagas from "app.sagas/user";
import authSagas from "app.sagas/authentication";
import masternodesSagas from "app.sagas/masternodes";
import governanceSagas from "app.sagas/governance";
import launchSagas from "app.sagas/launch";
import networkSagas from "app.sagas/network";
import invoiceSagas from "app.sagas/invoice";

export default function* rootSaga() {
  try {
    yield all([
      ...userSagas,
      ...authSagas,
      ...masternodesSagas,
      ...governanceSagas,
      ...launchSagas,
      ...networkSagas,
      ...invoiceSagas
    ]);
  } catch (e) {}
}
