import React, { useState, useContext, useEffect } from "react";
import {
  StepperHeading,
  StepperControls
} from "app.components/Common/Stepper/Stepper";
import LaunchContext from "app.components/Launch/LaunchContext";
import Select, {
  SelectIndicator,
  SelectItem
} from "app.components/Common/Select/Select";
import Content from "app.components/Common/Content/Content";
import Message from "app.components/Common/Message/Message";
import AppContext from "app.components/AppContext";

const LaunchStepCollateralLookup = () => {
  const { launch, searchCollateral } = useContext(LaunchContext);
  const { user } = useContext(AppContext);

  const { config, collateralData, isSearchCollateralLoading } = launch;
  const collateralList = collateralData.list;
  const isAddressValid = collateralData.isValid;
  const isAddressInvalid = collateralData.isValid === false;
  const [address, setAddress] = useState(config.address);
  const [collateral, setCollateral] = useCollateral(
    config.collateral,
    collateralList
  );
  const isThirdParty = config.masternodeType === "thirdParty";

  const reuseAddress = () => {
    const list = collateralList || [];
    const isCollateralInTheList = list.find(
      item => item.collateral === collateral
    );
    if (address && collateral && !isCollateralInTheList) {
      searchCollateral({ address, isThirdParty });
    }
  };
  useEffect(reuseAddress, []);

  let heading = "Enter the address that contains your 1,000 Dash ";

  if (config.masternodeType === "standard") {
    heading += "and Zaimirai will locate your collateral id and index.";
  } else {
    heading += "or more.";
  }

  const collaterals = collateralList || [];
  const isCollateralListEmpty = collateralList && !collateralList.length;
  return (
    <React.Fragment>
      <StepperHeading heading="Collateral Lookup" subHeading={heading} />
      <div className="flex flex--row-wrap">
        <div className="flex flex--grow u-mr-default">
          <input
            type="text"
            className="form-control"
            placeholder="Search for valid collateral"
            value={address}
            disabled={isSearchCollateralLoading}
            onChange={e => {
              setAddress(e.target.value);
            }}
          />
        </div>
        <div className="visible-sm flex flex--fill">&nbsp;</div>
        <div className="flex flex--grow--lg flex--fill--sm">
          <button
            className={
              isSearchCollateralLoading
                ? "button button--primary button--loading"
                : "button button--primary"
            }
            disabled={isSearchCollateralLoading || address.length < 34}
            onClick={() => {
              setCollateral("");
              searchCollateral({ address, isThirdParty });
            }}
          >
            Locate Collateral
          </button>
        </div>
      </div>
      <div className="table__detail" style={{ marginTop: "32px" }}>
        <Content loading={isSearchCollateralLoading}>
          {(!isThirdParty && (
            <React.Fragment>
              {(isCollateralListEmpty && (
                <Message>
                  Could not find a valid collateral. Check to make sure you are
                  using the correct address.
                </Message>
              )) ||
                (collaterals.length && (
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "64px" }} />
                        <th className="text--left">Transaction Id</th>
                        <th className="text--center">Transaction Index</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Select
                        value={collateral}
                        onChange={(e, value) => setCollateral(value)}
                        templateType="table"
                      >
                        {collaterals.map((item, i) => (
                          <tr key={i} className="bordered">
                            <td className="text--center">
                              <SelectItem
                                value={item.collateral}
                                customTemplate={
                                  <SelectIndicator
                                    selected={item.collateral === collateral}
                                  />
                                }
                              />
                            </td>
                            <td className="text--left word-break">
                              {item.collateralTx}
                            </td>
                            <td className="text--center">
                              {item.collateralIndex}
                            </td>
                          </tr>
                        ))}
                      </Select>
                    </tbody>
                  </table>
                )) ||
                null}
            </React.Fragment>
          )) || (
            <React.Fragment>
              {(isAddressInvalid && (
                <Message>
                  Could not find a valid collateral. Check to make sure you are
                  using the correct address.
                </Message>
              )) ||
                (isAddressValid && (
                  <div class="collateral-valid">
                    <span className="glyphicons glyphicons-ok-2" />
                    The address is valid
                  </div>
                )) ||
                null}
            </React.Fragment>
          )}
        </Content>
      </div>
      <StepperControls
        disabled={isSearchCollateralLoading}
        nextDisabled={!(collateral || isAddressValid)}
        onSubmitParams={{ collateral, address, userId: user.uid, lastStep: 2 }}
      />
    </React.Fragment>
  );
};

const useCollateral = (selectedCollateral, collateralList) => {
  const [collateral, setCollateral] = useState(selectedCollateral);
  const list = collateralList || [];

  const autoFillCollateral = () => {
    if (!collateral && list.length === 1) {
      setCollateral(list[0].collateral);
    }
  };
  useEffect(autoFillCollateral, [list]);

  return [collateral, setCollateral];
};

export default LaunchStepCollateralLookup;
