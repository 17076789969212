import API from "app.api/util/apis";
import instance from "app.api/Hosting/Client";
class HostingAPI extends API {
  constructor() {
    super(instance);
  }

  /**
   * Submits a request to get a user's token
   * @async
   * @function
   * @param {string} ticket - SSO ticket
   * @returns {(APIs.HTTPSuccessResponse|APIs.HTTPErrorResponse)} The Response or Error message
   */
  getTokenFromTicket = (ticket, profileId) => {
    if(ticket && ticket.length > 0) {
      return this.get("/checkpoint", {
        authenticated: false,
        params: {
          ticket,
          profileId
        }
      });
    } else {
      console.log("getTokenFromTicket HostingAPI ticket empty")
    }

  };

  requestUserCredits = async userId => {
    const { body = {} } = await this.get(`/profile/credit/${userId}`, {
      authenticated: true
    });
    return body.credit ? parseFloat(body.credit) : 0;
  };

  requestMasternodeCount = async () => {
    const { body = {} } = await this.get("/masterNode/count", {
      authenticated: true
    });

    return body.count;
  };

  requestBitcoinPriceInUSD = async () => {
    const { body = {} } = await this.get("/conversion/btc/USD", {
      authenticated: true
    });
    return body["24h_avg"];
  };

  requestDashPriceInBTC = async () => {
    const { body = {} } = await this.get("/conversion/dash", {
      authenticated: true
    });

    return body.lasttradeprice;
  };

  requestDashPriceInUSD = async () => {
    const { body = {} } = await this.get("/conversion/dashusd", {
      authenticated: true
    });

    return body.recentAverage;
  };

  requestMonitoredNodes = async () => {
    const { body = [] } = await this.get("/masterNode/myNodes", {
      authenticated: true
    });

    return body.error ? [] : body;
  };

  requestCommissionedNodes = async () => {
    const { body = {} } = await this.get("/commissioner/mine", {
      authenticated: true
    });

    return body.nodes || [];
  };

  requestMasternodeTransactions = async payoutAddress => {
    const { body = {} } = await this.get(
      "/chain/transactions/" + payoutAddress,
      {
        authenticated: true
      }
    );

    return body;
  };

  requestVotedProposals = async () => {
    const { body = [] } = await this.get("/budget/currentVotingRecord", {
      authenticated: true
    });

    return body;
  };

  searchMonitoredMasternode = async publicAddress => {
    const { body = [] } = await this.get(
      "/masterNode/findByPublicKey/" + publicAddress,
      {
        authenticated: true
      }
    );

    return body.error ? [] : body;
  };

  addMonitoredMasternode = async data => {
    const { body = {} } = await this.post("/masterNode/follow", data, {
      authenticated: true
    });

    return body;
  };

  removeMonitoredMasternode = async id => {
    const { body = {} } = await this.delete("/masterNode/unfollow/" + id, {
      authenticated: true
    });

    return body;
  };

  requestPortfolioSummary = async () => {
    const { body = {} } = await this.get("/masterNode/portfolioSummary", {
      authenticated: true
    });

    return body.summary || {};
  };

  requestGovernanceList = async () => {
    const { body = {} } = await this.get("/budget/list", {
      authenticated: true
    });

    return body.budgets || [];
  };

  broadcastVote = async (governanceId, votes) => {
    const { body = {} } = await this.post(
      "/budget/vote/" + governanceId,
      { votes },
      {
        authenticated: true
      }
    );

    return body;
  };

  searchCollateral = async address => {
    const { body = [] } = await this.get(
      "/commissioner/findCollateral/" + address,
      {
        authenticated: true
      }
    );

    return body;
  };

  launchMasternode = async data => {
    const { body = {} } = await this.post(
      "/commissioner/commissionServer",
      data,
      {
        authenticated: true
      }
    );

    return body;
  };

  validateFundAddress = async publickey => {
    const { body = {} } = await this.get(
      "/commissioner/validateFundAddress/" + publickey,
      {
        authenticated: true
      }
    );

    return body;
  };

  requestLaunchProgress = async id => {
    const { body = {} } = await this.get("/commissioner/progress/" + id, {
      authenticated: true
    });

    return body;
  };

  requestLatestInvoiceForMasternode = async id => {
    const { body = {} } = await this.get("/billing/latestInvoice/" + id, {
      authenticated: true
    });

    return body.invoice || {};
  };

  requestInvoices = async userId => {
    const { body = {} } = await this.get("/billing/invoices/" + userId, {
      authenticated: true
    });

    return body.invoices || [];
  };

  submitStripePayment = async data => {
    const { body = {} } = await this.post(
      "/billing/submitCreditCardPayment",
      data,
      {
        authenticated: true
      }
    );

    return body;
  };

  requestPaymentOptions = async () => {
    const { body = {} } = await this.get("/billing/paymentOptions", {
      authenticated: true
    });

    return body.options || [];
  };

  requestTags = async () => {
    const { body = {} } = await this.get("/masterNode/myTags", {
      authenticated: true
    });

    return body;
  };

  createTag = async data => {
    const { body = {} } = await this.post("/masterNode/createTag", data, {
      authenticated: true
    });

    return body;
  };

  addTagToMasternode = async data => {
    const { body = {} } = await this.post("/masterNode/tag", data, {
      authenticated: true
    });

    return body;
  };

  removeTagFromMasternode = async data => {
    const { body = {} } = await this.post("/masterNode/untag", data, {
      authenticated: true
    });

    return body;
  };

  getUserPaymentSchedule = async ()=> {

    const { body = {} } = await this.post("/3a8fbbba-46ac-43d1-996f-ad3f6f5c35d4", {}, {
      authenticated: true
    });

    return body;
  };

  getPublicKey = async ()=> {

    const { body = {} } = await this.post("/62236b61-069e-44df-b4c9-def74cb032c3", {}, {
      authenticated: true
    });

    return body;
  };

  getProfile = async ()=> {

    const { body = {} } = await this.post("/ccb3e9e0-5191-4b7d-8a67-1d80f2fb4d20", {email:'loc@webapper.net'}, {
      authenticated: true
    });

    return body;
  };

  updateProfile = async (params)=> {

    const { body = {} } = await this.put("/60a1e68e-3c67-4218-9dca-5cfa4c3df06c", params, {
      authenticated: true
    });

    return body;
  };

  updatePassword = async (params)=> {

    const { body = {} } = await this.put("/67d3d02a-c2fd-4859-938a-56b43ba9e003" + `?password=${params.password}&password1=${params.password1}&password2=${params.password2}`, {}, {
      authenticated: true
    });

    return body;
  };
}

export default new HostingAPI();
