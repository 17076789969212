import { call, put, takeEvery, select } from "redux-saga/effects";
import * as uiActions from "app.actions/ui";
import * as masternodesActions from "app.actions/masternodes";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { handleError } from "app.sagas/error";
import {
  NETWORK_STATUS,
  DASH_DECIMALS,
  DASH_COLLATERAL,
  COMMISSION_STATUS
} from "app.constants";

const masternodesSagas = [
  takeEvery(masternodesActions.FETCH_MASTERNODES_BEGIN, fetchMasternodes),
  takeEvery(
    masternodesActions.SEARCH_MONITORED_MASTERNODE_BEGIN,
    searchMonitoredMasternode
  ),
  takeEvery(
    masternodesActions.ADD_MONITORED_MASTERNODE_BEGIN,
    addMonitoredMasternode
  ),
  takeEvery(
    masternodesActions.REMOVE_MONITORED_MASTERNODE_BEGIN,
    removeMonitoredMasternode
  ),
  takeEvery(
    masternodesActions.FETCH_MASTERNODE_TRANSACTIONS_BEGIN,
    fetchMasternodeTransactions
  ),
  takeEvery(
    masternodesActions.FETCH_MASTERNODE_INVOICES_BEGIN,
    fetchMasternodeInvoices
  ),
  takeEvery(masternodesActions.FETCH_TAGS_BEGIN, fetchTags),
  takeEvery(masternodesActions.ADD_TAG_BEGIN, addTag),
  takeEvery(masternodesActions.REMOVE_TAG_BEGIN, removeTag)
];

export default masternodesSagas;

export function* fetchMasternodes() {
  try {
    const selector = state => state.masternodes.isLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.fetchMasternodesRequest(nextStatus));

    const { numActiveNodes, blockTime } = yield select(state => state.network);
    let monitoredNodes = yield call(HostingAPI.requestMonitoredNodes);
    let commissionedNodes = yield call(HostingAPI.requestCommissionedNodes);

    const positiveCommissionStatus = [
      COMMISSION_STATUS.COMPLETE,
      COMMISSION_STATUS.RENEWAL,
      COMMISSION_STATUS.LAUNCHING,
      COMMISSION_STATUS.AWAITING_BLOCKS
    ];

    const positiveNetworkStatus = [
      NETWORK_STATUS.ENABLED,
      NETWORK_STATUS.POSE_BANNED,
      NETWORK_STATUS.MIGRATING
    ];

    let unfinishedNodes = [];

    const commissionMap = commissionedNodes.reduce((map, node) => {
      const { status, proTxInfo = {}, collateral, index } = node;

      if (
        positiveCommissionStatus.includes(status) &&
        proTxInfo &&
        proTxInfo.proTxHash
      ) {
        map[collateral + "_" + index] = node;
      } else {
        unfinishedNodes.push(node);
      }
      return map;
    }, {});

    const pending = yield call(fetchUnfinishedNodes, unfinishedNodes);

    let numActive = 0;
    let numPending = 0;
    const nodes = monitoredNodes.reduce(
      (groups, monitored) => {
        monitored.tags = monitored.tags || [];

        const balance = monitored.balance / DASH_DECIMALS;
        const networkStatus = positiveNetworkStatus.includes(monitored.status)
          ? NETWORK_STATUS.ENABLED
          : monitored.status;

        let node = {
          ...getMasternodeInitialState(),
          monitorId: monitored.id,
          networkStatus,
          payoutAddress: monitored.payeePublicKey,
          collateral: monitored.vin + "_" + monitored.idx,
          collateralTx: monitored.vin,
          collateralIndex: monitored.idx,
          collateralAddress: monitored.publicKey,
          lastPaid: monitored.lastPaid.block_nTime,
          balance: balance % DASH_COLLATERAL,
          ip: monitored.address.ip,
          port: monitored.address.port,
          tags: monitored.tags || []
        };

        const commissionedNode = commissionMap[node.collateral];

        if (commissionedNode && !commissionedNode.used) {
          commissionedNode.used = true;
          node = {
            ...node,
            node40: true,
            commissionId: commissionedNode.id,
            commissionStatus: commissionedNode.status,
            creationDate: commissionedNode.created,
            expirationDate: commissionedNode.expirationDate,
            votingAddressNode40: commissionedNode.votingPublicKey,
            votingAddress: commissionedNode.proTxInfo.votingAddress,
            proTxHash: commissionedNode.proTxInfo.proTxHash,
            operatorReward: commissionedNode.proTxInfo.operatorReward,
            ownerAddress: commissionedNode.proTxInfo.ownerAddress,
            operatorPublicKeyNode40: commissionedNode.blsPublicKey,
            operatorPublicKey: commissionedNode.proTxInfo.pubKeyOperator,
            payoutAddress: commissionedNode.proTxInfo.payoutAddress,
            isThirdParty: commissionedNode.isThirdParty
          };

          if (node.networkStatus === NETWORK_STATUS.ENABLED) {
            numActive++;

            let lastPaid = node.lastPaid || 0;
            lastPaid = lastPaid > 0 ? lastPaid : 0;
            node.nextPayment = lastPaid + blockTime * numActiveNodes;
            groups.hosted.push(node);
          } else {
            node.pendingAction = "REGISTRATION DETAILS";
            groups.pending.push(node);
            numPending++;
          }


        } else {
          node = {
            ...node,
            node40: false
          };
          groups.monitored.push(node);
        }

        return groups;
      },
      { hosted: [], monitored: [], pending }
    );

    const summaryData = yield call(HostingAPI.requestPortfolioSummary);
    const summary = {
      addresses: summaryData.addressLookup || {},
      numActive,
      numPending
    };

    yield put(
      masternodesActions.fetchMasternodesReceive({ ...nodes, summary })
    );
  } catch (err) {
    yield put(masternodesActions.fetchMasternodesError());
    yield put(handleError({ err }));
  }
}

async function fetchUnfinishedNodes(unfinishedNodes) {
  let nodes = [];

  for (let unfinishedNode of unfinishedNodes) {
    const progress = await HostingAPI.requestLaunchProgress(unfinishedNode.id);
    const node = {
      commissionId: unfinishedNode.id,
      publicAddress: unfinishedNode.publicKey,
      creationDate: unfinishedNode.created
    };

    if (unfinishedNode.collateral) {
      node.collateral = unfinishedNode.collateral + "-" + unfinishedNode.index;
    }

    if (!progress.paid) {
      node.status = "WAITING FOR PAYMENT";
      node.pendingAction = "COMPLETE PAYMENT";
    } else {
      node.status = "WAITING FOR PROTX";
      node.pendingAction = "REGISTRATION DETAILS";
    }

    nodes.push(node)
  }

  return nodes;
}

export function* searchMonitoredMasternode(action) {
  try {
    const selector = state =>
      state.masternodes.isSearchMonitoredMasternodeLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.searchMonitoredMasternodeRequest(nextStatus));

    const { publicAddress } = action.data;
    const searchMonitoredMasternodeResult = yield call(
      HostingAPI.searchMonitoredMasternode,
      publicAddress
    );

    if (!searchMonitoredMasternodeResult.length) {
      yield put(
        masternodesActions.searchMonitoredMasternodeReceive({
          searchMonitoredMasternodeError: "notFound"
        })
      );
      return;
    }

    const masternodes = searchMonitoredMasternodeResult.map(mn => ({
      key: mn.publicKey,
      vin: mn.vin + "-" + mn.idx
    }));

    if (masternodes.length === 1) {
      yield put(masternodesActions.addMonitoredMasternode(masternodes[0]));
      yield put(
        masternodesActions.searchMonitoredMasternodeReceive({
          searchMonitoredMasternodeError: null,
          searchMonitoredMasternodeResult: []
        })
      );
      return;
    }

    yield put(
      masternodesActions.searchMonitoredMasternodeReceive({
        searchMonitoredMasternodeError: null,
        searchMonitoredMasternodeResult: masternodes
      })
    );
  } catch (err) {
    yield put(masternodesActions.searchMonitoredMasternodeError());
    yield put(handleError({ err }));
  }
}

export function* addMonitoredMasternode(action) {
  try {
    const selector = state => state.masternodes.isAddMonitoredMasternodeLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.addMonitoredMasternodeRequest(nextStatus));

    const { vin, key } = action.data;
    const [tx, idx] = vin.split("-");

    const addMonitoredMasternodeResult = yield call(
      HostingAPI.addMonitoredMasternode,
      {
        key,
        idx,
        vin: tx
      }
    );

    if (addMonitoredMasternodeResult.error) {
      yield put(
        masternodesActions.addMonitoredMasternodeReceive({
          addMonitoredMasternodeError: addMonitoredMasternodeResult.error,
          searchMonitoredMasternodeResult: []
        })
      );
      return;
    }

    yield put(uiActions.dismissModal({ modalMasternodeMonitoredAdd: false }));
    yield put(
      masternodesActions.addMonitoredMasternodeReceive({
        searchMonitoredMasternodeError: null,
        addMonitoredMasternodeError: null,
        searchMonitoredMasternodeResult: []
      })
    );
    yield put(masternodesActions.fetchMasternodes());
  } catch (err) {
    yield put(masternodesActions.addMonitoredMasternodeError());
    yield put(handleError({ err }));
  }
}

export function* removeMonitoredMasternode(action) {
  try {
    const selector = state =>
      state.masternodes.isDeleteMonitoredMasternodeLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.removeMonitoredMasternodeRequest(nextStatus));

    const { id } = action.data;
    yield call(HostingAPI.removeMonitoredMasternode, id);

    yield put(masternodesActions.removeMonitoredMasternodeReceive({ id }));
    yield put(
      uiActions.dismissModal({ modalMasternodeMonitoredRemove: false })
    );
  } catch (err) {
    yield put(masternodesActions.removeMonitoredMasternodeError());
    yield put(handleError({ err }));
  }
}

export function* fetchMasternodeTransactions(action) {
  try {
    const selector = state => state.masternodes.isMasternodeTransactionsLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(
      masternodesActions.fetchMasternodeTransactionsRequest(nextStatus)
    );

    let transactions = yield call(
      HostingAPI.requestMasternodeTransactions,
      action.data.payoutAddress
    );

    transactions.in = transactions.in.map(item => ({ ...item, type: "in" }));
    transactions.out = transactions.out.map(item => ({ ...item, type: "out" }));

    transactions = transactions.in.concat(transactions.out);
    transactions = transactions.sort((a, b) => a.block_nTime - b.block_nTime);

    let balance = 0;

    transactions = transactions.reduce((map, item) => {
      item.value /= DASH_DECIMALS;
      const transactionHash = item.tx_hash;
      const amount = item.type === "in" ? item.value : -item.value;

      balance += amount;
      if (!map[transactionHash]) {
        map[transactionHash] = {
          transactionHash,
          block: item.block_height,
          date: item.block_nTime * 1000,
          amount: 0
        };
      }

      map[transactionHash].balance = balance;
      map[transactionHash].amount += amount;

      return map;
    }, {});

    transactions = Object.values(transactions).sort((a, b) => b.date - a.date);
    yield put(
      masternodesActions.fetchMasternodeTransactionsReceive({
        transactions
      })
    );
  } catch (err) {
    yield put(masternodesActions.fetchMasternodeTransactionsError());
    yield put(handleError({ err }));
  }
}

export function* fetchMasternodeInvoices(action) {
  try {
    const selector = state => state.masternodes.isMasternodeInvoicesLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.fetchMasternodeInvoicesRequest());

    // TODO: Request all invoices from this masternode on the backend
    let invoice = yield call(
      HostingAPI.requestLatestInvoiceForMasternode,
      action.data.id
    );

    if (!invoice.amount) {
      yield put(
        masternodesActions.fetchMasternodeInvoicesReceive({ invoices: [] })
      );
      return;
    }

    invoice.amount = invoice.totalAmount / DASH_DECIMALS;
    yield put(
      masternodesActions.fetchMasternodeInvoicesReceive({ invoices: [invoice] })
    );
  } catch (err) {
    yield put(masternodesActions.fetchMasternodeInvoicesError());
    yield put(handleError({ err }));
  }
}

export function* fetchTags() {
  try {
    const selector = state => state.masternodes.isFetchTagsLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(masternodesActions.fetchTagsRequest());

    const { tags = [] } = yield call(HostingAPI.requestTags);

    yield put(masternodesActions.fetchTagsReceive({ tags }));
  } catch (err) {
    yield put(masternodesActions.fetchTagsError());
    yield put(handleError({ err }));
  }
}

export function* addTag(action) {
  try {
    let { tag, masternodeId } = action.data;
    let { hosted, tags } = yield select(state => state.masternodes);

    const tagExists = tags.find(item => item.tag === tag);

    if (!tagExists) {
      const createTagResult = yield call(HostingAPI.createTag, { tag });
      tag = { tag, id: createTagResult.id };
      tags.push(tag);
    } else {
      tag = tagExists;
    }

    let masternode = hosted.find(item => item.monitorId === masternodeId);

    if (masternode.tags.find(item => item.id === tag.id)) {
      return;
    }

    masternode.tags.push(tag);

    const data = {
      hosted,
      tags
    };

    yield put(masternodesActions.addTagRequest(data));

    yield call(HostingAPI.addTagToMasternode, {
      nodes: [masternodeId],
      tag: tag.id
    });

    yield put(masternodesActions.addTagReceive(data));
  } catch (err) {
    let { hosted } = yield select(state => state.masternodes);
    yield put(masternodesActions.addTagError({ hosted }));
    yield put(handleError({ err }));
  }
}

export function* removeTag(action) {
  try {
    let { tagId, masternodeId } = action.data;
    let hosted = yield select(state => state.masternodes.hosted);

    let masternode = hosted.find(item => item.monitorId === masternodeId);

    masternode.tags = masternode.tags.filter(item => item.id !== tagId);

    const data = {
      hosted
    };

    yield put(masternodesActions.removeTagRequest(data));

    yield call(HostingAPI.removeTagFromMasternode, {
      node: masternodeId,
      tag: tagId
    });
  } catch (err) {
    let hosted = yield select(state => state.masternodes.hosted);
    yield put(masternodesActions.removeTagError({ hosted }));
    yield put(handleError({ err }));
  }
}

function getMasternodeInitialState() {
  return {
    commissionId: null,
    monitorId: null,
    node40: null,
    collateralTx: null,
    collateralIndex: null,
    payoutAddress: null,
    networkStatus: null,
    commissionStatus: null,
    ip: null,
    port: null,
    tags: null,
    lastPaid: null,
    balance: null,
    proTxHash: null,
    operatorReward: null,
    ownerAddress: null,
    votingAddress: null,
    operatorPublicKey: null,
    votingAddressNode40: null,
    isThirdParty: null
  };
}
