import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import Modal from "app.components/Modal/Modal";
import { fetchNetwork } from "app.actions/network";
import { fetchUserCredits } from "app.actions/user";
import {
  receiveNetworkError,
  receiveGlobalError,
  removeFlashMessage,
  dismissErrorModal
} from "app.actions/ui";
import Header from "app.components/Header/Header";
import Toolbar from "app.components/Toolbar/Toolbar";
import Footer from "app.components/Footer/Footer";
import AppContext from "app.components/AppContext";

import { logout } from "app.actions/authentication";

import MasternodeContainer from "./Masternode/MasternodeContainer";
import GovernanceContainer from "./Governance/GovernanceContainer";
import LaunchContainer from "./Launch/LaunchContainer";
import MasternodeDetailContainer from "./Masternode/MasternodeDetailContainer";
import AccountContainer from "./Account/AccountContainer";
import InvoiceContainer from "./Invoice/InvoiceContainer";
import Content from "./Common/Content/Content";
import ModalError from "./Common/ModalError/ModalError";

class AppContainer extends Component {
  componentDidMount() {
    window.addEventListener("offline", this.handleNetworkChange);
    window.addEventListener("online", this.handleNetworkChange);

    this.setAnalyticsId(this.props);
    this.props.fetchNetwork();
    this.props.fetchUserCredits({ userId: this.props.user.uid });

    this.interval = setInterval(this.props.fetchNetwork, 60000);
  }

  setAnalyticsId(props) {
    if (process.env.NODE40_ENV !== "production") return;
    if (typeof props.user === "undefined") return;
    if (typeof props.user.sub === "undefined") return;

    ReactGA.set({ userId: props.user.sub }); // set ID for google analytics
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.handleNetworkChange);
    window.removeEventListener("online", this.handleNetworkChange);
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setAnalyticsId(this.props);
    }
  }

  handleNetworkChange = e => {
    if (e.type === "offline") {
      this.props.receiveNetworkError({
        message: "Network connection offline"
      });
    } else {
      this.props.receiveNetworkError();
    }
  };

  clearNetError = () => {
    this.props.receiveNetworkError();
  };

  dismissError = () => {
    this.props.receiveGlobalError(); // send in no error clears error
  };

  render() {
    const { user, network, credits, ui, dismissErrorModal } = this.props;
    const appContextValue = { user, network, credits };

    return (
      <AppContext.Provider value={appContextValue}>
        <Header logout={this.props.logout} user={user} />
        <Toolbar network={network} />
        <main className="content">
          <Content loading={!network.isReady}>
            <Switch>
              <Route exact path="/" component={MasternodeContainer} />
              <Route path="/governance" component={GovernanceContainer} />
              <Route path="/launch/:id?" component={LaunchContainer} />
              <Route
                path="/masternodes/:commissionId?"
                component={MasternodeDetailContainer}
              />
              <Route
                path="/account"
                component={AccountContainer}
              />
              <Route
                path="/invoices/:invoiceId?"
                component={InvoiceContainer}
              />
              <Redirect from="*" to="/" />
            </Switch>
          </Content>
          <Modal
            modalClass="error"
            isOpen={ui.modalError}
            dismiss={() => {
              dismissErrorModal({ modalGovernanceBroadcastVote: false });
            }}
          >
            <ModalError error={ui.error} />
          </Modal>
        </main>
        <Footer />
      </AppContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    networkError: state.ui.networkError,
    user: state.user.user,
    credits: state.user.credits,
    network: state.network,
    ui: state.ui,
    globalError: state.ui.globalError,
    flashMessages: state.ui.flashMessages
  };
};

const mapDispatchToProps = {
  receiveNetworkError,
  receiveGlobalError,
  dismissErrorModal,
  logout,
  removeFlashMessage,
  fetchNetwork,
  fetchUserCredits
};

AppContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    mapDispatchToProps // these actions are automatically wrapped in a dispatch by connect
  )(AppContainer)
);

export default AppContainer;
