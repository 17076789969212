import React, { useState } from "react";
import { middleDots } from "app.utils";
import DeleteIcon from "app.components/Common/Icons/DeleteIcon";

const Tags = ({
  tags,
  emptyMessage,
  maxLength,
  onDelete,
  tabValueMaxLength
}) => {
  const [selectedTagId, setSelectedTagId] = useState(null);
  const length = tags.length;

  if (maxLength) {
    tags = tags.slice(0, maxLength);
  }

  if (!length) {
    return emptyMessage || "No tags";
  }

  tabValueMaxLength =
    length === 1 && tabValueMaxLength
      ? tabValueMaxLength * maxLength
      : tabValueMaxLength;

  return (
    <React.Fragment>
      {tags.map((tag, index) =>
        onDelete ? (
          <div
            role="button"
            key={index}
            tabIndex={0}
            className={selectedTagId === tag.id ? "tag tag--deletable" : "tag"}
            onClick={() =>
              setSelectedTagId(selectedTagId === tag.id ? null : tag.id)
            }
            onKeyDown={e => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                setSelectedTagId(selectedTagId === tag.id ? null : tag.id);
              }
            }}
          >
            <TagValue>{tag.tag}</TagValue>
            <div
              role="button"
              tabIndex={0}
              onClick={e => {
                onDelete(tag.id);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  onDelete(tag.id);
                }
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        ) : (
          <div key={index} className="tag">
            <TagValue maxLength={tabValueMaxLength}>{tag.tag}</TagValue>
          </div>
        )
      )}
      {maxLength && length > maxLength ? (
        <div className="tag">+{length - maxLength}</div>
      ) : null}
    </React.Fragment>
  );
};

const TagValue = ({ children, maxLength = 16 }) =>
  middleDots(children, maxLength);

export default Tags;
