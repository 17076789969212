import { makeDefaultActionCreator as action } from "app.actions/helpers";

export const FETCH_GOVERNANCE_BEGIN = "FETCH_GOVERNANCE_BEGIN";
export const FETCH_GOVERNANCE_REQUEST = "FETCH_GOVERNANCE_REQUEST";
export const FETCH_GOVERNANCE_RECEIVE = "FETCH_GOVERNANCE_RECEIVE";
export const FETCH_GOVERNANCE_ERROR = "FETCH_GOVERNANCE_ERROR";

export const UPDATE_GOVERNANCE_BEGIN = "UPDATE_GOVERNANCE_BEGIN";
export const UPDATE_GOVERNANCE_REQUEST = "UPDATE_GOVERNANCE_REQUEST";
export const UPDATE_GOVERNANCE_RECEIVE = "UPDATE_GOVERNANCE_RECEIVE";
export const UPDATE_GOVERNANCE_ERROR = "UPDATE_GOVERNANCE_ERROR";

export const VOTE_BEGIN = "VOTE_BEGIN";
export const VOTE_REQUEST = "VOTE_REQUEST";
export const VOTE_RECEIVE = "VOTE_RECEIVE";
export const VOTE_ERROR = "VOTE_ERROR";

export const fetchGovernance = action(FETCH_GOVERNANCE_BEGIN);
export const fetchGovernanceRequest = action(FETCH_GOVERNANCE_REQUEST);
export const fetchGovernanceReceive = action(FETCH_GOVERNANCE_RECEIVE);
export const fetchGovernanceError = action(FETCH_GOVERNANCE_ERROR);

export const updateGovernance = action(UPDATE_GOVERNANCE_BEGIN);
export const updateGovernanceRequest = action(UPDATE_GOVERNANCE_REQUEST);
export const updateGovernanceReceive = action(UPDATE_GOVERNANCE_RECEIVE);
export const updateGovernanceError = action(UPDATE_GOVERNANCE_ERROR);

export const vote = action(VOTE_BEGIN);
export const voteRequest = action(VOTE_REQUEST);
export const voteReceive = action(VOTE_RECEIVE);
export const voteError = action(VOTE_ERROR);
