import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchInvoices, updateInvoice } from "app.actions/invoice";
import Content, { ContentEmpty } from "app.components/Common/Content/Content";

import InvoiceList from "app.components/Invoice/InvoiceList";
import InvoiceDetail from "app.components/Invoice/InvoiceDetail";

class InvoiceContainer extends Component {
  componentDidMount() {
    this.props.fetchInvoices({ userId: this.props.user.uid });
    this.interval = setInterval(
      () => this.props.fetchInvoices({ userId: this.props.user.uid }),
      15000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { invoice, match = {} } = this.props;
    const { invoices, isLoading } = invoice;

    const invoiceId = match.params && match.params.invoiceId;
    const selectedInvoice = invoices.find(
      item => item.id.toString() === invoiceId
    );
    return (
      <div className="monitor">
        <div className="flex flex--row flex--grow">
          {!selectedInvoice ? (
            <div className="masternode-detail">
              <div className="masternode-detail__list">
                <Content
                  loading={isLoading && invoices.length === 0}
                  empty={invoices.length === 0}
                >
                  <InvoiceList list={invoices} />
                  <ContentEmpty>
                    <div className="message--empty u-mt-large">
                      <div className="message__content">
                        <p>There are no invoices yet.</p>
                      </div>
                    </div>
                  </ContentEmpty>
                </Content>
              </div>
            </div>
          ) : (
            <InvoiceDetail
              invoice={selectedInvoice}
              updateInvoice={this.props.updateInvoice}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoice: state.invoice,
    user: state.user.user
  };
};

const mapDispatchToProps = {
  fetchInvoices,
  updateInvoice
};

InvoiceContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InvoiceContainer)
);

export default InvoiceContainer;
