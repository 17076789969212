import { call, put, takeEvery } from "redux-saga/effects";
import * as launchActions from "app.actions/launch";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { DASH_DECIMALS } from "app.constants";
import { handleError } from "app.sagas/error";

const launchSagas = [
  takeEvery(launchActions.SEARCH_COLLATERAL_BEGIN, searchCollateral),
  takeEvery(launchActions.FETCH_PAYMENT_OPTIONS_BEGIN, fetchPaymentOptions),
  takeEvery(launchActions.FETCH_LAUNCH_CONFIG_BEGIN, fetchLaunchConfig),
  takeEvery(launchActions.SAVE_LAUNCH_CONFIG_BEGIN, saveLaunchConfig),
  takeEvery(launchActions.CLEAR_LAUNCH_CONFIG_BEGIN, clearLaunchConfig),
  takeEvery(launchActions.LAUNCH_MASTERNODE_BEGIN, launchMasternode),
  takeEvery(launchActions.FETCH_LAUNCH_PROGRESS_BEGIN, fetchLaunchProgress),
  takeEvery(launchActions.FETCH_INVOICE_BEGIN, fetchInvoice),
  takeEvery(launchActions.SUBMIT_STRIPE_PAYMENT_BEGIN, submitStripePayment)
];

export default launchSagas;

export function* searchCollateral(action) {
  try {
    const selector = state => state.launch.isSearchCollateralLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    const { address, isThirdParty } = action.data;

    yield put(launchActions.searchCollateralRequest(nextStatus));

    let result;
    if (isThirdParty) {
      result = yield call(HostingAPI.validateFundAddress, address);
      result = { isValid: !!result.isValid };
    } else {
      result = yield call(HostingAPI.searchCollateral, address);
      const list = result
        .map(item => ({
          collateralTx: item.tx_hash,
          collateralIndex: item.position,
          collateral: `${item.tx_hash}_${item.position}`,
          used: item.used,
          balance: item.value
        }))
        .filter(item => !item.used);
      result = { list };
    }

    yield put(launchActions.searchCollateralReceive(result));
  } catch (err) {
    yield put(launchActions.searchCollateralError());
    yield put(handleError({ err }));
  }
}

export function* fetchPaymentOptions() {
  try {
    const selector = state => state.launch.isPaymentOptionsLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.fetchPaymentOptionsRequest(nextStatus));

    const paymentOptions = yield call(HostingAPI.requestPaymentOptions);

    yield put(launchActions.fetchPaymentOptionsReceive(paymentOptions));
  } catch (err) {
    yield put(launchActions.fetchPaymentOptionsError());
    yield put(handleError({ err }));
  }
}

export function* fetchLaunchConfig(action) {
  try {
    const selector = state => state.launch.isLaunchConfigLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    const id = action.data;

    if (!id) {
      yield put(launchActions.fetchLaunchConfigRequest(nextStatus));

      const localData = yield call(getFromLocalStorage);
      yield put(launchActions.fetchLaunchConfigReceive(localData));
      return;
    }

    yield call(clearLocalStorage);

    const progress = yield call(HostingAPI.requestLaunchProgress, id);

    const data = {
      status: progress.phase,
      paid: progress.paid,
      synced: progress.blocksCaughtUp,
      publicKey: progress.publicKey,
      depositAddress: progress.depositAddress,
      ip: progress.ip,
      votingPublicKey: progress.votingPublicKey,
      isThirdParty: progress.isThirdParty,
      operatorPublicKey: progress.blsPublicKey,
      lastStep: progress.paid ? 3 : 2,
      collateral: progress.collateral + "_" + progress.index
    };

    yield call(saveOnLocalStorage, data);
    yield put(launchActions.fetchLaunchConfigReceive(data));
  } catch (err) {
    yield put(launchActions.fetchLaunchConfigError());
    yield put(handleError({ err }));
  }
}

export function* saveLaunchConfig(action) {
  try {
    const selector = state => state.launch.isLaunchConfigLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.fetchLaunchConfigRequest(nextStatus));

    yield call(saveOnLocalStorage, action.data);

    const localStorageContent = yield call(getFromLocalStorage);

    yield put(launchActions.fetchLaunchConfigReceive(localStorageContent));
  } catch (err) {
    yield put(launchActions.fetchLaunchConfigError());
    yield put(handleError({ err }));
  }
}

export function* clearLaunchConfig() {
  try {
    const selector = state => state.launch.isLaunchConfigLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.fetchLaunchConfigRequest(nextStatus));

    yield call(clearLocalStorage);

    yield put(launchActions.clearLaunchConfigReceive({}));
  } catch (err) {
    yield put(launchActions.clearLaunchConfigError());
    yield put(handleError({ err }));
  }
}

export function* launchMasternode(action) {
  try {
    const selector = state => state.launch.isLaunchLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    const data = {
      status: "LAUNCHING",
      ...action.data
    };

    yield put(launchActions.launchMasternodeRequest(data));

    // save the info from the last step on local storage to prevent loosing this info
    // in case a refresh happens between the last click and the delaying call below (HostingAPI.launchMasternode)
    yield call(saveOnLocalStorage, data);

    const local = yield call(getFromLocalStorage);
    const collateral = data.collateral.split("_");

    const launchData = {
      publicKey: data.address,
      isThirdParty: local.masternodeType === "thirdParty",
      collateral: collateral[0],
      index: collateral[1],
      payment_option: local.paymentOption,
      node_type: "STANDARD",
      userid: local.userId,
      region: "US_EAST"
    };

    const launchResponse = yield call(HostingAPI.launchMasternode, launchData);

    if (launchResponse.error) {
      yield put(launchActions.launchMasternodeReceive(launchResponse));
      return;
    }

    yield call(saveOnLocalStorage, {
      ...action.data,
      ...launchResponse
    });

    yield put(launchActions.launchMasternodeReceive(launchResponse));
  } catch (err) {
    yield put(launchActions.launchMasternodeError());
    yield put(handleError({ err }));
  }
}

export function* fetchLaunchProgress() {
  try {
    const selector = state => state.launch.isLaunchProgressLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.fetchLaunchProgressRequest());

    const local = yield call(getFromLocalStorage);

    let data = {
      id: local.id
    };

    if (!data.id) {
      const masternodes = yield call(HostingAPI.requestCommissionedNodes);

      const masternode = masternodes.find(
        item => `${item.collateral}_${item.index}` === local.collateral
      );

      if (!masternode) {
        yield put(launchActions.fetchLaunchProgressReceive({}));
        return;
      }

      data.id = masternode.id;
    }

    const progress = yield call(HostingAPI.requestLaunchProgress, data.id);

    data = {
      ...data,
      status: progress.phase,
      paid: progress.paid,
      synced: progress.blocksCaughtUp,
      publicKey: progress.publicKey,
      depositAddress: progress.depositAddress,
      ip: progress.ip,
      votingPublicKey: progress.votingPublicKey,
      isThirdParty: progress.isThirdParty,
      operatorPublicKey: progress.blsPublicKey,
      collateral: progress.collateral + "_" + progress.index
    };

    yield call(saveOnLocalStorage, data);

    yield put(launchActions.fetchLaunchProgressReceive(data));
  } catch (err) {
    yield put(launchActions.fetchLaunchProgressError());
    yield put(handleError({ err }));
  }
}

export function* fetchInvoice(action) {
  try {
    const selector = state => state.launch.isInvoiceLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.fetchInvoiceRequest());

    let invoice = yield call(
      HostingAPI.requestLatestInvoiceForMasternode,
      action.data.id
    );

    invoice.amount = invoice.totalAmount / DASH_DECIMALS;
    yield put(launchActions.fetchInvoiceReceive(invoice));
  } catch (err) {
    yield put(launchActions.fetchInvoiceError());
    yield put(handleError({ err }));
  }
}

export function* submitStripePayment(action) {
  try {
    const selector = state => state.launch.isStripePaymentLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(launchActions.submitStripePaymentRequest());

    const data = {
      invoice: action.data.id,
      stripeToken: action.data.token,
      totalAmount: action.data.amount,
      renewal: !!action.data.renewal
    };

    const response = yield call(HostingAPI.submitStripePayment, data);

    yield put(launchActions.submitStripePaymentReceive(response));
  } catch (err) {
    yield put(launchActions.submitStripePaymentError());
    yield put(handleError({ err }));
  }
}

async function saveOnLocalStorage(newState) {
  const localStorageContent = await getFromLocalStorage();

  return Promise.resolve(
    localStorage.setItem(
      "launch",
      JSON.stringify({ ...localStorageContent, ...newState })
    )
  );
}

async function clearLocalStorage() {
  return Promise.resolve(localStorage.setItem("launch", "{}"));
}

function getFromLocalStorage() {
  return Promise.resolve(JSON.parse(localStorage.getItem("launch") || "{}"));
}
