import React from "react";
import { APP_NAME } from "app.constants";

const NoNetwork = () => (
  <div className="network-error">
    <div className="network-error__header">
      <span className="product-name">{APP_NAME}</span>
    </div>
    <div className="network-error__body">
      <h1>Connection Error</h1>
      <p>
        Could not connect to {APP_NAME}.{" "}
        <a
          href="#reload"
          onClick={e => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          Try again
        </a>
        .
      </p>
    </div>
  </div>
);

export default NoNetwork;
