import React, { Component } from "react";
import { SCREEN_SIZE_MD_MAX } from "app.constants";

class HeaderDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownMenuElement = null;
    this.dropdownMenuContainer = null;
    this.state = {
      menuOpen: false,
      menuClass: "header-dropdown__menu",
      menuOpenClass: "header-dropdown__menu--open"
    };
  }

  // this function watches for key presses to make sure some behaviors happen
  // if ESC (27) is pressed, we dismiss the dropdown
  _detectKey = event => {
    const key = event.which || event.keyCode;
    // esc press, dismiss modal
    if (this.state.menuOpen && key === 27) {
      this.toggleMenuOpen();
    }
  };

  _handleClick = event => {
    if (event.target === this.headerDropdownContainer) return;
    if (event.target === this.headerDropdownDescriptor) return;
    this.toggleMenuOpen();
  };

  toggleMenuOpen = () => {
    const { menuOpen, menuOpenClass } = this.state;

    if (menuOpen) {
      window.removeEventListener("keydown", this._detectKey, false);
      document.body.removeEventListener("click", this._handleClick, true);
      this.headerDropdownMenu.classList.remove(menuOpenClass);
    } else {
      document.body.addEventListener("click", this._handleClick, true);
      window.addEventListener("keydown", this._detectKey, false);
      this.headerDropdownMenu.classList.add(menuOpenClass);
    }

    const DOMRect = this.headerDropdownContainer.getBoundingClientRect();
    const menuWidth = this.headerDropdownMenu.getBoundingClientRect().width;
    const isScreenLarge = window.innerWidth > SCREEN_SIZE_MD_MAX;
    const dropdownMenuStyle = {
      left: isScreenLarge ? DOMRect.x : DOMRect.x - menuWidth + DOMRect.width
    };
    if (isScreenLarge) {
      dropdownMenuStyle.width = DOMRect.width;
    }

    this.setState({
      dropdownMenuStyle,
      menuOpen: !menuOpen
    });
  };

  render = () => (
    <div
      className="header-dropdown"
      ref={elem => {
        this.headerDropdownContainer = elem;
      }}
    >
      <button
        className="header-dropdown__descriptor"
        onClick={e => {
          e.preventDefault();
          this.toggleMenuOpen(!this.state.menuOpen);
        }}
        ref={elem => {
          this.headerDropdownDescriptor = elem;
        }}
        aria-haspopup="true"
        aria-expanded={this.state.menuOpen}
      >
        {this.props.descriptor}
      </button>
      <ul
        style={this.state.dropdownMenuStyle}
        className={this.state.menuClass}
        ref={elem => {
          this.headerDropdownMenu = elem;
        }}
      >
        {this.props.children.map((child, i) => (
          <li className={child.props.liClass} key={i}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HeaderDropdown;
