import React from "react";

const DigitalMoney = ({ coins, unit }) => {
  if (typeof coins === "undefined") return null;
  if (coins === null) return null;
  const parts = `${coins}`.split("."); // coerce to a string and split
  const bigPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
    <span className="coins">
      <span className="coins__big">{bigPart}</span>
      {unit ? (
        <span className="coins__little">
          .{parts[1]}&nbsp;{unit}
        </span>
      ) : (
        <span className="coins__little">.{parts[1]}</span>
      )}
    </span>
  );
};

export default DigitalMoney;
