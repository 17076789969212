import React from "react";
import { NavLink } from "react-router-dom";
import { matchPath } from "react-router";
import { useWindowSize } from "app.utils";

const GovernanceTabs = () => {
  const { isLarge } = useWindowSize();
  return (
    <div className="tabs__nav">
      <ul>
        <li>
          <NavLink activeClassName="active" to={`/governance/open`}>
            {isLarge ? <span>Open Proposals</span> : <span>Open</span>}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            isActive={(match, location) => {
              const actualMatch = matchPath(location.pathname, {
                path: `/governance/voted`
              });

              return actualMatch ? true : false;
            }}
            to={`/governance/voted`}
          >
            {isLarge ? <span>Voted Proposals</span> : <span>Voted</span>}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            isActive={(match, location) => {
              const actualMatch = matchPath(location.pathname, {
                path: `/governance/closed`
              });

              return actualMatch ? true : false;
            }}
            to={`/governance/closed`}
          >
            {isLarge ? <span>Closed Proposals</span> : <span>Closed</span>}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default GovernanceTabs;
