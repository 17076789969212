import {
  makeActionCreator,
  makeDefaultActionCreator
} from "app.actions/helpers";

export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const OPENING_MODAL_BEGIN = "OPENING_MODAL_BEGIN";
export const OPENING_PORTFOLIO_HELP_MODAL_BEGIN =
  "OPENING_PORTFOLIO_HELP_MODAL_BEGIN";
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";
export const UI_API_KEY_MODAL_COMPLETE = "UI_API_KEY_MODAL_COMPLETE";
export const UI_API_KEY_MODAL_DISMISS = "UI_API_KEY_MODAL_DISMISS";
export const UI_API_KEY_MODAL_OPEN = "UI_API_KEY_MODAL_OPEN";
export const UI_OPEN_MODAL = "UI_OPEN_MODAL";
export const UI_DISMISS_MODAL = "UI_DISMISS_MODAL";
export const UI_HIDE_UPGRADE_SLIDEOUT = "UI_HIDE_UPGRADE_SLIDEOUT";
export const UI_GLOBAL_ERROR = "UI_GLOBAL_ERROR";
export const UI_MODAL_ACTION_ERROR = "UI_MODAL_ACTION_ERROR";
export const UI_NETWORK_ERROR = "UI_NETWORK_ERROR";
export const UI_NEW_PORTFOLIO_HELP = "UI_NEW_PORTFOLIO_HELP";
export const UI_TOGGLE_COLLAPSED_CURRENCY = "UI_TOGGLE_COLLAPSED_CURRENCY";
export const UI_TOGGLE_COLLAPSED_EXCHANGE = "UI_TOGGLE_COLLAPSED_EXCHANGE";
export const UI_TOGGLE_NAV = "UI_TOGGLE_NAV";
export const UI_SKIP_INTRO = "UI_SKIP_INTRO";
export const UI_MODAL_WORKING_START = "UI_MODAL_WORKING_START";
export const UI_MODAL_WORKING_END = "UI_MODAL_WORKING_END";
export const UI_OPEN_ERROR_MODAL = "UI_OPEN_ERROR_MODAL";
export const UI_DISMISS_ERROR_MODAL = "UI_DISMISS_ERROR_MODAL";

export const toggleNavExpanded = makeActionCreator(UI_TOGGLE_NAV);
export const skipIntro = makeActionCreator(UI_SKIP_INTRO);

export const dismissModal = makeDefaultActionCreator(UI_DISMISS_MODAL);
export const openModal = makeDefaultActionCreator(UI_OPEN_MODAL);

export const dismissErrorModal = makeDefaultActionCreator(
  UI_DISMISS_ERROR_MODAL
);
export const openErrorModal = makeDefaultActionCreator(UI_OPEN_ERROR_MODAL);

export const toggleCollapseCurrency = makeActionCreator(
  UI_TOGGLE_COLLAPSED_CURRENCY,
  "currency"
);

export const toggleCollapseExchange = makeActionCreator(
  UI_TOGGLE_COLLAPSED_EXCHANGE,
  "exchange"
);

export const receiveModalActionError = makeActionCreator(
  UI_MODAL_ACTION_ERROR,
  "body",
  "response"
);

export const receiveGlobalError = makeActionCreator(UI_GLOBAL_ERROR, "error");

export const receiveNetworkError = makeActionCreator(UI_NETWORK_ERROR, "error");

export const requestNewPortfolioHelp = makeActionCreator(UI_NEW_PORTFOLIO_HELP);

export const addFlashMessage = makeActionCreator(
  ADD_FLASH_MESSAGE,
  "message",
  "messageId",
  "nextStatus"
);

export const removeFlashMessage = makeActionCreator(
  REMOVE_FLASH_MESSAGE,
  "messageId"
);

export const openNewPortfolioHelpModal = makeActionCreator(
  OPENING_PORTFOLIO_HELP_MODAL_BEGIN,
  "portfolioId",
  "override"
);

export const openApiKeyModal = makeActionCreator(
  UI_API_KEY_MODAL_OPEN,
  "params",
  "apiLinkSuccessCallback",
  "apiDismissCallback"
);
export const dismissApiKeyModal = makeActionCreator(UI_API_KEY_MODAL_DISMISS);

export const completeApiKeyModal = makeActionCreator(UI_API_KEY_MODAL_COMPLETE);

export const hideUpgradeSlideout = makeActionCreator(UI_HIDE_UPGRADE_SLIDEOUT);
