import React, { useContext } from "react";
import {
  SelectContext,
  SelectItemCard
} from "app.components/Common/Select/Select";

const SelectItem = props => {
  const { handleItemClick, selectedValue, getTabIndex } = useContext(
    SelectContext
  );
  const template = props.customTemplate || <SelectItemCard />;
  return (
    <div
      className={
        props.disabled ? "select__item select__item--disabled" : "select__item"
      }
      role="button"
      tabIndex={getTabIndex()}
      onClick={e => (props.disabled ? null : handleItemClick(e, props.value))}
      onKeyDown={e => {
        if (!props.disabled && (e.keyCode === 13 || e.keyCode === 32)) {
          handleItemClick(e, props.value);
        }
      }}
    >
      {React.cloneElement(template, { ...props, selectedValue })}
    </div>
  );
};

export default SelectItem;
