import * as masternodesActions from "app.actions/masternodes";

let initialState = {
  hosted: [],
  monitored: [],
  pending: [],
  transactions: [],
  invoices: [],
  tags: [],
  searchMonitoredMasternodeResult: [],
  summary: {
    addresses: {},
    numActive: 0,
    numPending: 0
  },
  isReady: false,
  isLoading: false,
  addMonitoredMasternodeError: null,
  searchMonitoredMasternodeError: null,
  isAddMonitoredMasternodeLoading: false,
  isSearchMonitoredMasternodeLoading: false,
  isDeleteMonitoredMasternodeLoading: false,
  isMasternodeTransactionsLoading: false,
  isMasternodeInvoicesLoading: false,
  isFetchTagsLoading: false,
  isAddTagLoading: false
};

const masternodes = (state = initialState, action) => {
  switch (action.type) {
    case masternodesActions.FETCH_MASTERNODES_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case masternodesActions.FETCH_MASTERNODES_RECEIVE: {
      return {
        ...state,
        isLoading: false,
        isReady: true,
        ...action.data
      };
    }
    case masternodesActions.FETCH_MASTERNODES_ERROR: {
      return {
        ...state,
        isLoading: false,
        ...action.data
      };
    }
    case masternodesActions.SEARCH_MONITORED_MASTERNODE_REQUEST: {
      return {
        ...state,
        isSearchMonitoredMasternodeLoading: true
      };
    }
    case masternodesActions.SEARCH_MONITORED_MASTERNODE_RECEIVE: {
      return {
        ...state,
        isSearchMonitoredMasternodeLoading: false,
        ...action.data
      };
    }
    case masternodesActions.SEARCH_MONITORED_MASTERNODE_ERROR: {
      return {
        ...state,
        isSearchMonitoredMasternodeLoading: false,
        ...action.data
      };
    }
    case masternodesActions.ADD_MONITORED_MASTERNODE_REQUEST: {
      return {
        ...state,
        isAddMonitoredMasternodeLoading: true
      };
    }
    case masternodesActions.ADD_MONITORED_MASTERNODE_RECEIVE: {
      return {
        ...state,
        isAddMonitoredMasternodeLoading: false,
        ...action.data
      };
    }
    case masternodesActions.ADD_MONITORED_MASTERNODE_ERROR: {
      return {
        ...state,
        isAddMonitoredMasternodeLoading: false,
        ...action.data
      };
    }
    case masternodesActions.ADD_MONITORED_MASTERNODE_RESET: {
      return {
        ...state,
        isAddMonitoredMasternodeLoading: false,
        isSearchMonitoredMasternodeLoading: false,
        addMonitoredMasternodeError: null,
        searchMonitoredMasternodeError: null,
        searchMonitoredMasternodeResult: []
      };
    }
    case masternodesActions.REMOVE_MONITORED_MASTERNODE_REQUEST: {
      return {
        ...state,
        isDeleteMonitoredMasternodeLoading: true
      };
    }
    case masternodesActions.REMOVE_MONITORED_MASTERNODE_RECEIVE: {
      return {
        ...state,
        isDeleteMonitoredMasternodeLoading: false,
        monitored: state.monitored.filter(
          item => item.monitorId !== action.data.id
        )
      };
    }
    case masternodesActions.REMOVE_MONITORED_MASTERNODE_ERROR: {
      return {
        ...state,
        isDeleteMonitoredMasternodeLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_MASTERNODE_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isMasternodeTransactionsLoading: true
      };
    }
    case masternodesActions.FETCH_MASTERNODE_TRANSACTIONS_RECEIVE: {
      return {
        ...state,
        isMasternodeTransactionsLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_MASTERNODE_TRANSACTIONS_ERROR: {
      return {
        ...state,
        isMasternodeTransactionsLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_MASTERNODE_INVOICES_REQUEST: {
      return {
        ...state,
        isMasternodeInvoicesLoading: true
      };
    }
    case masternodesActions.FETCH_MASTERNODE_INVOICES_RECEIVE: {
      return {
        ...state,
        isMasternodeInvoicesLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_MASTERNODE_INVOICES_ERROR: {
      return {
        ...state,
        isMasternodeInvoicesLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_TAGS_REQUEST: {
      return {
        ...state,
        isFetchTagsLoading: true
      };
    }
    case masternodesActions.FETCH_TAGS_RECEIVE: {
      return {
        ...state,
        isFetchTagsLoading: false,
        ...action.data
      };
    }
    case masternodesActions.FETCH_TAGS_ERROR: {
      return {
        ...state,
        isFetchTagsLoading: false,
        ...action.data
      };
    }
    case masternodesActions.ADD_TAG_REQUEST: {
      return {
        ...state,
        ...action.data,
        isAddTagLoading: true
      };
    }
    case masternodesActions.ADD_TAG_RECEIVE: {
      return {
        ...state,
        isAddTagLoading: false
      };
    }
    case masternodesActions.ADD_TAG_ERROR: {
      return {
        ...state,
        isAddTagLoading: false,
        ...action.data
      };
    }
    case masternodesActions.REMOVE_TAG_REQUEST: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
};

export default masternodes;
