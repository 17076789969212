export const makeActionCreator = (type, ...argNames) => {
  return (...args) => {
    let action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};

export const makeDefaultActionCreator = type => {
  const isError = type.split("_").pop() === "ERROR";
  const defaultParam = isError ? "error" : "data";

  return makeActionCreator(type, defaultParam);
};

export const handleApiKeyError = function(
  body,
  exchange,
  openApiKeyModal,
  errorCallback,
  error,
  apiLinkSuccessCallback,
  apiDismissCallback
) {
  if (typeof openApiKeyModal === "undefined")
    throw new Error(
      "Must provide openApiKeyModal action creator to handleApiKeyError"
    );

  const { metadata } = body;

  const codes = [
    "apiKeyOverScope",
    "apiKeyRequired",
    "apiKeyInvalid",
    "apiKeyAccessDenied",
    "apiKeyInvalidSignedRequest"
  ];

  if (codes.findIndex(item => item === metadata.code) > -1) {
    const params = {
      exchange: exchange,
      code: metadata.code
    };

    return openApiKeyModal(params, apiLinkSuccessCallback, apiDismissCallback);
  } else {
    return errorCallback(
      {
        type: "EXCHANGE_FAILURE",
        message: `There was an error connecting to ${exchange.label}.`
      },
      error
    );
  }
};
