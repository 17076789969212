import React from "react";

const LogoutIcon = ({ className, color = "#FFF" }) => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Logout</title>
    <path
      d="M10 16H0v-4h10V8l6 6-6 6v-4zM30 0v26l-12 6v-6H6v-8h2v6h10V6l8-4H8v8H6V0h24z"
      fillRule="nonzero"
      fill="#08293F"
    />
  </svg>
);

export default LogoutIcon;
