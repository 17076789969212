import React from "react";

const InvoicesIcon = () => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Invoices</title>
    <path
      d="M27 0H3C1.35 0 0 1.35 0 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3zm-1 28H4V4h22v24zM8 19h14v2H8v-2zm0 4h14v2H8v-2zm4.2-12.4V7.8H15V6.4h-2.8V5h-1.4v1.4H8V12h2.8v2.8H8v1.4h2.8v1.4h1.4v-1.4H15v-5.6h-2.8zm-1.4 0H9.4V7.8h1.4v2.8zm2.8 4.2h-1.4V12h1.4v2.8z"
      fill="#08293F"
      fillRule="nonzero"
    />
  </svg>
);

export default InvoicesIcon;
