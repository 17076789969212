import React from "react";
import moment from "moment";
import DigitalMoney from "app.components/Util/DigitalMoney";
import CashMoney from "app.components/Util/CashMoney";
import { NEXT_REWARD_MIN_SECONDS } from "app.constants";

const MasternodeDetailSummary = ({ masternode, network, summary }) => {
  const { dashPriceUsd, masternodeReward } = network;
  const { collateralAddress, payoutAddress } = masternode;
  const currentSeconds = new Date().getTime() / 1000 + NEXT_REWARD_MIN_SECONDS;

  const collateralSummary = summary[collateralAddress] || {};
  const payoutSummary = summary[payoutAddress] || {};
  const rewards = payoutSummary.rewards || {};
  const currentBalance = rewards.available ? parseFloat(rewards.available) : 0;
  const totalRewards = rewards.mined ? parseFloat(rewards.mined) : 0;
  let netWorth = collateralSummary.balance || 0;

  if (collateralAddress !== payoutAddress) {
    netWorth += currentBalance;
  }

  let data = {
    currentBalance,
    netWorth,
    totalRewards,
    lastPayment: masternode.lastPaid,
    lastPaymentValue: masternodeReward
  };

  data.netWorthUsd = data.netWorth * dashPriceUsd;
  data.currentBalanceUsd = data.currentBalance * dashPriceUsd;
  data.totalRewardsUsd = data.totalRewards * dashPriceUsd;

  return (
    <div className="summary">
      <div className="summary__title hidden-lg">
        <div className="summary__title__description">Total Balance:</div>
        <div className="summary__title__value">
          <DigitalMoney coins={data.netWorth.toFixed("2")} /> Dash (
          <CashMoney dollars={data.netWorthUsd} />)
        </div>
      </div>
      <div className="summary__data">
        <div className="summary__data__items">
          <div className="summary__item summary__item--x020 visible-lg">
            <h2 className="summary__item__header">Total Balance</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                <DigitalMoney coins={data.netWorth.toFixed("2")} /> Dash
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary">
                <CashMoney dollars={data.netWorthUsd} />
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x020">
            <h2 className="summary__item__header">Unspent Rewards</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                <DigitalMoney coins={data.currentBalance.toFixed("2")} /> Dash
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary">
                <CashMoney dollars={data.currentBalanceUsd} />
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x020">
            <h2 className="summary__item__header">Earned Rewards</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                <DigitalMoney coins={data.totalRewards.toFixed("2")} /> Dash
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x020">
            <h2 className="summary__item__header">Last Reward *</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                {moment(data.lastPayment * 1000).fromNow()}
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x020">
            <h2 className="summary__item__header">Next Reward *</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                {currentSeconds < masternode.nextPayment
                  ? "~" + moment(masternode.nextPayment * 1000).fromNow()
                  : "Within the next few hours"}
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasternodeDetailSummary;
