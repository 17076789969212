import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { store } from "app.config/configureStore";
import moment from "moment";
import Modal from "app.components/Modal/Modal";
import { COMMON_DATE_ONLY_FORMAT } from "app.constants";
import { dismissModal, openModal } from "app.actions/ui";
import {
  fetchMasternodes,
  fetchMasternodeTransactions,
  fetchMasternodeInvoices,
  fetchTags,
  removeTag,
  addTag
} from "app.actions/masternodes";
import Content, { ContentEmpty } from "app.components/Common/Content/Content";
import Tags from "app.components/Common/Tags/Tags";
import NodeStatus from "../Common/NodeStatus";
import MasternodeDetailSummary from "app.components/Masternode/MasternodeDetailSummary";
import MasternodeDetailTransactions from "app.components/Masternode/MasternodeDetailTransactions";
import MasternodeTagAdd from "app.components/Masternode/MasternodeTagAdd";

class MasternodeDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masternode: null,
      tagsChanged: false
    };
  }
  componentDidMount() {
    this.props.fetchMasternodes();
    this.props.fetchTags();
  }
  componentDidUpdate() {
    if (!this.state.masternode && this.props.masternodes.isReady) {
      const { match = {}, masternodes } = this.props;
      const commissionId = match.params && match.params.commissionId;
      const masternode = masternodes.hosted.find(
        item => item.commissionId.toString() === commissionId
      );
      if (masternode) {
        this.props.fetchMasternodeTransactions({
          payoutAddress: masternode.payoutAddress
        });
        this.props.fetchMasternodeInvoices({
          id: masternode.commissionId
        });
        this.setState({ masternode });
      } else {
        store.dispatch(push("/"));
      }
    }
    if (this.state.tagsChanged) {
      const masternode = this.props.masternodes.hosted.find(
        item => item.commissionId === this.state.masternode.commissionId
      );
      this.setState({ masternode, tagsChanged: false });
    }
  }
  render() {
    const { masternodes, network, ui, dismissModal, openModal } = this.props;
    const { modalMasternodeTagAdd } = ui;
    const {
      isMasternodeTransactionsLoading,
      isReady,
      isMasternodeInvoicesLoading,
      transactions,
      invoices,
      isFetchTagsLoading,
      isAddTagLoading,
      summary
    } = masternodes;
    const { masternode } = this.state;

    return (
      <Content loaderType="full" loading={!isReady || !masternode}>
        {(masternode && (
          <div className="monitor">
            <MasternodeDetailSummary
              masternode={masternode}
              network={network}
              summary={summary.addresses}
            />
            <div className="flex flex--row flex--grow">
              <div className="masternode-detail u-pb-default">
                <div className="masternode-detail__title u-mb-default flex flex--row">
                  Masternode Info
                  <div className="hidden-sm u-ml-large align--center flex flex--grow">
                    <NodeStatus status={masternode.networkStatus} />
                  </div>
                  <div className="visible-sm u-ml-large align--center flex flex--grow">
                    <div className="flex justify--right">
                      <NodeStatus status={masternode.networkStatus} />
                    </div>
                  </div>
                </div>
                <div className="masternode-detail__item masternode-detail__item--50">
                  <div className="masternode-detail__item__description">
                    Server IP and Port
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.ip}:{masternode.port}
                  </div>
                </div>
                <div className="masternode-detail__item masternode-detail__item--50">
                  <div className="masternode-detail__item__description">
                    Masternode Type
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.isThirdParty
                      ? "Third Party Masternode"
                      : "Standard Masternode"}
                  </div>
                </div>
                <div className="masternode-detail__item masternode-detail__item--50">
                  <div className="masternode-detail__item__description">
                    Creation Date
                  </div>
                  <div className="masternode-detail__item__value">
                    {moment(masternode.creationDate).format(
                      COMMON_DATE_ONLY_FORMAT
                    )}
                  </div>
                </div>
                <div className="masternode-detail__item masternode-detail__item--50">
                  <div className="masternode-detail__item__description">
                    Expiration Date
                  </div>
                  <div className="masternode-detail__item__value">
                    {moment(masternode.expirationDate).format(
                      COMMON_DATE_ONLY_FORMAT
                    )}
                  </div>
                </div>

                <div className="masternode-detail__item masternode-detail__item--100">
                  <div className="masternode-detail__item__description">
                    Tags
                    <button
                      className="button button--sm button--outline--positive u-ml-default"
                      disabled={isFetchTagsLoading || isAddTagLoading}
                      onClick={() => {
                        openModal({ modalMasternodeTagAdd: true });
                      }}
                    >
                      + Tag
                    </button>
                  </div>
                  <div className="masternode-detail__item__value flex flex--row-wrap">
                    <Tags
                      tags={masternode.tags}
                      onDelete={tagId => {
                        this.props.removeTag({
                          tagId,
                          masternodeId: masternode.monitorId
                        });
                        this.setState({ tagsChanged: true });
                      }}
                    />
                  </div>
                </div>
              </div>
              {false ? (
                <div className="masternode-detail masternode-detail--50">
                  <div className="masternode-detail__title">Last Invoices</div>
                  <div className="masternode-detail__list">
                    <Content
                      loading={isMasternodeInvoicesLoading}
                      empty={invoices.length === 0}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th className="text--left">Invoice</th>
                            <th className="text--center">Due Date</th>
                            <th className="text--right">Amount</th>
                            <th className="text--right">Status</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoices.slice(0, 4).map((item, itemIndex) => (
                            <tr key={itemIndex}>
                              <td className="text--left">{item.sequence}</td>
                              <td className="text--center">
                                {moment(item.dueDate).format(
                                  COMMON_DATE_ONLY_FORMAT
                                )}
                              </td>
                              <td className="text--right">
                                {item.amount} DASH
                              </td>
                              <td className="text--right">{item.settled}</td>
                              <td>
                                <Link to={`/invoices/${item.id}`}>View</Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="text--right" colSpan="99">
                              <Link to={`/invoices`}>View all invoices</Link>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <ContentEmpty>
                        <div className="message--empty u-mt-large">
                          <div className="message__content">
                            <p>There are no invoices yet.</p>
                          </div>
                        </div>
                      </ContentEmpty>
                    </Content>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="flex flex--row flex--grow">
              <div className="masternode-detail u-pb-default">
                <div className="masternode-detail__title u-mb-default">
                  ProTx Info
                </div>

                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    ProTx Hash
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.proTxHash}
                  </div>
                </div>

                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    Payout Address
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.payoutAddress}
                  </div>
                </div>
                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    Collateral
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.collateral}
                  </div>
                </div>
                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    Owner Address
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.ownerAddress}
                  </div>
                </div>

                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    Voting Address
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.votingAddressNode40}
                  </div>
                  {masternode.votingAddress !==
                  masternode.votingAddressNode40 ? (
                    <div className="masternode-detail__item__message">
                      <span class="glyphicons glyphicons-circle-exclamation-mark" />
                      The Voting Address registered with the network does not
                      match the NODE40 Voting Address above. If you would like
                      to vote from the Budget Proposals page the Voting Address
                      must be updated. If you plan to vote from your own wallet,
                      you can ignore this message.
                    </div>
                  ) : null}
                </div>
                <div className="masternode-detail__item">
                  <div className="masternode-detail__item__description">
                    Operator Reward
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.operatorReward}%
                  </div>
                </div>
                <div className="masternode-detail__item masternode-detail__item--100">
                  <div className="masternode-detail__item__description">
                    Operator Public Key
                  </div>
                  <div className="masternode-detail__item__value">
                    {masternode.operatorPublicKeyNode40}
                  </div>
                  {masternode.operatorPublicKey !==
                  masternode.operatorPublicKeyNode40 ? (
                    <div className="masternode-detail__item__message masternode-detail__item__message--error">
                      <span class="glyphicons glyphicons-circle-exclamation-mark" />
                      The operator key on the network does not match the NODE40
                      Operator Key above.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex flex--row flex--grow">
              <div className="masternode-detail">
                <div className="masternode-detail__title">
                  Transaction History
                </div>
                <div className="masternode-detail__list">
                  <MasternodeDetailTransactions
                    transactions={transactions}
                    isMasternodeTransactionsLoading={
                      isMasternodeTransactionsLoading
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )) || <div />}

        <Modal
          modalClass="monitored"
          isOpen={modalMasternodeTagAdd}
          dismiss={() => {
            dismissModal({ modalMasternodeTagAdd: false });
          }}
        >
          <MasternodeTagAdd
            add={tag => {
              dismissModal({ modalMasternodeTagAdd: false });
              this.setState({ tagsChanged: true });
              this.props.addTag({
                masternodeId: masternode.monitorId,
                tag
              });
            }}
          />
        </Modal>
      </Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    masternodes: state.masternodes,
    network: state.network,
    ui: state.ui
  };
};

const mapDispatchToProps = {
  fetchMasternodes,
  fetchMasternodeTransactions,
  fetchMasternodeInvoices,
  dismissModal,
  openModal,
  fetchTags,
  removeTag,
  addTag
};

MasternodeDetailContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MasternodeDetailContainer)
);

export default MasternodeDetailContainer;
