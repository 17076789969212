import React, { useState, useEffect } from "react";

import StepperNav from "app.components/Common/Stepper/StepperNav";
import StepperItem from "app.components/Common/Stepper/StepperItem";
import StepperItemSub from "app.components/Common/Stepper/StepperItemSub";
import StepperControls from "app.components/Common/Stepper/StepperControls";
import StepperHeading from "app.components/Common/Stepper/StepperHeading";
import StepperNavIndicator from "app.components/Common/Stepper/StepperNavIndicator";
import Content from "app.components/Common/Content/Content";
const StepperContext = React.createContext({});

const Stepper = ({
  children = [],
  heading,
  isLoading,
  onItemSubmit,
  startingStep = 0
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(startingStep);
  const [maxStepIndex, setMaxStepIndex] = useState(startingStep);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentStep = children[currentStepIndex];
  const steps = children.map(item => ({ ...item.props }));
  const stepNumber = currentStepIndex + 1;

  useEffect(() => {
    setCurrentStepIndex(startingStep);
  }, [startingStep]);

  const getHeadingPrefix = () => {
    let headingPrefixCount = 1;

    return () => {
      headingPrefixCount++;
      return stepNumber + String.fromCharCode(95 + headingPrefixCount);
    };
  };

  const next = data => {
    if (currentStepIndex === steps.length) {
      return;
    }
    const goToNextStep = () => {
      setIsSubmitting(false);
      const nextIndex = currentStepIndex + 1;
      setCurrentStepIndex(nextIndex);
      if (nextIndex > maxStepIndex) {
        setMaxStepIndex(nextIndex);
      }
    };

    const onSubmit = steps[currentStepIndex].onSubmit || onItemSubmit;

    if (onSubmit) {
      const result = onSubmit(data);

      if (result instanceof Promise) {
        setIsSubmitting(true);
        return result.then(() => {
          goToNextStep();
        });
      }
    }

    return goToNextStep();
  };

  const previous = () => {
    if (currentStepIndex === 0) {
      return;
    }

    setCurrentStepIndex(currentStepIndex - 1);
  };

  const stepper = {
    steps,
    currentStepIndex,
    maxStepIndex,
    next,
    previous,
    getHeadingPrefix: getHeadingPrefix(),
    isSubmitting
  };

  return (
    <div className="stepper">
      <StepperContext.Provider value={stepper}>
        <div className="stepper__header">
          <h1>{heading}</h1>
        </div>
        <StepperNav />
        <div className="stepper__content">
          <Content loading={isLoading}>{currentStep}</Content>
        </div>
      </StepperContext.Provider>
    </div>
  );
};

export default Stepper;

export {
  StepperNav,
  StepperItem,
  StepperItemSub,
  StepperControls,
  StepperContext,
  StepperHeading,
  StepperNavIndicator
};
