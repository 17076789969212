import React from "react";
import { SelectIndicator } from "app.components/Common/Select/Select";

const SelectItemTemplate = ({
  label,
  description,
  selectedValue,
  value,
  helpDescription,
  disabled
}) => (
  <div className="select__item__card--container">
    <div className="select__item__card">
      <p className="select__item__card__name">{label}</p>
      <p className="select__item__card__info">
        <span className="select__item__card__info__label">{description}</span>
        {helpDescription && (
          <span
            role="button"
            tabIndex="0"
            className="select__item__card__info__toggle"
          >
            <span className="sr-only">Show description</span>
          </span>
        )}
      </p>
      {helpDescription && (
        <div className="select__item__card__descr">
          <p>{helpDescription}</p>
        </div>
      )}
    </div>
    <SelectIndicator disabled={disabled} selected={selectedValue === value} />
  </div>
);

export default SelectItemTemplate;
