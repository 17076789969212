import React from "react";
import { Route, Switch } from "react-router-dom";
import CashMoney from "app.components/Util/CashMoney";

const Toolbar = ({ network }) => (
  <div className="toolbar">
    <div className="toolbar-container">
      <div className="toolbar-container__title">
        <Switch>
          <Route exact path="/" render={() => "Masternodes"} />
          <Route
            path="/launch"
            render={() => (
              <React.Fragment>
                <span className="hidden-sm">Launch a Masternode</span>
                <span className="visible-sm">Launch MN</span>
              </React.Fragment>
            )}
          />
          <Route
            path="/governance"
            render={() => (
              <React.Fragment>
                <span className="hidden-sm">Budget Proposals</span>
                <span className="visible-sm">Proposals</span>
              </React.Fragment>
            )}
          />
          <Route
            path="/masternodes"
            render={() => (
              <React.Fragment>
                <span className="hidden-sm">Masternode Details</span>
                <span className="visible-sm">MN Details</span>
              </React.Fragment>
            )}
          />
          <Route path="/account" render={() => "Account"} />
          <Route path="/invoices" render={() => "Invoices"} />
        </Switch>
      </div>
      <div className="toolbar-container__data">
        {network.isReady && (
          <div className="toolbar-container__data__prices">
            <ul>
              <li>
                <img
                  alt="Dash"
                  title="Dash"
                  src="https://assets.zaimirai.com/images/cryptocurrency-icons/svg/color/dash.svg"
                />
                <CashMoney dollars={network.dashPriceUsd} />
              </li>
              <li>
                <img
                  alt="Bitcoin"
                  title="Bitcoin"
                  src="https://assets.zaimirai.com/images/cryptocurrency-icons/svg/color/btc.svg"
                />
                <CashMoney dollars={network.btcPriceUsd} />
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Toolbar;
