import React from "react";
import moment from "moment";
import DigitalMoney from "app.components/Util/DigitalMoney";
import CashMoney from "app.components/Util/CashMoney";
import { NEXT_REWARD_MIN_SECONDS } from "app.constants";

const MasternodeSummary = ({ list, network, type, summary }) => {
  const { dashPriceUsd, masternodeReward } = network;
  const currentSeconds = new Date().getTime() / 1000 + NEXT_REWARD_MIN_SECONDS;

  let addresses = [];

  let data = list.reduce(
    (props, item) => {
      addresses.push(item.payoutAddress, item.collateralAddress);

      if (
        props.nextPayment === 0 ||
        (item.nextPayment && item.nextPayment < props.nextPayment)
      ) {
        props.nextPayment = item.nextPayment;
      }
      if (props.lastPayment === 0 || item.lastPaid > props.lastPayment) {
        props.lastPayment = item.lastPaid;
      }

      return props;
    },
    {
      nextPayment: 0,
      nextPaymentValue: masternodeReward,
      lastPayment: 0,
      lastPaymentValue: masternodeReward
    }
  );

  const { netWorth, currentBalance } = Object.keys(summary.addresses).reduce(
    (map, key) => {
      if (addresses.includes(key)) {
        const item = summary.addresses[key];
        if (item.balance) {
          map.netWorth += parseFloat(item.balance);
        }
        if (item.rewards) {
          map.currentBalance += parseFloat(item.rewards.available);
        }
      }
      return map;
    },
    {
      netWorth: 0,
      currentBalance: 0
    }
  );

  data.netWorth = netWorth;
  data.netWorthUsd = netWorth * dashPriceUsd;
  data.currentBalance = currentBalance;
  data.currentBalanceUsd = currentBalance * dashPriceUsd;

  return (
    <div className="summary">
      <div className="summary__data">
        <div className="summary__data__items">
          <div className="summary__item summary__item--x025">
            <h2 className="summary__item__header">Total Balance</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                <DigitalMoney coins={data.netWorth.toFixed("2")} unit="Dash" />
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary">
                <CashMoney dollars={data.netWorthUsd} />
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x025">
            <h2 className="summary__item__header">Unspent Rewards</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                <DigitalMoney
                  coins={data.currentBalance.toFixed("2")}
                  unit="Dash"
                />
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary">
                <CashMoney dollars={data.currentBalanceUsd} />
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x025 visible-lg">
            <h2 className="summary__item__header">Number of Nodes</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                {summary.numActive} Active
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary">
                {summary.numPending} Inactive
              </p>
            </div>
          </div>
          <div className="summary__item summary__item--x025">
            <h2 className="summary__item__header">Next Reward *</h2>
            <div className="summary__item__content">
              <p className="summary__item__content__value">
                {currentSeconds < data.nextPayment
                  ? "~" + moment(data.nextPayment * 1000).fromNow()
                  : "Within the next few hours"}
              </p>
              <p className="summary__item__content__value summary__item__content__value--secondary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasternodeSummary;
