import { call, put, takeEvery } from "redux-saga/effects";
import * as networkActions from "app.actions/network";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { handleError } from "app.sagas/error";

const networkSagas = [
  takeEvery(networkActions.FETCH_NETWORK_BEGIN, fetchNetwork)
];

export default networkSagas;

export function* fetchNetwork() {
  try {
    const selector = state => state.network.isLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(networkActions.fetchNetworkRequest(nextStatus));

    const numActiveNodes = yield call(HostingAPI.requestMasternodeCount);
    const btcPriceUsd = yield call(HostingAPI.requestBitcoinPriceInUSD);
    const dashPriceUsd = yield call(HostingAPI.requestDashPriceInUSD);

    yield put(
      networkActions.fetchNetworkReceive({
        numActiveNodes,
        btcPriceUsd,
        dashPriceUsd
      })
    );
  } catch (err) {
    yield put(networkActions.fetchNetworkError());
    yield put(handleError({ err }));
  }
}
