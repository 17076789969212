import React from "react";

const Message = ({ children = [] }) => {
  return (
    <div className="message">
      {typeof children === "string" ? (
        <MessageContent>{children}</MessageContent>
      ) : (
        children
      )}
    </div>
  );
};

const MessageCommands = ({ children }) => {
  return <div className="message__commands">{children}</div>;
};

const MessageContent = ({ children }) => {
  return <div className="message__content">{children}</div>;
};

export default Message;

export { MessageCommands, MessageContent };
