import React from "react";
import moment from "moment";

const TimeAgo = props => {
  const { value } = props;

  return <span>{moment(value * 1000).fromNow()}</span>;
};

export default TimeAgo;
