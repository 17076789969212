let lastKnownGood = undefined;

const cancelActionsOnNotNetwork = [
  "UI_OPEN_MODAL",
  "UI_DISMISS_MODAL",
  "SELECT_ENTRY"
];

export default function networkCancelMiddleware(history) {
  return store => next => action => {
    if (typeof action === "function") {
      if (window.navigator.onLine === false) {
        return;
      }
    }

    // a few explicit actions we don't want to do when net access is borked
    if (window.navigator.onLine === false) {
      if (
        typeof action.type !== "undefined" &&
        cancelActionsOnNotNetwork.findIndex(item => action.type === item) > -1
      ) {
        return;
      }
    }

    if (action.type === "@@router/LOCATION_CHANGE") {
      if (window.navigator.onLine === false) {
        const curLoc = history.location;
        if (
          typeof lastKnownGood !== "undefined" &&
          curLoc.pathname + curLoc.search !==
            lastKnownGood.pathname + lastKnownGood.search
        ) {
          history.replace(lastKnownGood);
        }
        return;
      } else {
        // last known good?
        lastKnownGood = history.location;
      }
    }

    let result = next(action);
    return result;
  };
}
