import React, { useContext } from "react";
import { StepperContext } from "app.components/Common/Stepper/Stepper";

const StepperControls = ({
  children,
  nextDisabled,
  disabled,
  onSubmitParams,
  onClickBack,
  backHidden
}) => {
  const stepper = useContext(StepperContext);

  onClickBack = onClickBack || stepper.previous;

  return (
    <div className="stepper__controls">
      {children || (
        <React.Fragment>
          {!backHidden && (
            <button
              className="button button--text"
              disabled={disabled}
              onClick={onClickBack}
            >
              Back
            </button>
          )}
          <button
            className="button button--primary"
            disabled={nextDisabled || disabled}
            onClick={() => stepper.next(onSubmitParams)}
          >
            Next
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default StepperControls;
