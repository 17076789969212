import * as userActions from "app.actions/user";
import * as authenticationActions from "app.actions/authentication";
import { testLocalStorage } from "app.utils";
import {
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_DECODED_TOKEN_KEY
} from "app.constants"; //,
import jwtDecode from "jwt-decode";

const LOCAL_STORAGE_STATUS = testLocalStorage();

// intial state, eventually we'll restore this from local storage (per user)
let initialState = {
  token: undefined,
  user: {},
  userId: undefined,
  userLoading: false,
  isAuthenticating: false,
  isAuthenticated: false,
  isLoggingOut: false,
  isFetchUserCreditsLoading: false,
  credits: 0
};

// if local storage is supported, we rehydrate the token/user from there
if (LOCAL_STORAGE_STATUS) {
  try {
    const tokenJSON = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (typeof tokenJSON !== "undefined") {
      const token = JSON.parse(tokenJSON);
      const tokenParsed = jwtDecode(token) || {};
      initialState = { ...initialState, token: token, user: tokenParsed };
    }
  } catch (e) {
    console.log(e);
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case userActions.AUTHORIZE_USER_RECEIVE:
      return persistToken({
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isLoggingOut: false,
        token: action.token,
        user: jwtDecode(action.token)
      });

    case userActions.AUTHORIZE_USER_ERROR:
      return {
        ...state,
        ...action.data
      };
    case userActions.RESET_USER:
    case authenticationActions.LOGOUT:
      return persistToken({
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isLoggingOut: true,
        token: undefined,
        user: undefined
      });
    case userActions.INVALIDATE_TOKEN:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        token: undefined
      };
    case userActions.USER_TOKEN_REQUEST:
      return { ...state, isAuthenticating: true };
    case userActions.USER_TOKEN_RECEIVE:
      return persistToken({
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        token: action.response.token,
        user: jwtDecode(action.response.token)
      });
    case userActions.USER_TOKEN_FETCH_ERROR:
      return { ...state, isAuthenticating: false, ...action.data };
    case userActions.USER_IS_AUTHENTICATED:
      return { ...state, isAuthenticating: false, isAuthenticated: true };
    case userActions.USER_REQUEST:
      return { ...state, userLoading: true };
    case userActions.USER_RECEIVE:
      return { ...state, userLoading: false, user: action.response };
    case userActions.USER_FETCH_ERROR:
      return { ...state, userLoading: false, ...action.data };
    case userActions.FETCH_USER_CREDITS_REQUEST:
      return { ...state, isFetchUserCreditsLoading: true };
    case userActions.FETCH_USER_CREDITS_RECEIVE:
      return { ...state, isFetchUserCreditsLoading: false, ...action.data };
    case userActions.FETCH_USER_CREDITS_ERROR:
      return { ...state, isFetchUserCreditsLoading: false, ...action.data };
    default:
      return state;
  }
};

export default user;

// we don't want to persist all state to local storage, so when a some auth actions happen,
// we'll pull out the keys we are interested in and save them
function persistToken(state) {
  if (LOCAL_STORAGE_STATUS) {
    const { token, user } = state;
    if (typeof token === "undefined") {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_DECODED_TOKEN_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(token));
      localStorage.setItem(
        LOCAL_STORAGE_DECODED_TOKEN_KEY,
        JSON.stringify(user)
      );
    }
  }

  return state;
}
