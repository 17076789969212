import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      <div className="footer__links">
        <ul>
          <li>
            <a href="https://www.zaimirai.com">ZAIMIRAI.com</a>
          </li>
          <li>
            <a href="https://zaimirai.com/tos/">Terms of Use</a>
          </li>
          <li>
            <a href="https://zaimirai.com/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://zaimirai.com/support/">Contact Us</a>
          </li>
        </ul>
      </div>
      <div className="footer__copyright">
        <p>
          © Copyright {new Date().getFullYear()} Zaimirai LLC. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
