import React, { useState, useContext, useEffect } from "react";
import { push } from "connected-react-router";
import { store } from "app.config/configureStore";
import {
  StepperItemSub,
  StepperControls
} from "app.components/Common/Stepper/Stepper";
import Select, {
  SelectItem,
  SelectIndicator
} from "app.components/Common/Select/Select";
import Content from "app.components/Common/Content/Content";
import AppContext from "app.components/AppContext";
import LaunchContext from "app.components/Launch/LaunchContext";
import Message from "app.components/Common/Message/Message";
import HostingAPI from "../../api/Hosting/HostingAPI";

const LaunchStepMasternodeOptions = () => {
  const { launch, fetchPaymentOptions, fetchUserCredits } = useContext(
    LaunchContext
  );
  const { credits, user } = useContext(AppContext);

  const [masternodeType, setMasternodeType] = useState(
    launch.config.masternodeType
  );
  const [walletType, setWalletType] = useState(launch.config.walletType);
  const [paymentOption, setPaymentOption] = useState(
    launch.config.paymentOption || "THREE_MONTHS"
  );
  const dashCoreWalletDisabled = masternodeType === "thirdParty";

  async function loadUserPaymentSchedule() {
    let result = await HostingAPI.getUserPaymentSchedule();
    if(result.success){
      let userPaymentSchedule = result.data;
      setPaymentOption(userPaymentSchedule.paymentOption);
    }
  }

  const fetchPaymentOptionsEffect = () => {
    fetchUserCredits({ userId: user.uid });
    if (!launch.paymentOptions) {
      loadUserPaymentSchedule();
      fetchPaymentOptions();
    }
  };
  useEffect(fetchPaymentOptionsEffect, []);

  const handleMasternodeTypeChange = (e, value) => {
    setMasternodeType(value);

    if (value === "thirdParty") {
      setWalletType("dashCore");
    }
  };

  let paymentOptions = launch.paymentOptions || [];

  // Filtering out 1 year option
  //paymentOptions = paymentOptions.filter(item => item.value !== "TWELVE_MONTHS");

  const isPaymentOptionsEmpty =
    launch.paymentOptions && !launch.paymentOptions.length;

  return (
    <React.Fragment>
      <StepperItemSub
        heading="Masternode type"
        subHeading="Select who will hold your masternode collateral."
      >
        <Select value={masternodeType} onChange={handleMasternodeTypeChange}>
          <SelectItem
            value="standard"
            label="Standard Masternode"
            description="The collateral of the masternode is held by you."
          />
          <SelectItem
            value="thirdParty"
            label="Third Party Masternode"
            description="The collateral of the masternode is held by a third party (e.g., SALT Lending)."
          />
        </Select>
      </StepperItemSub>
      <StepperItemSub
        heading="Wallet type"
        subHeading="Select the wallet type where your masternode collateral is stored."
      >
        <Select value={walletType} onChange={(e, v) => setWalletType(v)}>
          <SelectItem
            value="dashCore"
            label="Dash Core Wallet"
            description="The masternode is registered using the Debug Console on the Dash Core Wallet."
          />
          <SelectItem
            value="hardware"
            label="Hardware Wallet"
            disabled={dashCoreWalletDisabled}
            description="The masternode is registered using the Dash Masternode Tool (not available with third party masternodes)."
          />
        </Select>
      </StepperItemSub>
      <StepperItemSub
        heading="Payment Options"
        subHeading="Select a payment option below. At the next step, payments can be made in Dash or with a credit card."
      >
        {credits > 0 ? (
          <div className="u-mb-large">
            <Message>
              You have a {credits.toFixed("2")} Dash credit. The credit will be
              reflected on the Payment Screen.
            </Message>
          </div>
        ) : null}

        <Content loading={launch.isPaymentOptionsLoading}>
          {(isPaymentOptionsEmpty && (
            <Message>
              Could not find the payment options, please contact support.
            </Message>
          )) || (
            <Select
              className="payment-options"
              onChange={(e, v) => setPaymentOption(v)}
              value={paymentOption}
            >
              {paymentOptions.map((item, index) => (
                <SelectItem
                  key={index}
                  value={item.value}
                  customTemplate={<PaymentOption data={item} />}
                />
              ))}
            </Select>
          )}
        </Content>
      </StepperItemSub>



      <StepperControls
        nextDisabled={!masternodeType || !walletType || !paymentOption}
        onClickBack={() => store.dispatch(push("/"))}
        onSubmitParams={{
          masternodeType,
          walletType,
          paymentOption,
          lastStep: 1
        }}
      />
    </React.Fragment>
  );
};

const PaymentOption = ({ data, selectedValue }) => {
  return (
    <div className="payment-options__card">
      {/*{data.value === "THREE_MONTHS" && <div className="most-popular" />}*/}
      <div className="payment-options__card__header">{data.name}</div>
      <div className="payment-options__card__body">
        <span className="payment-options__card__body__price">
          {data.price} DASH
        </span>
        <span className="payment-options__card__body__discount">
          {data.discountMessage}
        </span>
      </div>
      <div className="payment-options__card__footer">
        <SelectIndicator selected={data.value === selectedValue} />
      </div>
    </div>
  );
};

export default LaunchStepMasternodeOptions;
