import React from "react";
import moment from "moment";
import DigitalMoney from "app.components/Util/DigitalMoney";
import { useWindowSize } from "app.utils";
import { shortenCurrencyValue } from "app.utils/currencies";
import Loader from "app.components/Common/Loader/Loader";
import NodeStatus from "app.components/Common/NodeStatus";
import Content, { ContentEmpty } from "app.components/Common/Content/Content";

const GovernanceList = ({ list, openProposal, type }) => {
  const { isLarge } = useWindowSize();
  return isLarge ? (
    <Content empty={!list.length}>
      <div className="table__detail">
        <table>
          <thead>
            <tr>
              <th className="text--left">Description</th>
              <th className="text--center">Closing Date</th>
              <th className="text--center">Amount</th>
              <th className="text--center">Vote</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => {
              return (
                <tr className="bordered" key={index}>
                  <td className="text--left">
                    <p className="proposal-title">{item.name}</p>
                    <p className="proposal-summary">
                      <span className="u-mr-default">
                        <a href={item.url}>{item.urlSource}</a>
                      </span>

                      <span className="u-mr-default proposal-summary__divider">
                        |
                      </span>
                      <span className="proposal-vote-count__value proposal-vote-count__value--yes">
                        {item.yeas}
                      </span>
                      <span className="proposal-vote-count__label">Yes</span>
                      <span className="u-mr-default proposal-summary__divider">
                        |
                      </span>
                      <span className="proposal-vote-count__value proposal-vote-count__value--no">
                        {item.nays}
                      </span>
                      <span className="proposal-vote-count__label">No</span>
                      <span className="u-mr-default proposal-summary__divider">
                        |
                      </span>
                      <span className="proposal-vote-count__value proposal-vote-count__value--abstain">
                        {item.abstains}
                      </span>
                      <span className="proposal-vote-count__label">
                        Abstain
                      </span>
                    </p>
                  </td>
                  <td className="text--center">
                    <p>{moment(item.endEpoch * 1000).fromNow()}</p>
                  </td>
                  <td className="text--right">
                    <p>
                      <DigitalMoney coins={item.requestedDash} unit="Dash" />
                    </p>
                    <p>${shortenCurrencyValue(item.requestedUsd)}</p>
                  </td>

                  {openProposal ? (
                    <td className="text--center">
                      {(item.isVoteLoading && (
                        <div className="flex justify--center">
                          <Loader type="horizontal" />
                        </div>
                      )) ||
                        (item.voteSuccess && (
                          <span className="node-status node-status--enabled">
                            Voted
                          </span>
                        )) ||
                        (item.voteError && (
                          <span className="node-status node-status--not-enabled">
                            {item.voteError} Masternode(s) failed to vote
                          </span>
                        )) || (
                          <p>
                            <button
                              onClick={() => openProposal(item, "YES")}
                              className="button button--positive u-m-default"
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => openProposal(item, "NO")}
                              className="button button--danger u-m-default"
                            >
                              No
                            </button>
                            <button
                              onClick={() => openProposal(item, "ABSTAIN")}
                              className="button button--outline-primary u-m-default"
                            >
                              Abstain
                            </button>
                          </p>
                        )}
                    </td>
                  ) : (
                    <td className="text--center">
                      <span className="node-status node-status--not-enabled">
                        Closed
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ContentEmpty>
        <div className="message--empty u-mt-large">
          <div className="message__content">
            <p>There are no proposals on this section.</p>
          </div>
        </div>
      </ContentEmpty>
    </Content>
  ) : (
    <div className="masternode-card-container">
      {list.map((item, itemIndex) => (
        <div key={itemIndex} className="masternode-card">
          <div className="masternode-card__descriptor-container">
            <div className="masternode-card__descriptor flex--grow">
              <div className="masternode-card__descriptor__title">Proposal</div>
              <div className="word-break">{item.name}</div>
            </div>
            <div className="masternode-card__descriptor visible-md">
              <div className="masternode-card__descriptor__title">Amount</div>
              <div className="word-break">
                <DigitalMoney coins={item.requestedDash} unit="Dash" /> ($
                {shortenCurrencyValue(item.requestedUsd)})
              </div>
            </div>
          </div>
          <div className="masternode-card__item-container">
            <div className="masternode-card__item">
              <div className="masternode-card__item__info masternode-card__item__info--grow">
                <div className="flex flex--row">
                  <span className="masternode-card__item__info__value">
                    <div className="proposal-summary">
                      <span className="proposal-vote-count__value proposal-vote-count__value--yes">
                        {item.yeas}
                      </span>
                      <span className="proposal-vote-count__label">Yes</span>
                      <span className="u-mr-default proposal-summary__divider">
                        |
                      </span>
                      <span className="proposal-vote-count__value proposal-vote-count__value--no">
                        {item.nays}
                      </span>
                      <span className="proposal-vote-count__label">No</span>
                      <span className="u-mr-default proposal-summary__divider">
                        |
                      </span>
                      <span className="proposal-vote-count__value proposal-vote-count__value--abstain">
                        {item.abstains}
                      </span>
                      <span className="proposal-vote-count__label">
                        Abstain
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="break-column visible-sm" />

              <div className="masternode-card__item__info masternode-card__item__info--grow hidden-md">
                <span className="masternode-card__item__info__title">
                  Amount
                </span>
                <span className="masternode-card__item__info__value">
                  <DigitalMoney coins={item.requestedDash} unit="Dash" /> ($
                  {shortenCurrencyValue(item.requestedUsd)})
                </span>
              </div>
              <div className="masternode-card__item__info masternode-card__item__info--grow align--right">
                <span className="masternode-card__item__info__title">
                  Closing Date
                </span>
                <span className="masternode-card__item__info__value">
                  {moment(item.endEpoch * 1000).fromNow()}
                </span>
              </div>

              {openProposal ? (
                <React.Fragment>
                  <div className="break-column visible-sm" />
                  <div className="masternode-card__item__info masternode-card__item__info--vote">
                    <div className="text--center">
                      {(item.isVoteLoading && (
                        <div
                          className="flex justify--center"
                          style={{ height: "36px" }}
                        >
                          <Loader type="horizontal" />
                        </div>
                      )) ||
                        (item.voteSuccess && (
                          <span className="node-status node-status--enabled">
                            Voted
                          </span>
                        )) ||
                        (item.voteError && (
                          <span className="node-status node-status--not-enabled">
                            {item.voteError} Masternode(s) failed to vote
                          </span>
                        )) || (
                          <div>
                            <button
                              onClick={() => openProposal(item, "YES")}
                              className="button button--positive u-mh-small"
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => openProposal(item, "NO")}
                              className="button button--danger u-mh-small"
                            >
                              No
                            </button>
                            <button
                              onClick={() => openProposal(item, "ABSTAIN")}
                              className="button button--outline-primary u-mh-small"
                            >
                              Abstain
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="masternode-card__item__info masternode-card__item__info--grow align--right">
                  <div className="flex flex--row">
                    <span className="masternode-card__item__info__value">
                      <NodeStatus status={"CLOSED"} />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GovernanceList;
