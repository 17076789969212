import API from "app.api/util/apis";
import instance from "app.api/SSO/Client";

class AuthAPI extends API {
  constructor() {
    super(instance);
  }

  getTicket = () => {
    return this.client.getNewTicket();
  };

  /**
   * Submits a request to get a user's token
   * @async
   * @function
   * @param {string} ticket - SSO ticket
   * @returns {(APIs.HTTPSuccessResponse|APIs.HTTPErrorResponse)} The Response or Error message
   */
  getTokenFromTicket = (ticket, profileId) => {
    if(ticket && ticket.length > 0){
      return this.get("/checkpoint", {
        authenticated: false,
        params: {
          ticket,
          profileId
        }
      });
    } else {
      console.log("getTokenFromTicket ticket empty")
    }
  };
}

export default new AuthAPI();
