import React, { useContext } from "react";
import { StepperContext } from "app.components/Common/Stepper/Stepper";

const StepperHeading = ({ heading, subHeading, prefix }) => {
  const { currentStepIndex } = useContext(StepperContext);
  return (
    <div className="stepper__heading">
      <h3>
        <span className="sr-only">Step {currentStepIndex + 1}</span>
        {prefix ? `${prefix}. ${heading}` : heading}
      </h3>
      <p className="stepper__sub-heading">{subHeading}</p>
    </div>
  );
};

export default StepperHeading;
