import {
  makeActionCreator,
  makeDefaultActionCreator as action
} from "app.actions/helpers";

export const INVALIDATE_TOKEN = "INVALIDATE_TOKEN";
export const USER_IS_AUTHENTICATED = "USER_IS_AUTHENTICATED";

export const USER_FETCH = "USER_FETCH";
export const USER_RECEIVE = "USER_RECEIVE";
export const USER_REQUEST = "USER_REQUEST";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";

export const USER_TOKEN_FETCH = "USER_TOKEN_FETCH";
export const USER_TOKEN_RECEIVE = "USER_TOKEN_RECEIVE";
export const USER_TOKEN_REQUEST = "USER_TOKEN_REQUEST";
export const USER_TOKEN_FETCH_ERROR = "USER_TOKEN_FETCH_ERROR";

export const AUTHORIZE_USER_REQUEST = "AUTHORIZE_USER_REQUEST";
export const AUTHORIZE_USER_RECEIVE = "AUTHORIZE_USER_RECEIVE";
export const AUTHORIZE_USER_BEGIN = "AUTHORIZE_USER_BEGIN";
export const AUTHORIZE_USER_ERROR = "AUTHORIZE_USER_ERROR";

export const FETCH_USER_CREDITS_BEGIN = "FETCH_USER_CREDITS_BEGIN";
export const FETCH_USER_CREDITS_REQUEST = "FETCH_USER_CREDITS_REQUEST";
export const FETCH_USER_CREDITS_RECEIVE = "FETCH_USER_CREDITS_RECEIVE";
export const FETCH_USER_CREDITS_ERROR = "FETCH_USER_CREDITS_ERROR";

export const RESET_USER = "RESET_USER";

export const markUserAuthenticated = makeActionCreator(USER_IS_AUTHENTICATED);

export const invalidateToken = makeActionCreator(INVALIDATE_TOKEN);

export const authorizeUser = makeActionCreator(
  AUTHORIZE_USER_BEGIN,
  "ticket",
  "redirectTo",
  "fromLogin"
);
export const requestAuthorizeUser = makeActionCreator(AUTHORIZE_USER_REQUEST);
export const receiveAuthorizeUser = makeActionCreator(
  AUTHORIZE_USER_RECEIVE,
  "token",
  "user"
);
export const authorizeUserError = action(AUTHORIZE_USER_ERROR);

export const requestToken = makeActionCreator(USER_TOKEN_REQUEST);
export const receiveToken = makeActionCreator(USER_TOKEN_RECEIVE, "response");
export const fetchNewToken = makeActionCreator(USER_TOKEN_FETCH, "token");
export const fetchNewTokenError = action(USER_TOKEN_FETCH_ERROR);

export const requestUser = makeActionCreator(USER_REQUEST);
export const receiveUser = makeActionCreator(USER_RECEIVE, "response");
export const fetchUser = makeActionCreator(USER_FETCH);
export const fetchUserError = action(USER_FETCH_ERROR);

export const resetUser = makeActionCreator(RESET_USER);

export const fetchUserCredits = action(FETCH_USER_CREDITS_BEGIN);
export const fetchUserCreditsRequest = action(FETCH_USER_CREDITS_REQUEST);
export const fetchUserCreditsReceive = action(FETCH_USER_CREDITS_RECEIVE);
export const fetchUserCreditsError = action(FETCH_USER_CREDITS_ERROR);
