import * as launchActions from "app.actions/launch";

let initialState = {
  isSearchCollateralLoading: false,
  isPaymentOptionsLoading: false,
  isLaunchConfigLoading: false,
  isLaunchLoading: false,
  isLaunchProgressLoading: false,
  isInvoiceLoading: false,
  paymentOptions: null,
  collateralData: {
    list: null,
    isValid: null
  },
  config: {
    status: "",
    address: "",
    paymentAddress: "",
    collateral: "",
    walletType: "",
    masternodeType: ""
  },
  invoice: {}
};

const launch = (state = initialState, action) => {
  switch (action.type) {
    case launchActions.SEARCH_COLLATERAL_REQUEST: {
      return {
        ...state,
        isSearchCollateralLoading: true
      };
    }
    case launchActions.SEARCH_COLLATERAL_RECEIVE: {
      return {
        ...state,
        isSearchCollateralLoading: false,
        collateralData: action.data,
        config: { ...state.config, collateral: "" }
      };
    }
    case launchActions.SEARCH_COLLATERAL_ERROR: {
      return {
        ...state,
        isSearchCollateralLoading: false,
        ...action.data
      };
    }
    case launchActions.FETCH_PAYMENT_OPTIONS_REQUEST: {
      return {
        ...state,
        isPaymentOptionsLoading: true
      };
    }
    case launchActions.FETCH_PAYMENT_OPTIONS_RECEIVE: {
      return {
        ...state,
        isPaymentOptionsLoading: false,
        paymentOptions: action.data
      };
    }
    case launchActions.FETCH_PAYMENT_OPTIONS_ERROR: {
      return {
        ...state,
        isPaymentOptionsLoading: false,
        ...action.data
      };
    }
    case launchActions.FETCH_LAUNCH_CONFIG_REQUEST: {
      return {
        ...state,
        isLaunchConfigLoading: true
      };
    }
    case launchActions.FETCH_LAUNCH_CONFIG_RECEIVE: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        config: { ...state.config, ...action.data, isReady: true }
      };
    }
    case launchActions.FETCH_LAUNCH_CONFIG_ERROR: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        ...action.data
      };
    }
    case launchActions.SAVE_LAUNCH_CONFIG_REQUEST: {
      return {
        ...state,
        isLaunchConfigLoading: true
      };
    }
    case launchActions.SAVE_LAUNCH_CONFIG_RECEIVE: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        config: action.data
      };
    }
    case launchActions.SAVE_LAUNCH_CONFIG_ERROR: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        ...action.data
      };
    }
    case launchActions.CLEAR_LAUNCH_CONFIG_REQUEST: {
      return {
        ...state,
        isLaunchConfigLoading: true
      };
    }
    case launchActions.CLEAR_LAUNCH_CONFIG_RECEIVE: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        config: { ...initialState.config, isReady: true }
      };
    }
    case launchActions.CLEAR_LAUNCH_CONFIG_ERROR: {
      return {
        ...state,
        isLaunchConfigLoading: false,
        ...action.data
      };
    }
    case launchActions.LAUNCH_MASTERNODE_REQUEST: {
      return {
        ...state,
        isLaunchLoading: true,
        collateralData: initialState.collateralData,
        config: { ...state.config, ...action.data }
      };
    }
    case launchActions.LAUNCH_MASTERNODE_RECEIVE: {
      return {
        ...state,
        isLaunchLoading: false,
        config: { ...state.config, ...action.data }
      };
    }
    case launchActions.LAUNCH_MASTERNODE_ERROR: {
      return {
        ...state,
        isLaunchLoading: false,
        ...action.data
      };
    }
    case launchActions.FETCH_LAUNCH_PROGRESS_REQUEST: {
      return {
        ...state,
        isLaunchProgressLoading: true
      };
    }
    case launchActions.FETCH_LAUNCH_PROGRESS_RECEIVE: {
      return {
        ...state,
        isLaunchProgressLoading: false,
        config: { ...state.config, ...action.data }
      };
    }
    case launchActions.FETCH_LAUNCH_PROGRESS_ERROR: {
      return {
        ...state,
        isLaunchProgressLoading: false,
        ...action.data
      };
    }
    case launchActions.FETCH_INVOICE_REQUEST: {
      return {
        ...state,
        isInvoiceLoading: true
      };
    }
    case launchActions.FETCH_INVOICE_RECEIVE: {
      return {
        ...state,
        isInvoiceLoading: false,
        invoice: action.data
      };
    }
    case launchActions.FETCH_INVOICE_ERROR: {
      return {
        ...state,
        isInvoiceLoading: false,
        ...action.data
      };
    }
    case launchActions.SUBMIT_STRIPE_PAYMENT_REQUEST: {
      return {
        ...state,
        isStripePaymentLoading: true
      };
    }
    case launchActions.SUBMIT_STRIPE_PAYMENT_RECEIVE: {
      return {
        ...state,
        isStripePaymentLoading: false,
        payment: action.data
      };
    }
    case launchActions.SUBMIT_STRIPE_PAYMENT_ERROR: {
      return {
        ...state,
        isStripePaymentLoading: false,
        ...action.data
      };
    }
    default:
      return state;
  }
};

export default launch;
