import { useState, useEffect } from "react";
import { SCREEN_SIZE_MD_MAX } from "app.constants";

export const useWindowSize = () => {
  const isClient = typeof window === "object";
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const resizeEffect = () => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };

  useEffect(resizeEffect, []);

  return { isLarge: window.innerWidth > SCREEN_SIZE_MD_MAX, windowSize };
};
