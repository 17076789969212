import React from "react";
import { PAGINATION_SIZE, PAGINATION_SIZE_SMALL_SCREEN } from "app.constants";
import { useWindowSize, getNumberSequencedArray } from "app.utils";

const Pagination = ({ onChange, currentPage = 0, pageSize, totalRecords }) => {
  const { isLarge } = useWindowSize();
  const numPages = Math.ceil(totalRecords / pageSize);
  const paginationSize = isLarge
    ? PAGINATION_SIZE
    : PAGINATION_SIZE_SMALL_SCREEN;
  let firstPage = 1;
  let lastPage = numPages;

  if (numPages > paginationSize) {
    const halfPagination = paginationSize / 2;
    const limit = currentPage + halfPagination;

    if (currentPage > halfPagination) {
      firstPage = currentPage - halfPagination;
    }

    if (limit > numPages) {
      lastPage = numPages;
      firstPage = firstPage - (limit - numPages) + 1;
    } else {
      lastPage = firstPage + paginationSize - 1;
    }
  }

  const pages = getNumberSequencedArray(firstPage, lastPage);

  return (
    <div className="pagination">
      <button
        className="button pagination__item"
        disabled={currentPage === 0}
        onClick={() => onChange(currentPage - 1)}
      >
        {"<"}
      </button>
      {pages.map(i => (
        <button
          key={i}
          className={
            currentPage === i - 1
              ? "button pagination__item button--primary"
              : "button pagination__item"
          }
          onClick={() => onChange(i - 1)}
        >
          {i}
        </button>
      ))}
      <button
        className="button pagination__item"
        disabled={currentPage >= numPages - 1}
        onClick={() => onChange(currentPage + 1)}
      >
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
