import React from "react";

const SelectItem = ({ disabled, selected }) => {
  return (
    <div
      className={
        disabled
          ? "round-indicator round-indicator--disabled"
          : selected
          ? "round-indicator round-indicator--active"
          : "round-indicator"
      }
    >
      {selected ? <span className="sr-only">Selected</span> : null}
    </div>
  );
};

export default SelectItem;
