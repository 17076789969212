import { select } from "redux-saga/effects";
import { STATUS } from "app.constants";

import { isUninitialized } from "app.utils";

export const getLoadingState = function*(selector) {
  try {
    if (typeof selector !== "function")
      throw new Error("expected a selector function in getLoadingState");

    const currentStatus = yield select(selector);
    const isLoading =
      currentStatus === STATUS.LOADING || currentStatus === STATUS.RELOADING;

    const uninitialized = isUninitialized(currentStatus);

    const nextStatus = uninitialized ? STATUS.LOADING : STATUS.RELOADING;

    return { isLoading, nextStatus };
  } catch (err) {
    throw new Error(err);
  }
};
