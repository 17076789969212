import axios from "axios";

/**
 * An HTTP Client Manager to interact with Node40 APIs in a standardized manner
 * @memberof HTTPClients.Base
 * @abstract
 */
class HttpClient {
  getResponseData = data => {
    if (typeof data === undefined) return null;
    return data;
  };

  /**
   * Formats the response to node40 patterns
   * @param {object} response - The axios response
   * @private
   */
  formatResponse = response => {
    const body = this.getResponseData(response.data);
    const _meta = response;

    return { body, _meta };
  };

  /**
   * Extracts the response to node40 patterns
   * @param {object} status - The status code
   * @param {object} response - The axios response
   * @private
   */
  getErrorFromResponse = (status, response) => {
    const type = status;
    const { data } = response;

    if (status >= 500) {
      return {
        serverError: true,
        type,
        message: "An unexpected error has occurred. Our team has been notified."
      };
    }

    if (data) return { ...data };

    switch (status) {
      case 401:
        return { message: "Unauthorized", type };
      case 403:
        return { message: "The attempted action was forbidden.", type };
      case 404:
        return { message: "The resource or action was not found.", type };
      default:
        return { message: `Error: Unhandled reponse: ${status}`, type };
    }
  };

  /**
   * Formats the error states into the node40 patterns
   * @param {object} apiError - The axios error
   * @private
   */
  formatError = apiError => {
    const { response } = apiError;

    if (!response) {
      return Promise.reject({
        body: { networkError: true },
        error: apiError,
        _meta: apiError
      });
    }

    const { status } = response;

    const _meta = response;
    const error = status;
    const body = this.getErrorFromResponse(status, response);

    return Promise.reject({
      _meta,
      error,
      body
    });
  };

  getClientConfig = config => {
    return {
      baseURL: config.serviceBase
    };
  };

  /**
   * Constructs a new instance of the HTTPClient
   * @param {HTTPClients.HTTPClientConfig} config - The configuration for this HTTP Client.
   */
  constructor(config) {
    if (typeof config !== "object")
      throw new Error("Did not provide a config argument to Instance");

    this.config = config;

    const clientConfig = this.getClientConfig(config);
    const noop = response => response;

    /**
     * An axios instance to be used for authenticated requests
     */
    this.instance = axios.create(clientConfig);

    const { formatResponse, formatError } = this;

    this.instance.interceptors.response.use(formatResponse, formatError);

    if (typeof config.afterResponse === "function") {
      // Wrap so that they don't have to follow axios middleware conventions and know to return the response
      // And instead can focus on just the concept of listening as if it were an event
      const wrapAfterResponse = response => {
        config.afterResponse(response);
        return response;
      };

      this.instance.interceptors.response.use(wrapAfterResponse);
    }

    if (typeof config.afterResponseError === "function") {
      // Wrap so that they don't have to follow axios middleware conventions and know to return the response
      // And instead can focus on just the concept of listening as if it were an event
      const wrapAfterResponseError = error => {
        config.afterResponseError(error);
        return Promise.reject(error);
      };

      this.instance.interceptors.response.use(noop, wrapAfterResponseError);
    }

    // We are then going to swallow the errors because we want them to come back without rejecting
    // so that we can handle errors without tons of try / catches in sagas
    this.instance.interceptors.response.use(noop, noop);
  }
}

export default HttpClient;
