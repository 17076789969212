import React, { useState } from "react";
import moment from "moment";
import DigitalMoney from "app.components/Util/DigitalMoney";
import {
  COMMON_DATE_FORMAT,
  COMMON_DATE_ONLY_FORMAT,
  TRANSACTIONS_PER_PAGE
} from "app.constants";
import Content, { ContentEmpty } from "app.components/Common/Content/Content";
import Pagination from "app.components/Common/Pagination/Pagination";
import { useWindowSize } from "app.utils";

const MasternodeDetailTransactions = ({
  transactions,
  isMasternodeTransactionsLoading
}) => {
  const { isLarge } = useWindowSize();
  const [currentPage, setCurrentPage] = useState(0);
  const totalTransactions = transactions.length;

  const onPaginationChange = page => {
    setCurrentPage(page);
  };

  transactions = transactions.slice(
    currentPage * TRANSACTIONS_PER_PAGE,
    currentPage * TRANSACTIONS_PER_PAGE + TRANSACTIONS_PER_PAGE
  );

  return (
    <Content
      loading={isMasternodeTransactionsLoading}
      empty={transactions.length === 0}
    >
      <React.Fragment>
        {isLarge ? (
          <table>
            <thead>
              <tr>
                <th className="text--left" style={{ minWidth: "384px" }}>
                  Transaction
                </th>
                <th>Block</th>
                <th>Date</th>
                <th className="text--right">Amount (Dash)</th>
                <th className="text--right">Balance (Dash)</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {transactions.map((item, itemIndex) => (
                <tr key={itemIndex}>
                  <td className="text--left mono">{item.transactionHash}</td>
                  <td className="text--center">{item.block}</td>
                  <td className="text--center">
                    {moment(item.date).format(COMMON_DATE_FORMAT)}
                  </td>
                  <td className="text--right">
                    {item.amount > 0 ? (
                      <DigitalMoney coins={item.amount.toFixed("8")} />
                    ) : (
                      <div className="text--danger">
                        {"("}
                        <DigitalMoney
                          coins={Math.abs(item.amount).toFixed("8")}
                        />
                        {")"}
                      </div>
                    )}
                  </td>
                  <td className="text--right">
                    <DigitalMoney coins={item.balance.toFixed("8")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="masternode-card-container">
            {transactions.map((item, i) => (
              <div key={i} className="masternode-card u-mt-none no-border">
                <div className="masternode-card__item-container">
                  <div className="masternode-card__item">
                    <div className="masternode-card__item__info masternode-card__item__info--100">
                      <span className="masternode-card__item__info__title">
                        Transaction Hash
                      </span>
                      <span className="masternode-card__item__info__value mono word-break">
                        {item.transactionHash}
                      </span>
                    </div>
                    <div className="masternode-card__item__info masternode-card__item__info--grow">
                      <span className="masternode-card__item__info__title">
                        Block
                      </span>
                      <span className="masternode-card__item__info__value">
                        {item.block}
                      </span>
                    </div>
                    <div className="masternode-card__item__info masternode-card__item__info--grow">
                      <span className="masternode-card__item__info__title">
                        Date
                      </span>
                      <span className="masternode-card__item__info__value">
                        {moment(item.date).format(COMMON_DATE_ONLY_FORMAT)}
                      </span>
                    </div>
                    <div className="masternode-card__item__info flex--grow flex--auto align--right">
                      <span className="masternode-card__item__info__title">
                        Amount (Dash)
                      </span>
                      <span className="masternode-card__item__info__value">
                        {item.amount > 0 ? (
                          <DigitalMoney coins={item.amount.toFixed("8")} />
                        ) : (
                          <div className="text--danger">
                            {"("}
                            <DigitalMoney
                              coins={Math.abs(item.amount).toFixed("8")}
                            />
                            {")"}
                          </div>
                        )}
                      </span>
                    </div>
                    <div className="masternode-card__item__info flex--grow flex--auto align--right">
                      <span className="masternode-card__item__info__title">
                        Balance (Dash)
                      </span>
                      <span className="masternode-card__item__info__value">
                        <DigitalMoney coins={item.balance.toFixed("8")} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <Pagination
          onChange={onPaginationChange}
          currentPage={currentPage}
          pageSize={TRANSACTIONS_PER_PAGE}
          totalRecords={totalTransactions}
        />
      </React.Fragment>
      <ContentEmpty>
        <div className="message--empty u-mt-large">
          <div className="message__content">
            <p>There are no transactions yet.</p>
          </div>
        </div>
      </ContentEmpty>
    </Content>
  );
};

export default MasternodeDetailTransactions;
