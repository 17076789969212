import { makeDefaultActionCreator as action } from "app.actions/helpers";

export const FETCH_INVOICES_BEGIN = "FETCH_INVOICES_BEGIN";
export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_RECEIVE = "FETCH_INVOICES_RECEIVE";
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR";

export const UPDATE_INVOICE_BEGIN = "UPDATE_INVOICE_BEGIN";
export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_RECEIVE = "UPDATE_INVOICE_RECEIVE";
export const UPDATE_INVOICE_ERROR = "UPDATE_INVOICE_ERROR";

export const fetchInvoices = action(FETCH_INVOICES_BEGIN);
export const fetchInvoicesRequest = action(FETCH_INVOICES_REQUEST);
export const fetchInvoicesReceive = action(FETCH_INVOICES_RECEIVE);
export const fetchInvoicesError = action(FETCH_INVOICES_ERROR);

export const updateInvoice = action(UPDATE_INVOICE_BEGIN);
export const updateInvoiceRequest = action(UPDATE_INVOICE_REQUEST);
export const updateInvoiceReceive = action(UPDATE_INVOICE_RECEIVE);
export const updateInvoiceError = action(UPDATE_INVOICE_ERROR);
