import { makeActionCreator } from "app.actions/helpers";

export const AUTHENTICATION_STATUS_CHECK_BEGIN =
  "AUTHENTICATION_STATUS_CHECK_BEGIN";

export const AUTHENTICATION_STATUS_CHECK_REQUEST =
  "AUTHENTICATION_STATUS_CHECK_REQUEST";

export const AUTHENTICATION_STATUS_CHECK_RECEIVE =
  "AUTHENTICATION_STATUS_CHECK_RECEIVE";

export const AUTHENTICATION_STATUS_CHECK_ERROR =
  "AUTHENTICATION_STATUS_CHECK_ERROR";

export const AUTO_REFRESH_USER_TOKEN_START = "AUTO_REFRESH_USER_TOKEN_START";
export const AUTO_REFRESH_USER_TOKEN_END = "AUTO_REFRESH_USER_TOKEN_END";

export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN";

export const ORGANIZATION_TOKEN_REFRESH = "ORGANIZATION_TOKEN_REFRESH";

export const AUTO_REFRESH_ORGANIZATION_TOKEN_START =
  "AUTO_REFRESH_ORGANIZATION_TOKEN_START";
export const AUTO_REFRESH_ORGANIZATION_TOKEN_END =
  "AUTO_REFRESH_ORGANIZATION_TOKEN_END";

export const GO_TO_LOGIN = "GO_TO_LOGIN";
export const LOGOUT = "LOGOUT";

export const checkAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_BEGIN
);

export const requestAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_REQUEST
);

export const receiveAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_RECEIVE
);

export const checkAuthenticationStatusError = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_ERROR
);

export const autoRefreshUserToken = makeActionCreator(
  AUTO_REFRESH_USER_TOKEN_START
);

export const stopRefreshingUserToken = makeActionCreator(
  AUTO_REFRESH_USER_TOKEN_END
);

export const refreshOrganizationToken = makeActionCreator(
  ORGANIZATION_TOKEN_REFRESH
);

export const autoRefreshOrganizationToken = makeActionCreator(
  AUTO_REFRESH_ORGANIZATION_TOKEN_START
);
export const stopRefreshingOrganizationToken = makeActionCreator(
  AUTO_REFRESH_ORGANIZATION_TOKEN_END
);

export const refreshUserToken = makeActionCreator(REFRESH_USER_TOKEN);

export const goToLogin = makeActionCreator(
  GO_TO_LOGIN,
  "redirectTo",
  "serviceId"
);

export const logout = makeActionCreator(LOGOUT, "redirectTo");
