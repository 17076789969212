import { STATUS } from "app.constants";
import { useInterval } from "app.utils/useInterval";
import { useWindowSize } from "app.utils/useWindowSize";

let localStorageTestResult;

export const testLocalStorage = function() {
  const lstest = "node04-ls-test";

  if (typeof localStorageTestResult === "undefined") {
    try {
      localStorage.setItem(lstest, lstest);
      localStorage.removeItem(lstest);
      localStorageTestResult = true;
      return localStorageTestResult;
    } catch (e) {
      localStorageTestResult = false;
      return localStorageTestResult;
    }
  } else {
    return localStorageTestResult;
  }
};

export const middleDots = function(str, maxLength, separator = "\u2026") {
  if (str.length <= maxLength) return str;

  const sepLen = separator.length;
  const charsToShow = maxLength - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    str.substr(0, frontChars) + separator + str.substr(str.length - backChars)
  );
};

export const getNumberSequencedArray = function(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((i, idx) => idx + start);
};

export const isDollars = function(dollars, allowNegative = true) {
  if (allowNegative) {
    return dollars.match(/^-?[0-9]+\.?[0-9]?[0-9]?$/);
  }
  return dollars.match(/^[0-9]+\.?[0-9]?[0-9]?$/);
};

export const formatDollarValue = function(dollars, places = 2) {
  let dollarsAsFloatFull;
  let isNegative = false;

  if (typeof dollars === "undefined") return null;
  if (dollars === null) return null;

  if (typeof dollars === "number") {
    dollarsAsFloatFull = dollars;
    isNegative = dollarsAsFloatFull < 0 ? true : false;
  }

  if (typeof dollars === "string") {
    const cleanString = `${dollars}`.replace(/[^-.\d.]+/g, "");
    dollarsAsFloatFull = parseFloat(cleanString);
    isNegative =
      cleanString.substr(0, 1) === "-" || dollars.substr(0, 1) === "(";
  }

  const dollarsAsFloat = dollarsAsFloatFull.toFixed(places);
  const parts = `${dollarsAsFloat}`.split(".");
  let dollarsPart = parts[0]
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let changePart = parts[1] || "";

  return `${isNegative ? "-" : ""}$${dollarsPart}${
    places > 0 ? "." : ""
  }${changePart}`;
};

export const shortenString = (longString, maxLength, tailCharactersToShow) => {
  if (typeof longString === "undefined") return "";
  if (longString === null) return "";
  let shortenedString = longString;
  if (longString.length > maxLength) {
    shortenedString = `${longString.substr(
      0,
      maxLength - 2
    )}...${longString.substr(
      longString.length - tailCharactersToShow,
      tailCharactersToShow
    )}`;
  }
  return shortenedString;
};

export const isUninitialized = function(status) {
  return typeof status === "undefined" || status === STATUS.UNINITIALIZED;
};

export const isLoaded = function(status) {
  return status === STATUS.LOADED || status === STATUS.RELOADING;
};

export const isLoading = function(status) {
  return status === STATUS.LOADING || status === STATUS.RELOADING;
};

export const isReloading = function(status) {
  return status === STATUS.RELOADING;
};

// essentially reproduces event.path since that is not wideley supported.
export const getElementPath = function(target) {
  let path = [];
  while (target) {
    path.push(target);
    target = target.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
    path.push(document);
  if (path.indexOf(window) === -1) path.push(window);
  return path;
};

export { useInterval, useWindowSize };
