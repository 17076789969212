import React, { useContext, useEffect, useState } from "react";
import {
  StepperHeading,
  StepperControls
} from "app.components/Common/Stepper/Stepper";
import StripeButton from "app.components/Common/Stripe/StripeButton";
import { STRIPE_KEY, NODE40_STRIPE_LOGO } from "app.constants";
import LaunchContext from "app.components/Launch/LaunchContext";
import AppContext from "app.components/AppContext";
import CopyToClipboard from "app.components/Common/CopyToClipboard/CopyToClipboard";
import Loader from "app.components/Common/Loader/Loader";
import { useInterval } from "app.utils";
import { Link } from "react-router-dom";

const LaunchStepPayment = () => {
  const {
    launch,
    fetchInvoice,
    fetchLaunchProgress,
    submitStripePayment
  } = useContext(LaunchContext);
  const { user } = useContext(AppContext);
  const [isStripePaymentLoading, setIsStripePaymentLoading] = useState(false);
  const { config, isLaunchLoading, isInvoiceLoading, invoice } = launch;
  const status = config.status;
  const isPaymentConfirmed = config.paid;
  const isExpired = status === "ERROR";
  // Right now we are letting the user go to the next step even if the machine is not ready.
  // With the deterministic masternode list we have time until the protx is submitted
  const isCompleted = isPaymentConfirmed;
  const isLaunching = !config.id;
  const isInvoiceReady = !isInvoiceLoading && invoice.id;
  const isPaymentDisabled =
    !isInvoiceReady || !config.depositAddress || isPaymentConfirmed;

  const getStripeData = launchInvoice => {
    if (!launchInvoice) {
      return {};
    }
    return {
      name: launchInvoice.sequence,
      description: launchInvoice.amount + " Dash",
      amount: launchInvoice.fiatValue ? launchInvoice.fiatValue * 100 : 0,
      email: user.sub
    };
  };

  const submitPayment = token => {
    setIsStripePaymentLoading(true);
    submitStripePayment({
      id: cachedInvoice.id,
      token: token.id,
      amount: cachedInvoice.fiatValue,
      renewal: false
    });
  };

  const stripeConfig = {
    key: STRIPE_KEY,
    image: NODE40_STRIPE_LOGO,
    token: submitPayment
  };

  const progressInterval = () => {
    if (!isLaunchLoading && !isCompleted) {
      fetchLaunchProgress({ id: config.id });
    }
  };

  const requestInvoice = () => {
    if (config.id && !isInvoiceLoading) {
      fetchInvoice({ id: config.id });
    }
  };

  const formatInvoiceValue = invoiceValue => {
    try {
      invoiceValue = parseFloat(invoiceValue);
      return invoiceValue.toFixed(2);
    } catch (e) {
      return invoiceValue;
    }
  };

  useEffect(requestInvoice, [isLaunchLoading, config.id]);
  useInterval(progressInterval);
  cachedInvoice = invoice;

  if (config.error) {
    return (
      <React.Fragment>
        <div className="launch-message">
          <div className="launch-message__title">
            There's a commissioning process pending, please go to the main page
            to view it.
          </div>
          <div className="launch-message__description">
            <Link to="/" className="button button--primary">
              Go To Main Page
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isLaunching) {
    return (
      <React.Fragment>
        <div className="launch-message">
          <div className="launch-message__title">
            Preparing masternode server. This will take a couple of seconds.
          </div>
          <div className="launch-message__description">
            <Loader type="horizontal" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isExpired) {
    return (
      <React.Fragment>
        <div className="launch-message">
          Your invoice has expired, please launch your masternode again.
        </div>
        <div className="text--right">
          <Link className="button button--primary" to="/">
            Finish
          </Link>
        </div>
      </React.Fragment>
    );
  }

  if (isCompleted) {
    return (
      <React.Fragment>
        <div className="launch-message">
          <div className="launch-message__title">
            <span className="payment__progress__item__done glyphicons glyphicons-ok-2" />
            Payment confirmed.
          </div>
          <div className="launch-message__description">
            On the next step you can register your masternode with the network.
          </div>
        </div>
        <div className="text--right">
          <StepperControls
            backHidden={true}
            disabled={!isCompleted}
            nextDisabled={!isCompleted}
            onSubmitParams={{
              lastStep: 3
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StepperHeading
        heading="Payment"
        subHeading={"Use one of the payment methods below"}
      />

      <div className="payment">
        <div className="payment__form">
          <div className="payment-type">
            <div className="payment-type__item">
              <div className="payment-type__item__title">Pay in Dash</div>
              <div className="payment-type__item__description">
                {!invoice.amount
                  ? "Loading deposit address"
                  : "Please send exactly " +
                    invoice.amount +
                    " Dash to this deposit address."}
              </div>
              <div>
                <CopyToClipboard
                  textWidth="96px"
                  placeholder="Loading deposit address..."
                  value={config.depositAddress}
                  disabled={
                    isPaymentDisabled || isCompleted || isStripePaymentLoading
                  }
                />
              </div>
            </div>
            <div className="payment-type__item payment-type__item--grow">
              <div className="payment-type__item__title">
                Or, Pay by credit card
              </div>
              <div className="payment-type__item__description">
                {!invoice.fiatValue
                  ? "Loading fiat value"
                  : `You will be charged with $${formatInvoiceValue(
                      invoice.fiatValue
                    )}.`}
              </div>
              <div className="payment-type__item__button">
                <StripeButton
                  className={
                    isPaymentDisabled
                      ? "button button--primary button--loading"
                      : "button button--primary"
                  }
                  disabled={
                    isPaymentDisabled || isCompleted || isStripePaymentLoading
                  }
                  config={stripeConfig}
                  data={getStripeData(invoice)}
                >
                  Pay with stripe
                </StripeButton>
              </div>
            </div>
          </div>
        </div>
        <div className="payment__progress">
          <div className="payment__progress__item">
            {(!config.id && <Loader type="horizontal" />) || (
              <span className="payment__progress__item__done glyphicons glyphicons-ok-2" />
            )}
            Preparing masternode server. This will take a couple of seconds.
          </div>

          {config.id && (
            <div className="payment__progress__item">
              {(!isInvoiceReady && <Loader type="horizontal" />) || (
                <span className="payment__progress__item__done glyphicons glyphicons-ok-2" />
              )}
              Generating invoice
            </div>
          )}

          {isInvoiceReady && (
            <div className="payment__progress__item">
              {(!isPaymentConfirmed && <Loader type="horizontal" />) || (
                <span className="payment__progress__item__done glyphicons glyphicons-ok-2" />
              )}
              {!isStripePaymentLoading
                ? "Waiting for payment"
                : "Processing payment"}
            </div>
          )}
        </div>
      </div>
      <StepperControls
        backHidden={true}
        disabled={!isCompleted}
        nextDisabled={!isCompleted}
        onSubmitParams={{
          lastStep: 3
        }}
      />
    </React.Fragment>
  );
};

let cachedInvoice;
export default LaunchStepPayment;
