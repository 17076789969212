import React, { Component } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import HostingAPI from "../../api/Hosting/HostingAPI";
import stringUtil from "../../utils/stringUtil";
import Content from "app.components/Common/Content/Content";
import { PLAN_URL } from "app.constants";

class AccountContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      initObject: {},
      enableSubmit: false,
      profileUpdated: false,
      submitBtnLabel: 'Update',
      passwordInfo: {},
      initPassword:{},
      enableSubmitPassword: false,
      passwordUpdated: false,
      submitBtnLabelPassword: 'Change',
      passwordChangeError: null,
      passwordShow: false,
      password1Show: false,
      password2Show: false,
      userPayment: {},
      loadProfile: true,
      loadPlan: true,
      passwordError: null,
      password1Error: null,
      password2Error: null
    };
  }

  componentDidMount = async () => {
    this.showProfile(true);
    const userProfile = await HostingAPI.getProfile();
    this.setState({ userInfo: userProfile });
    this.showProfile(false);
    this.setInitObject(userProfile);
    this.showPlan(true);
    const payment = await HostingAPI.getUserPaymentSchedule();
    this.setState({ userPayment: payment.data });
    this.showPlan(false);
    const passwordObj = {password: null, password1: null, password2: null};
    this.setState({ initPassword: passwordObj });
    this.setPasswordInfo(passwordObj);
  }

  componentWillUnmount = () => {
  }

  showProfile = (enabled) => {
    this.setState({ loadProfile: enabled });
  };

  showPlan = (enabled) => {
    this.setState({ loadPlan: enabled });
  };

  setInitObject = (data) => {
    this.setState({initObject: {username: data.username, firstName: data.firstName, lastName: data.lastName}});
  };

  validateData = () => true;

  enableSubmitButton = (enabled) => {
    this.setState({ enableSubmit: enabled });
  };

  handleChange = (event) => {
    // destructuring to get the variables
    const { userInfo } = this.state;
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      userInfo[name] = event.target.checked;
    } else {
      userInfo[name] = value;
    }
    // setting state whith the value of "name" as the key
    this.setState({ userInfo });
    const validateResult = this.validateData();
    this.enableSubmitButton(validateResult);
  }

  handleReset = (event) => {
    event.preventDefault();
    const { userInfo, initObject }= this.state;
    userInfo.username = initObject.username;
    userInfo.firstName = initObject.firstName;
    userInfo.lastName = initObject.lastName;
    this.setState(this.state.initObject);
    this.enableSubmitButton(false);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({profileUpdated: false});
    this.enableSubmitButton(false);
    this.setState({submitBtnLabel: 'Saving...'});
    const result = await HostingAPI.updateProfile(this.state.userInfo);
    this.setState({profileUpdated: true});
    this.setState({submitBtnLabel: 'Update'});
    this.setInitObject(result);
  }

  setPasswordInfo = (data) => {
    this.setState({passwordInfo: {password: data.password, password1: data.password1, password2: data.password2}});
  };

  validateDataChangePassword = () => {
    const {password, password1, password2} = this.state.passwordInfo;
    let errors = {};
    errors.success = false;
    const passwordRegex = /(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/;
    if (!password) {
      errors.passwordError = "Required";
    } else if (!password1) {
      errors.password1Error = "Required";
    } else if (!passwordRegex.test(password1)) {
      errors.password1Error = 'Your password must be at least 12 characters long, 1 x letter, 1 x digit, 1 x special char';
    } else if(password1 !== password2) {
      errors.password2Error = 'New Passwords do not match.';
    } else errors.success = true;
    this.setState({passwordError: errors.passwordError});
    this.setState({password1Error: errors.password1Error});
    this.setState({password2Error: errors.password2Error});
    return errors.success;
  };

  enableSubmitButtonChangePassword = (enabled) => {
    this.setState({ enableSubmitPassword: enabled });
  };

  handleChangeChangePassword = (event) => {
    // destructuring to get the variables
    const { passwordInfo } = this.state;
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      passwordInfo[name] = event.target.checked;
    } else {
      passwordInfo[name] = value;
    }
    // setting state whith the value of "name" as the key
    this.setState({ passwordInfo });
    const validateResult = this.validateDataChangePassword();
    this.enableSubmitButtonChangePassword(validateResult);
  }

  handleResetChangePassword = (event) => {
    event.preventDefault();
    const { passwordInfo, initPassword }= this.state;
    passwordInfo.password = initPassword.password;
    passwordInfo.password1 = initPassword.password1;
    passwordInfo.password2 = initPassword.password2;
    this.setState(this.state.initPassword);
    this.enableSubmitButtonChangePassword(false);
  }

  handleSubmitChangePassword = async (event) => {
    event.preventDefault();
    this.setState({passwordUpdated: false});
    this.enableSubmitButtonChangePassword(false);
    this.setState({submitBtnLabelPassword: 'Saving...'});
    const result = await HostingAPI.updatePassword(this.state.passwordInfo);
    this.setState({passwordChangeError: result.error});
    this.setState({passwordUpdated: true});
    this.setState({submitBtnLabelPassword: 'Change'});
    if (stringUtil.isNullOrEmpty(this.state.passwordChangeError)) this.setPasswordInfo(this.state.initPassword);
  }

  handleShowChatIntercomMessenger = () => {
    window.Intercom('show');
  };

  render = () => {
    const { userInfo, enableSubmit, profileUpdated, submitBtnLabel, passwordInfo, enableSubmitPassword, passwordUpdated, submitBtnLabelPassword, passwordChangeError, passwordShow, password1Show, password2Show, userPayment, loadProfile, loadPlan, passwordError, password1Error, password2Error }= this.state;

    return (
      <div className="monitor">
        <div className="flex flex--row flex--grow">
          <div className="masternode-detail__list">
            <div className="message--empty u-mt-large">
              <div className="message__content">
                <div className="content__wrapper">
                  <div className="panel account-container">
                    <header>
                      <div className="page-header">
                        <h2 className="page-header__title">My Account</h2>
                      </div>
                      <nav className="account-nav">
                        <NavLink to="/account/profile">Profile</NavLink>
                        <NavLink to="/account/password">Password</NavLink>
                        <NavLink to="/account/subscription">Subscription&nbsp;<span className="hidden-xs">&amp;</span>&nbsp;Billing</NavLink>
                      </nav>
                    </header>
                    <Switch>
                      <Route
                        path="/account/profile"
                        render={() => (
                          <Content loading={loadProfile}>
                            <div className="account-profile">
                              <form>
                                <p className="text--success" hidden={!profileUpdated}>Your profile has been updated</p>
                                <div className="form-group"><label htmlFor="username">Email</label><input className="form-control" type="text" name="username" id="username" value={stringUtil.isNullOrEmpty(userInfo.username)?'':userInfo.username} onChange={this.handleChange} /></div>
                                <div className="form-group"><label htmlFor="firstName">First Name</label><input className="form-control" type="text" name="firstName" id="firstName" value={stringUtil.isNullOrEmpty(userInfo.firstName)?'':userInfo.firstName} onChange={this.handleChange} /></div>
                                <div className="form-group"><label htmlFor="lastName">Last Name</label><input className="form-control" type="text" name="lastName" id="lastName" value={stringUtil.isNullOrEmpty(userInfo.lastName)?'':userInfo.lastName} onChange={this.handleChange} /></div>
                                <div className="form-group text--right">
                                  <button className="button button--text button--onethird" disabled={!enableSubmit} name="cancelChangeInfo" onClick={this.handleReset}>Cancel</button>
                                  <button className="button button--sweet-action button--onethird" disabled={!enableSubmit} name="accountInfo" onClick={this.handleSubmit}>{submitBtnLabel}</button>
                                </div>
                              </form>
                            </div>
                          </Content>
                        )}
                      />
                      <Route
                        path="/account/password"
                        render={() => (
                          <div className="account-password">
                            <form>
                              <p className="text--success" hidden={!passwordUpdated || !stringUtil.isNullOrEmpty(passwordChangeError)}>Your password has been changed.</p>
                              <p className="text--danger" hidden={!passwordUpdated || stringUtil.isNullOrEmpty(passwordChangeError)}>{passwordChangeError}</p>
                              <div className="form-group">
                                <label htmlFor="currentPassword">Current Password<span className={`note ${passwordError&&'text--danger'}`}>{passwordError}</span></label>
                                <div className="password-input">
                                  <input className="form-control password-input__password" name="password" id="currentPassword" i18n="[object Object]" type={passwordShow? 'text':'password'} value={stringUtil.isNullOrEmpty(passwordInfo.password)?'':passwordInfo.password} onChange={this.handleChangeChangePassword} />
                                  <button type="button" title="Show my typing" className={`password-input__toggler glyphicons ${passwordShow? 'glyphicons-eye-close':'glyphicons-eye-open'}`} onClick={() => {this.setState({passwordShow: !passwordShow})}}></button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="newPassword">New Password<span className={`note ${password1Error&&'text--danger'}`}>{password1Error?password1Error:'Must be at least 12 characters long.'}</span></label>
                                <div className="password-input">
                                  <input className="form-control password-input__password" name="password1" id="newPassword" i18n="[object Object]" type={password1Show? 'text':'password'} value={stringUtil.isNullOrEmpty(passwordInfo.password1)?'':passwordInfo.password1} onChange={this.handleChangeChangePassword} />
                                  <button type="button" title="Show my typing" className={`password-input__toggler glyphicons ${password1Show? 'glyphicons-eye-close':'glyphicons-eye-open'}`} onClick={() => {this.setState({password1Show: !password1Show})}}></button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="confirmNewPassword">Confirm New Password<span className={`note ${password2Error&&'text--danger'}`}>{password2Error}</span></label>
                                <div className="password-input">
                                  <input className="form-control password-input__password" name="password2" id="confirmNewPassword" i18n="[object Object]" type={password2Show? 'text':'password'} value={stringUtil.isNullOrEmpty(passwordInfo.password2)?'':passwordInfo.password2} onChange={this.handleChangeChangePassword} />
                                  <button type="button" title="Show my typing" className={`password-input__toggler glyphicons ${password2Show? 'glyphicons-eye-close':'glyphicons-eye-open'}`} onClick={() => {this.setState({password2Show: !password2Show})}}></button>
                                </div>
                              </div>
                              <div className="form-group text--right">
                                <button className="button button--text button--onethird" disabled={!enableSubmitPassword} name="cancelChangePassword" onClick={this.handleResetChangePassword}>Cancel</button>
                                <button className="button button--sweet-action button--onethird" disabled={!enableSubmitPassword} type="submit" name="UpdatePassword" onClick={this.handleSubmitChangePassword}>{submitBtnLabelPassword}</button>
                              </div>
                            </form>
                          </div>
                        )}
                      />
                      <Route
                        path="/account/subscription"
                        render={() => (
                          <Content loading={loadPlan}>
                            <div className="account-subscription">
                              <div className="subscription__current-subscription">
                                <div className="subscription__current-subscription__row">
                                  <div className="subscription__current-subscription__row-title">
                                    <p>{userPayment.name}</p>
                                    <p className="subscription__current-subscription__row-title-link"><a href={PLAN_URL} target='_blank' rel='noopener noreferrer'>See plan options</a></p>
                                  </div>
                                  <div className="subscription__current-subscription__row-summary"><p>{`${userPayment.discountMessage} - ${userPayment.price} ${userPayment.paymentPeriod}`}</p></div>
                                  <div className="subscription__current-subscription__row-action"><NavLink to='/account/subscription' onClick={this.handleShowChatIntercomMessenger}>Want to change your plan? Chat with us now.</NavLink></div>
                                </div>
                              </div>
                            </div>
                          </Content>
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountContainer;
