import React, { useState, useRef } from "react";

const CopyToClipboard = ({
  disabled,
  placeholder,
  children = [],
  value = "",
  label = "Copy to clipboard",
  onCopy,
  rows
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [clipboardUnavailable, setClipboardUnavailable] = useState(false);
  const clipboardElement = useRef(null);
  const maxLength = 96;

  const selectTextAndCopyToClipboard = (
    element,
    successCallback,
    errorCallback
  ) => {
    try {
      element.select();
      const command = document.execCommand("copy");
      successCallback(command);
    } catch (e) {
      errorCallback(e);
    }
  };

  const timedToggle = callback => {
    callback(true);
    setTimeout(() => callback(false), 4000);
  };

  const copyToClipboard = () => {
    selectTextAndCopyToClipboard(
      clipboardElement.current,
      () => timedToggle(setCopiedToClipboard),
      () => timedToggle(setClipboardUnavailable)
    );
    if (onCopy) {
      onCopy(clipboardElement.current.value);
    }
  };

  return (
    <div className="copy-to-clipboard">
      {(children.length && (
        <div className="copy-to-clipboard__description">{children}</div>
      )) ||
        null}

      <div className="copy-to-clipboard__commands">
        {(value.length < maxLength && !rows && (
          <input
            type="text"
            ref={clipboardElement}
            className="form-control"
            readOnly={true}
            value={value}
            placeholder={placeholder}
          />
        )) || (
          <textarea
            ref={clipboardElement}
            className="form-control"
            readOnly={true}
            value={value}
            placeholder={placeholder}
            rows={rows || value.length / maxLength + 1}
          />
        )}

        <button
          className="button button--positive"
          disabled={disabled}
          onClick={copyToClipboard}
        >
          {label}
        </button>
      </div>

      <div className="copy-to-clipboard__info">
        {(copiedToClipboard && (
          <span className="copy-to-clipboard__info--positive">
            Copied to clipboard
          </span>
        )) ||
          (clipboardUnavailable && (
            <span className="copy-to-clipboard__info--negative">
              There was an error copying to clipboard, please copy manually
            </span>
          )) || <span>&nbsp;</span>}
      </div>
    </div>
  );
};

export default CopyToClipboard;
