import download from "downloadjs";

/**
 * API Common Functionality
 * @namespace APIs
 */

/**
 * The configuration for an HTTP Client
 * @memberof APIs
 * @typedef {Object} HTTPSuccessResponse
 * @property {Object} body - The response body data
 * @property {Object} _meta - The response metadata
 */

/**
 * The configuration for an HTTP Client
 * @memberof APIs
 * @typedef {Object} HTTPErrorResponse
 * @property {Object} body - The response body data
 * @property {Object} _meta - The response metadata
 * @property {Object} error - The response error
 */

class ApiError extends Error {
  constructor(message, innerException) {
    super(message);
    this.innerException = innerException;
  }
}

/**
 * An class to define the behavior of an actual API
 * @memberof APIs
 */
class API {
  /**
   * Constructs a new instance of the DocumentsAPI
   * @param {HTTPClients.HttpClient} httpClient - The HTTP Client to use to access the API
   */
  constructor(httpClient) {
    this.client = httpClient;

    this.get = httpClient.instance.get;
    this.post = httpClient.instance.post;
    this.put = httpClient.instance.put;
    this.delete = httpClient.instance.delete;
    this.request = httpClient.instance;

    this.download = async (requestParams, fileName, mimeType) => {
      if (typeof fileName !== "string")
        throw new ApiError("Must provide a filename to download a file");

      return this.request({
        responseType: "blob",
        ...requestParams
      }).then(response => {
        if (response.error)
          throw new ApiError("There has been an error downloading your file", {
            error: response.error,
            meta: response._meta,
            body: response.body
          });

        download(response.body, fileName, mimeType);

        return { body: response.body, fileName };
      });
    };
  }
}

export default API;
