import { call, put, select, takeEvery } from "redux-saga/effects";
import * as governanceActions from "app.actions/governance";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { handleError } from "app.sagas/error";

const governanceSagas = [
  takeEvery(governanceActions.FETCH_GOVERNANCE_BEGIN, fetchGovernance),
  takeEvery(governanceActions.VOTE_BEGIN, vote)
];

export default governanceSagas;

export function* fetchGovernance() {
  try {
    const selector = state => state.governance.isLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(governanceActions.fetchGovernanceRequest(nextStatus));

    let proposals = yield call(HostingAPI.requestGovernanceList);

    const votedProposals = yield call(HostingAPI.requestVotedProposals);

    proposals = proposals.map(proposal => ({
      ...proposal,
      voted: votedProposals.includes(proposal.id)
    }));

    yield put(governanceActions.fetchGovernanceReceive({ proposals }));
  } catch (err) {
    yield put(governanceActions.fetchGovernanceError());
    yield put(handleError({ err }));
  }
}

export function* vote(action) {
  try {
    const selector = state => state.governance.isVoteLoading;

    const { isLoading } = yield* getLoadingState(selector);
    const { id, votes } = action.data;
    if (isLoading) return;
    let proposals = yield select(state => state.governance.proposals);

    let proposal = proposals.find(item => item.id === id);
    proposal.isVoteLoading = true;

    yield put(governanceActions.voteRequest({ proposals }));

    const results = yield call(HostingAPI.broadcastVote, id, votes);

    const errors = Object.keys(results).filter(key => results[key] !== "OK");

    proposal.voteError = errors.length;
    proposal.isVoteLoading = false;
    proposal.voteSuccess = !errors.length;

    yield put(governanceActions.voteReceive({ results, proposals }));
  } catch (err) {
    yield put(handleError({ err }));
  }
}
