import React from "react";
import { Link } from "react-router-dom";

const HeaderDropdownItem = ({
  type,
  description,
  path,
  icon,
  className,
  onClick
}) => (
  <React.Fragment>
    {(() => {
      switch (type) {
        case "href":
          return (
            <a href={path} className={className}>
              <HeaderDropdownItemDescriptor
                description={description}
                icon={icon}
              />
            </a>
          );
        case "link":
          return (
            <Link to={path} className={className}>
              <HeaderDropdownItemDescriptor
                description={description}
                icon={icon}
              />
            </Link>
          );
        case "button":
          return (
            <button onClick={onClick} className={className}>
              <HeaderDropdownItemDescriptor
                description={description}
                icon={icon}
              />
            </button>
          );
        default:
          return null;
      }
    })()}
  </React.Fragment>
);

const HeaderDropdownItemDescriptor = ({ description, icon }) => (
  <React.Fragment>
    {description}
    {icon}
  </React.Fragment>
);

export default HeaderDropdownItem;
