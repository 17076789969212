// reducer for UI state
import * as uiActions from "app.actions/ui";
import { testLocalStorage } from "app.utils";
import { LOCAL_STORAGE_KEY } from "app.constants";

// each reducer needs its own local storage key
const ui_local_storage_key = `${LOCAL_STORAGE_KEY}_UI`;

// intial state, eventually we'll restore this from local storage (per user)
let initialState = {
  modalMasternodeMonitoredAdd: false,
  modalMasternodeMonitoredRemove: false,
  modalGovernanceDelegationCommands: false,
  modalGovernanceBroadcastVote: false,
  modalMasternodeTagAdd: false,
  modalError: false,
  networkError: undefined,
  globalError: undefined,
  flashMessages: [],
  modalWorking: false
};

// if local storage is supported, we rehydrate some of the initial state from localStorage (if present)
if (testLocalStorage()) {
  try {
    if (typeof localStorage[ui_local_storage_key] !== "undefined") {
      const savedState = JSON.parse(localStorage[ui_local_storage_key]) || {};
      initialState = { ...initialState, ...savedState };
    }
  } catch (e) {
    console.log(e);
  }
}

const ui = (state = initialState, action) => {
  let flashMessages;

  switch (action.type) {
    case uiActions.UI_OPEN_MODAL:
      return {
        ...state,
        ...action.data
      };
    case uiActions.UI_DISMISS_MODAL:
      return {
        ...state,
        ...action.data
      };
    case uiActions.UI_OPEN_ERROR_MODAL:
      return {
        ...state,
        ...action.data,
        modalError: true
      };
    case uiActions.UI_DISMISS_ERROR_MODAL:
      return {
        ...state,
        ...action.data,
        modalError: false
      };
    case uiActions.UI_MODAL_ACTION_ERROR:
      return { ...state, modalError: action.response };
    case uiActions.UI_GLOBAL_ERROR:
      return { ...state, globalError: action.error };
    case uiActions.UI_NETWORK_ERROR:
      return { ...state, networkError: action.error };
    case uiActions.ADD_FLASH_MESSAGE: {
      flashMessages = [...state.flashMessages];
      flashMessages.push({
        message: action.message,
        id: action.messageId,
        status: action.nextStatus,
        date: new Date()
      });
      return { ...state, flashMessages: flashMessages };
    }
    case uiActions.REMOVE_FLASH_MESSAGE: {
      flashMessages = [...state.flashMessages];
      let messageToRemove = flashMessages.findIndex((item, idx, arr) => {
        if (item.id === action.messageId) return true;
        return false;
      });
      flashMessages.splice(messageToRemove, 1);
      return { ...state, flashMessages: flashMessages };
    }
    default:
      return state;
  }
};

export default ui;
