import {
  call,
  put,
  select,
  takeLatest,
  race,
  delay,
  take
} from "redux-saga/effects";
import * as authenticationActions from "app.actions/authentication";
import * as userActions from "app.actions/user";
import { HOSTING_SERVICE_ID, SSO_BASE } from "app.constants";
import { handleError } from "app.sagas/error";
import AuthAPI from "app.api/SSO/AuthAPI";

// WATCHERS
const authenticationSagas = [
  takeLatest(
    authenticationActions.AUTHENTICATION_STATUS_CHECK_BEGIN,
    checkAuthenticationStatus
  ),
  takeLatest(authenticationActions.GO_TO_LOGIN, goToLogin),
  takeLatest(
    authenticationActions.AUTO_REFRESH_USER_TOKEN_START,
    autoRefreshUserToken
  ),
  takeLatest(authenticationActions.REFRESH_USER_TOKEN, refreshUserToken),
  takeLatest(authenticationActions.LOGOUT, logout)
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default authenticationSagas;

// WORKER SAGAS
// worker Saga: will be fired on user actions

function* checkAuthenticationStatus() {
  try {
    const state = yield select(state => state);
    const userState = state.user;

    const { token } = userState;

    const hasUserToken = typeof token !== "undefined";

    if (!hasUserToken) {
      // If we have neither token, exit out and get to login
      yield put(authenticationActions.goToLogin());
      return;
    }

    yield put(authenticationActions.requestAuthenticationStatus());

    yield put(authenticationActions.autoRefreshUserToken());

    yield put(authenticationActions.receiveAuthenticationStatus());
  } catch (err) {
    yield put(authenticationActions.checkAuthenticationStatusError());
    yield put(handleError({ err }));
  }
}

function goToLogin(action) {
  const { redirectTo, serviceId } = action;
  let redirectPath, hostingServiceId;

  if (typeof redirectTo === "undefined" && typeof serviceId === "undefined") {
    // Use balance service ID, and send us to the root of the app
    redirectPath = undefined;
    hostingServiceId = HOSTING_SERVICE_ID;
  } else if (
    typeof redirectTo !== "undefined" &&
    typeof serviceId === "undefined"
  ) {
    // Use just the redirect to, and the service ID for the balance app
    redirectPath = redirectTo;
    hostingServiceId = HOSTING_SERVICE_ID;
  } else if (
    typeof redirectTo === "undefined" &&
    typeof serviceId !== "undefined"
  ) {
    // use just the service id, and do not provide a redirect path
    hostingServiceId = serviceId;
  } else {
    // Use both the redirect to and the service ID
    redirectPath = redirectTo;
    hostingServiceId = serviceId;
  }

  const pathQueryParam = redirectPath
    ? `&redirect=${encodeURIComponent(redirectPath)}`
    : "";

  // Router will just use empty strings for
  // const redirectUrl = `${SSO_BASE}/login?service=${hostingServiceId}${pathQueryParam}`;
  const redirectUrl = `${SSO_BASE}?service=${hostingServiceId}${pathQueryParam}`;
  // if (!window.location.href.includes('/login')){
     window.location = redirectUrl;
  // }

}

function* autoRefreshUserToken() {
  try {
    let idleTimer = 0;
    let refreshTimer = 0;
    let refreshRunning = true;

    const REFRESH_INTERVAL_SECONDS = 56;
    const IDLE_MAX_SECONDS = 60 * 5;

    const resetTimer = () => {
      idleTimer = 0;
    };

    window.addEventListener("mousemove", resetTimer, false);
    window.addEventListener("click", resetTimer, false);
    window.addEventListener("keydown", resetTimer, false);

    while (refreshRunning) {
      const { cancelled } = yield race({
        delayed: delay(1000),
        cancelled: take(authenticationActions.AUTO_REFRESH_USER_TOKEN_END)
      });

      if (cancelled) {
        refreshRunning = false;
      } else {
        idleTimer++;
        refreshTimer++;

        const withinRefreshWindow = idleTimer <= IDLE_MAX_SECONDS;
        const onRefreshInterval = refreshTimer % REFRESH_INTERVAL_SECONDS === 0;

        if (withinRefreshWindow && onRefreshInterval) {
          // It's been 56 seconds
          yield put(authenticationActions.refreshUserToken());
        }
      }
    }

    window.removeEventListener("mousemove", resetTimer, false);
    window.removeEventListener("click", resetTimer, false);
    window.removeEventListener("keydown", resetTimer, false);
  } catch (err) {
    yield put(authenticationActions.goToLogin());
    //console.log("autoRefreshUserToken",err);
  }
}

function* refreshUserToken(action) {
  try {
    const ticket = yield call(AuthAPI.getTicket);
    if(ticket.length > 0) {
      const response = yield call(AuthAPI.getTokenFromTicket, ticket);

      if (response.error) {
        // Bubble up so we can just go to login
        //throw response.error;
        console.log(response.error);
      } else {
        if (ticket.length == 0) {
          logout(action);
        } else {
          const { body } = response;

          yield put(userActions.receiveAuthorizeUser(body.access_token, body));
        }

      }
    }

  } catch (err) {
    yield put(authenticationActions.goToLogin());
    //console.log("refreshUserToken", err);
  }
}

function logout(action) {
  const { redirectTo } = action;
  let pathQueryParam;

  if (typeof redirectTo === "undefined") {
    pathQueryParam = `&redirect=${encodeURIComponent(
      window.location.pathname
    )}`;
  } else {
    pathQueryParam = `&redirect=${encodeURIComponent(redirectTo)}`;
  }

  // const redirectUrl = `${SSO_BASE}/logout?service=${HOSTING_SERVICE_ID}${pathQueryParam}`;
  const redirectUrl = `${SSO_BASE}?service=${HOSTING_SERVICE_ID}${pathQueryParam}`;

  window.location = redirectUrl;
}
