import React, { useContext, useState } from "react";
import {
  StepperContext,
  StepperHeading
} from "app.components/Common/Stepper/Stepper";

const StepperItemSub = ({ children, heading, subHeading, headingPrefix }) => {
  const { getHeadingPrefix } = useContext(StepperContext);
  const [prefix] = useState(headingPrefix || getHeadingPrefix());

  return (
    <div className="stepper__item__sub">
      <StepperHeading
        heading={heading}
        subHeading={subHeading}
        prefix={prefix}
      />
      {children}
    </div>
  );
};

export default StepperItemSub;
