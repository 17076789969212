import React from "react";

const User = ({ className, color = "#FFF" }) => (
  <svg
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>User</title>
    <defs>
      <circle id="a" cx="30" cy="30" r="30" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        cx="30"
        cy="30"
        r="30"
        fillRule="nonzero"
        stroke={color}
        strokeWidth="3"
        transform="translate(2 2)"
      />
      <g transform="translate(2 2)">
        <mask id="b" fill="#FFF">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M33.53 44.385v-3.319c4.433-2.497 8.05-8.727 8.05-14.955C41.58 16.108 41.58 8 29.506 8s-12.074 8.108-12.074 18.11c0 6.23 3.616 12.459 8.05 14.956v3.319C11.83 45.5 1.332 52.208 1.332 60.318h56.345c0-8.11-10.496-14.819-24.148-15.933h.001z"
          fill={color}
          fillRule="nonzero"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
);

export default User;
