import { call, put, takeEvery } from "redux-saga/effects";
import * as invoiceActions from "app.actions/invoice";
import HostingAPI from "app.api/Hosting/HostingAPI";
import { getLoadingState } from "app.utils/selectors";
import { DASH_DECIMALS } from "app.constants";
import { handleError } from "app.sagas/error";

const invoiceSagas = [
  takeEvery(invoiceActions.FETCH_INVOICES_BEGIN, fetchInvoices),
  takeEvery(invoiceActions.UPDATE_INVOICE_BEGIN, updateInvoice)
];

export default invoiceSagas;

export function* fetchInvoices(action) {
  try {
    const selector = state => state.invoice.isLoading;

    const { isLoading, nextStatus } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(invoiceActions.fetchInvoicesRequest(nextStatus));

    let invoices = yield call(HostingAPI.requestInvoices, action.data.userId);

    invoices = invoices.map(item => {
      item.amount = parseFloat(item.amount) / DASH_DECIMALS;
      item.dueDate = new Date(item.dueDate).getTime();
      return item;
    });

    invoices = invoices.sort((a, b) => b.dueDate - a.dueDate);

    yield put(invoiceActions.fetchInvoicesReceive({ invoices }));
  } catch (err) {
    yield put(invoiceActions.fetchInvoicesError());
    yield put(handleError({ err }));
  }
}

export function* updateInvoice(action) {
  try {
    const selector = state => state.invoice.isUpdateInvoiceLoading;

    const { isLoading } = yield* getLoadingState(selector);

    if (isLoading) return;

    yield put(invoiceActions.updateInvoiceRequest());

    const data = {
      invoice: action.data.id,
      stripeToken: action.data.token,
      totalAmount: action.data.amount,
      renewal: !!action.data.renewal
    };

    yield call(HostingAPI.submitStripePayment, data);

    yield put(invoiceActions.updateInvoiceReceive({}));
  } catch (err) {
    yield put(invoiceActions.updateInvoiceError());
    yield put(handleError({ err }));
  }
}
